import { FunctionComponent, useCallback } from 'react';

import { useDispatch, useSelector } from '@/store';
import { appActions, appSelectors } from '@/store/slices/app';

import { DisabledForm } from './DisabledForm';
import { RecoveryCodesModal } from './RecoveryCodesModal';

export const AccountThoFactorAuthForm: FunctionComponent = () => {
  const dispatch = useDispatch();

  const recoveryCodes = useSelector(appSelectors.getRecoveryCodes);

  const handleCloseRecoveryCodes = useCallback(() => {
    dispatch(appActions.clearRecoveryCodes());
  }, [dispatch]);

  return (
    <>
      <DisabledForm />

      {recoveryCodes && <RecoveryCodesModal codes={recoveryCodes} onClose={handleCloseRecoveryCodes} />}
    </>
  );
};

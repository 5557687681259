import { EuiFieldText, EuiForm, EuiFormRow, EuiSuperSelect, EuiText } from '@elastic/eui';
import { css } from '@emotion/css';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Controller, UseFormSetError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { ROLES_OPTIONS, useIsOpenControl, useRole } from '@/hooks';
import { useSelector } from '@/store';
import { appSelectors } from '@/store/slices/app';
import { Roles } from '@/types';
import { SecurityFieldBtn } from '@/components/SecurityFieldBtn';

import { Fields, schema } from './schema';

interface CreateUserFormProps {
  id: string;
  onSubmit: (data: Fields) => void;
}

export interface CreateUserFormRef {
  setError: UseFormSetError<Fields>;
}

export const CreateUserForm = forwardRef<CreateUserFormRef, CreateUserFormProps>(function CreateUserForm(
  { id, onSubmit },
  ref
) {
  const { isOpen: isSecurityField, toggle: toggleSecurityField } = useIsOpenControl(true);
  const { t } = useTranslation();
  const { isValid: isSuperAdmin } = useRole([Roles.Role.SUPER_ADMIN]);
  const rolesOptions = useMemo(
    () => ROLES_OPTIONS.map((option) => ({ ...option, inputDisplay: t(`role.${option.value}`) })),
    [t]
  );
  const organization_id = useSelector(
    isSuperAdmin ? appSelectors.getCurrentOrganizationId : appSelectors.getOrganizationId
  );

  const { control, handleSubmit, setError } = useForm<Fields>({
    defaultValues: {
      email: '',
      password: '',
      nickname: '',
      is_active: true,
      role: '',
      organization_id: organization_id as number,
    },
    resolver: yupResolver(schema),
  });

  useImperativeHandle(ref, () => {
    return {
      setError,
    };
  }, [setError]);

  return (
    <EuiForm
      component="form"
      id={id}
      onSubmit={handleSubmit(onSubmit)}
      className={css`
        width: 400px;
        max-width: 100%;
        .euiFormRow__labelWrapper {
          flex-direction: column;
          gap: 10px;
        }
        .euiFormLabel {
          font-weight: 400;
          font-size: 14px;
        }
        .euiFormControlLayout--group {
          background-color: transparent;
        }
      `}
    >
      <Controller
        control={control}
        name="email"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ gap: 10 }}
            label={t('form.label.login')}
            error={t(error?.message as string)}
            isInvalid={!!error?.message}
            labelAppend={
              <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                {t(`modal.createUser.notes.email`)}
              </EuiText>
            }
            fullWidth
          >
            <EuiFieldText
              placeholder={t('form.placeholder.login')}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              autoComplete="off"
            />
          </EuiFormRow>
        )}
      />

      <Controller
        control={control}
        name="password"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ gap: 10 }}
            label={t(`form.label.${name}`)}
            error={t(error?.message as string)}
            isInvalid={!!error?.message}
            fullWidth
          >
            <EuiFieldText
              type={isSecurityField ? 'password' : 'text'}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              autoComplete="new-password"
              append={<SecurityFieldBtn isSecurity={isSecurityField} toggleSecurity={toggleSecurityField} />}
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="nickname"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ gap: 10 }}
            label={t(`form.label.${name}`)}
            error={t(error?.message as string)}
            isInvalid={!!error?.message}
            labelAppend={
              <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                {t(`modal.createUser.notes.${name}`)}
              </EuiText>
            }
            fullWidth
          >
            <EuiFieldText
              type="text"
              placeholder={t(`form.placeholder.${name}`)}
              value={value as string}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
            />
          </EuiFormRow>
        )}
      />
      {isSuperAdmin && (
        <Controller
          control={control}
          name="role"
          render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              style={{ gap: 10 }}
              label={t(`form.label.${name}`)}
              error={t(error?.message as string)}
              isInvalid={!!error?.message}
              fullWidth
            >
              <EuiSuperSelect
                name={name}
                valueOfSelected={value}
                options={rolesOptions}
                placeholder={t(`form.placeholder.${name}`)}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={!!error}
                fullWidth
              />
            </EuiFormRow>
          )}
        />
      )}
    </EuiForm>
  );
});

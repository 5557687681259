import { FC } from 'react';
import { EuiDatePicker, EuiDatePickerRange, EuiFormErrorText, EuiFormRow, EuiSuperSelect } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import moment from 'moment';

import { DEFAULT_START_DATE, PERIODS } from '@/constants';
import { getConstrainedDateISOString } from '@/utils';

import { FormFields } from './shcema';

interface PeriodSelectProps {
  selectedPeriod?: string;
  error?: string;
  control: Control<FormFields>;
  trigger: UseFormTrigger<FormFields>;
}
export const PeriodSelect: FC<PeriodSelectProps> = ({ selectedPeriod, error, control, trigger }) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        control={control}
        name="period"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ marginTop: 20, gap: 8 }}
            label={t(`form.label.${name}`)}
            isInvalid={!!error}
            error={t(error?.message || '')}
          >
            <EuiSuperSelect
              key={name}
              name={name}
              valueOfSelected={value}
              options={PERIODS.map((period) => ({
                value: period,
                inputDisplay: t(`date_select.${period}`),
              }))}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
            />
          </EuiFormRow>
        )}
      />
      {selectedPeriod === 'custom' && (
        <>
          <EuiDatePickerRange
            iconType={false}
            className={css`
              box-shadow: none;
              background-color: var(--primary-background);
              margin-top: 16px;
              .euiFormControlLayout {
                height: auto;
              }
              .euiDatePicker .euiFormControlLayout {
                border: 1px solid var(--border-color);
                border-radius: 6px;
              }
              .euiText {
                margin-top: 24px;
              }
              .euiFormControlLayoutDelimited {
                box-shadow: none;
                background-color: var(--primary-background);
              }
              input.euiFieldText.euiDatePicker {
                text-align: left;
              }
            `}
            startDateControl={
              <Controller
                control={control}
                name="start_date"
                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                  <EuiFormRow style={{ gap: 8 }} label={t(`form.label.${name}`)} isInvalid={!!error}>
                    <EuiDatePicker
                      key={name}
                      name={name}
                      selected={value ? moment(value) : undefined}
                      onChange={(date) => {
                        onChange(getConstrainedDateISOString(date));
                        trigger();
                      }}
                      placeholder="dd.mm.yyyy HH:MM"
                      compressed
                      showIcon={false}
                      dateFormat={process.env.REACT_APP_DATE_TIME_FORMAT}
                      minDate={moment(DEFAULT_START_DATE)}
                      maxDate={moment()}
                      showTimeSelect
                      timeFormat="HH:mm"
                    />
                  </EuiFormRow>
                )}
              />
            }
            endDateControl={
              <Controller
                control={control}
                name="end_date"
                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                  <EuiFormRow style={{ gap: 8 }} label={t(`form.label.${name}`)} isInvalid={!!error}>
                    <EuiDatePicker
                      key={name}
                      name={name}
                      selected={value ? moment(value) : undefined}
                      onChange={(date) => {
                        onChange(getConstrainedDateISOString(date));
                        trigger();
                      }}
                      placeholder="dd.mm.yyyy HH:MM"
                      compressed
                      showIcon={false}
                      dateFormat={process.env.REACT_APP_DATE_TIME_FORMAT}
                      minDate={moment(DEFAULT_START_DATE)}
                      maxDate={moment()}
                      showTimeSelect
                      timeFormat="HH:mm"
                    />
                  </EuiFormRow>
                )}
              />
            }
          />
          {!!error && (
            <EuiFormRow>
              <EuiFormErrorText>{t(error)}</EuiFormErrorText>
            </EuiFormRow>
          )}
        </>
      )}
    </>
  );
};

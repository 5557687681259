import { FC } from 'react';
import { EuiButtonEmpty, EuiFlexGroup, EuiPanel, EuiSpacer, EuiText, EuiTitle, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';

import { Loader } from '../Loader';

type NoteProps = {
  title: string;
  color?: string;
  text: string;
  btnText: string;
  onClick: () => void;
  hiddenBtn?: boolean;
  hasLoader?: boolean;
};
export const SearchStatusNote: FC<NoteProps> = ({
  title,
  color,
  text,
  btnText,
  onClick,
  hasLoader,
  hiddenBtn = false,
}) => {
  const { euiTheme } = useEuiTheme();
  return (
    <EuiPanel hasShadow={false} paddingSize="xl" hasBorder style={{ maxWidth: 620 }}>
      <EuiTitle size="s">
        <h1
          className={css`
            font-weight: 600;
            font-size: 18px;
            color: ${color};
            padding-bottom: ${euiTheme.size.xs};
            border-bottom: 1px solid ${euiTheme.colors.lightShade};
          `}
        >
          {title}
        </h1>
      </EuiTitle>
      <EuiText
        size="s"
        className={css`
          padding-block: ${euiTheme.size.m};
        `}
      >
        {text}
      </EuiText>
      {!hiddenBtn && (
        <EuiButtonEmpty onClick={onClick} color="danger" style={{ padding: 0, textDecoration: 'underline' }}>
          {btnText}
        </EuiButtonEmpty>
      )}
      {!!hasLoader && (
        <EuiFlexGroup justifyContent="center" alignItems="center" direction="column" gutterSize="none">
          <EuiSpacer size="xl" />
          <Loader />
        </EuiFlexGroup>
      )}
    </EuiPanel>
  );
};

import { boolean, object, ObjectSchema, string } from 'yup';

export type FormFields = {
  email: string;
  password: string;
  captcha_token?: string;
  remember_me?: boolean;
};

export const schema: ObjectSchema<FormFields> = object().shape({
  captcha_token: string().when('$isDevMode', (isDevMode, schema) =>
    isDevMode.some((v) => v) ? schema : schema.required('form.error.required')
  ),
  email: string().required('form.error.required'),
  password: string().required('form.error.required'),
  remember_me: boolean(),
});

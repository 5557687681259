/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Suspense } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';

import { AuthRequired, BaseLayout, ErrorElement, PageLoader, RoleRequired, SideBarLayout } from '@/components';
import {
  SignIn,
  SignInOtp,
  SettingsOtp,
  Page404,
  Account,
  Home,
  SearchDetails,
  LogOut,
  Organizations,
  Users,
  SearchError,
  Documentation,
  UsersStats,
} from '@/pages';
import { Roles } from '@/types';

import { Routes } from './types';

const routes: RouteObject[] = [
  {
    path: '/',
    errorElement: <ErrorElement />,
    children: [
      {
        path: Routes.HOME,
        element: <AuthRequired />,
        children: [
          {
            element: <SideBarLayout />,
            children: [
              {
                path: '',
                element: <Home />,
              },
              {
                path: Routes.SEARCH,
                element: <Navigate to={Routes.HOME} replace />,
              },
              {
                path: Routes.SEARCH_DETAILS,
                element: <SearchDetails />,
              },
              {
                path: Routes.SEARCH_ERROR,
                element: <SearchError />,
              },
              {
                path: Routes.DOCS,
                element: <Documentation />,
              },
            ],
          },
          {
            element: <BaseLayout />,
            children: [
              {
                path: Routes.ACCOUNT,
                element: <Account />,
              },
              {
                element: <RoleRequired roles={[Roles.Role.SUPER_ADMIN]} fallback={<Navigate to={Routes.HOME} />} />,
                children: [{ path: Routes.ORGANIZATIONS, element: <Organizations /> }],
              },
              {
                element: (
                  <RoleRequired
                    roles={[Roles.Role.SUPER_ADMIN, Roles.Role.ADMIN]}
                    fallback={<Navigate to={Routes.HOME} />}
                  />
                ),
                children: [{ path: Routes.USERS_STATS, element: <UsersStats /> }],
              },
              {
                element: (
                  <RoleRequired
                    roles={[Roles.Role.SUPER_ADMIN, Roles.Role.ADMIN]}
                    fallback={<Navigate to={Routes.HOME} />}
                  />
                ),
                children: [{ path: Routes.USERS, element: <Users /> }],
              },
            ],
          },
        ],
      },
      {
        path: Routes.LOGIN,
        element: <Suspense fallback={<PageLoader />}>{<SignIn />}</Suspense>,
      },
      {
        path: Routes.LOGIN_OTP,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SignInOtp />
          </Suspense>
        ),
      },
      {
        path: Routes.SETTINGS_OTP,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SettingsOtp />
          </Suspense>
        ),
      },
      {
        path: Routes.LOGOUT,
        element: (
          <Suspense fallback={<PageLoader />}>
            <LogOut />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<PageLoader />}>
            <Page404 />
          </Suspense>
        ),
      },
    ],
  },
];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);

import { EuiFlexGroup, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { AccountThoFactorAuthForm } from '@/components';
import { ACCOUNT_MENU_ITEMS } from '@/constants';

import { PasswordForm } from './PasswordForm';
import { IdentityForm } from './IdentityForm';

export const AccountForm = () => {
  const { t } = useTranslation();
  return (
    <EuiFlexGroup direction="column" className="accountFormWrapper">
      <EuiPanel hasShadow={false} hasBorder id={ACCOUNT_MENU_ITEMS.IDENTITY}>
        <EuiTitle size="xs">
          <h2>{t('page.account.menu.identity')}</h2>
        </EuiTitle>
        <EuiSpacer size="l" />
        <IdentityForm />
        <EuiSpacer size="m" />
      </EuiPanel>
      <EuiPanel hasShadow={false} hasBorder id={ACCOUNT_MENU_ITEMS.PASSWORD}>
        <EuiTitle size="xs">
          <h2>{t('page.account.menu.password')}</h2>
        </EuiTitle>
        <EuiSpacer size="l" />
        <PasswordForm />
        <EuiSpacer size="m" />
      </EuiPanel>
      <EuiPanel hasShadow={false} hasBorder id={ACCOUNT_MENU_ITEMS.TWO_AUTH}>
        <EuiTitle size="xs">
          <h2>{t('page.account.menu.2_factor_auth')}</h2>
        </EuiTitle>
        <EuiSpacer size="l" />
        <AccountThoFactorAuthForm />
        <EuiSpacer size="m" />
      </EuiPanel>
    </EuiFlexGroup>
  );
};

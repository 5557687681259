import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiPage, EuiPageTemplate, EuiPanel, EuiSpacer } from '@elastic/eui';
import { FC, ReactNode } from 'react';

import { Logo } from '../Logo';
import AuthBackground from '@/assets/auth_background.jpg';
import { useWindowSize } from '@/hooks';

export const AuthUILayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { width } = useWindowSize();
  return (
    <EuiPageTemplate grow>
      <EuiPage grow paddingSize="none">
        <EuiPanel
          paddingSize="xl"
          hasShadow
          borderRadius="none"
          style={{
            zIndex: 1,
            maxWidth: 600,
            paddingInline: 24,
            height: '100vh',
            overflow: 'auto',
          }}
          className="eui-yScroll"
        >
          <EuiFlexGroup direction="column" gutterSize="l" style={{ minHeight: '100%' }}>
            <EuiFlexItem grow={0}>
              <Logo />
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFlexGroup direction="column" gutterSize="none" style={{ minHeight: '100%' }} justifyContent="center">
                {children}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={0}>
              <EuiSpacer size="xl" />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
        {width > 1274 && (
          <EuiFlexGroup
            justifyContent="center"
            alignItems="center"
            style={{
              height: '100vh',
              overflow: 'hidden',
            }}
          >
            <EuiImage src={AuthBackground} alt="Big brother" size={787} />
          </EuiFlexGroup>
        )}
      </EuiPage>
    </EuiPageTemplate>
  );
};

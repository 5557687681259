export enum Columns {
  user = 'nickname',
  organization = 'organization_name',
  two_fa = 'is_2fa_enabled',
  role = 'role',
  joined = 'join_date',
  lastSeen = 'last_seen',
  status = 'is_active',
}

export type UserData = Components.Schemas.User;

export type TableProps = {
  isLoading: boolean;
  isUsedFilters: boolean;
  limit?: number;
  total: number;
  activePage: number;
  handleChangePage: (page: number) => void;
  handleChangeItemsPerPage: (limit: number) => void;
  selected: number[];
  onSelectionChange: (value: number[]) => void;
  onDisableBtnClick: () => void;
};

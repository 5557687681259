import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface RecoveryCodesProps {
  onClose: () => void;
  codes: string[];
}

export const RecoveryCodes: FC<RecoveryCodesProps> = ({ codes, onClose }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  return (
    <>
      <EuiTitle size="s">
        <h2 style={{ fontWeight: 500, fontSize: 24, marginBottom: 10 }}>{t('modal.recoveryCodes.title')}</h2>
      </EuiTitle>
      <EuiText size="s">{t('modal.recoveryCodes.subtitle')}:</EuiText>
      <EuiSpacer size="xl" />
      <EuiPanel hasBorder={true} paddingSize="m">
        <EuiFlexGroup direction="column" alignItems="center" gutterSize="s">
          {codes.map((code) => (
            <EuiText key={code} color="accent" size="s">
              {code}
            </EuiText>
          ))}
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer size="xl" />
      <EuiCallOut title={t('modal.recoveryCodes.alertTitle')} size="m" color="warning">
        <EuiText size="s" color="warning">
          {t('modal.recoveryCodes.alertNote')}
        </EuiText>
      </EuiCallOut>
      <EuiSpacer size="xl" />
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem grow>
          <EuiCopy textToCopy={codes.join('\n')}>
            {(copy) => (
              <EuiButton
                onClick={copy}
                fill
                color="primary"
                fullWidth
                contentProps={{ style: { color: euiTheme.colors.body } }}
              >
                {t('button.copy')}
              </EuiButton>
            )}
          </EuiCopy>
        </EuiFlexItem>
        <EuiFlexItem grow>
          <EuiButtonEmpty onClick={onClose} color="primary">
            {t('button.next')}
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

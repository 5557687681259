import { createAsyncThunk } from '@reduxjs/toolkit';

import { dataServiceApi } from '@/api/data';

export const createSearch = createAsyncThunk(
  'search/createSearch',
  async (payload: Paths.NewSearchSearchNewPost.QueryParameters, thunkApi) => {
    try {
      const { data } = await dataServiceApi.createSearch(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const getSearchById = createAsyncThunk(
  'search/getSearchById',
  async (payload: Paths.GetSearchSearchSearchIdGet.PathParameters, thunkApi) => {
    try {
      const { data } = await dataServiceApi.getSearchById(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const getSearchDetails = createAsyncThunk(
  'search/getSearchDetails',
  async (payload: Paths.GetSearchMessagesSearchSearchIdMessagesGet.PathParameters, thunkApi) => {
    try {
      const { data } = await dataServiceApi.getSearchMessages(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const deleteSearchById = createAsyncThunk(
  'search/deleteSearchById',
  async (payload: Paths.DeleteSearchSearchSearchIdDelete.PathParameters, thunkApi) => {
    try {
      await dataServiceApi.deleteSearchById(payload, thunkApi.signal);
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const createExport = createAsyncThunk(
  'search/createExport',
  async (
    payload: Paths.CreateExportSearchSearchIdExportPost.PathParameters & { callback: (id: number) => void },
    thunkApi
  ) => {
    try {
      const { callback, ...rest } = payload;
      const { data } = await dataServiceApi.createExport(rest);
      callback(data.data.attributes.export_id);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const getExportById = createAsyncThunk(
  'search/getExportById',
  async (payload: Paths.GetExportSearchExportsExportIdGet.PathParameters, thunkApi) => {
    try {
      const { data } = await dataServiceApi.getExportById(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchExportFile = createAsyncThunk(
  'search/fetchExportFile',
  async (payload: Paths.DownloadExportSearchExportsExportIdDownloadGet.PathParameters, thunkApi) => {
    try {
      const { data } = await dataServiceApi.getMessagesExportFile(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);
export const createSearchStats = createAsyncThunk(
  'search/createSearchStats',
  async (
    payload: Paths.CreateStatisticSearchStatsSearchesSearchIdPost.PathParameters & { callback: (id: number) => void },
    thunkApi
  ) => {
    try {
      const { callback, ...rest } = payload;
      const {
        data: { data },
      } = await dataServiceApi.createSearchStats(rest);
      callback(data.attributes.search_id);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const getSearchStatsById = createAsyncThunk(
  'search/getSearchStatsById',
  async (payload: Paths.GetSearchSearchSearchIdGet.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await dataServiceApi.getSearchById(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchSearchStats = createAsyncThunk(
  'search/fetchSearchStats',
  async (payload: Paths.GetSearchStatusStatsSearchesSearchIdGet.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await dataServiceApi.getSearchStatsById(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

import { EuiButton, EuiFieldText, EuiForm, EuiFormRow } from '@elastic/eui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, ObjectSchema, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';

import { appActions, appSelectors } from '@/store/slices/app';
import { useDispatch, useSelector } from '@/store';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';

type FormFields = Pick<Paths.UpdateUserSettingsUsersSettingsPut.RequestBody, 'nickname'>;

const schema: ObjectSchema<FormFields> = object().shape({
  nickname: string().required('form.error.required'),
});

export const IdentityForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const user = useSelector(appSelectors.getUser);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      nickname: user?.attributes.nickname || '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = useCallback(
    async (data: FormFields) => {
      try {
        setLoading(true);
        await dispatch(appActions.updateAccount(data)).unwrap();
        dispatch(
          toastActions.create({
            type: Toast.Type.SUCCESS,
            message: 'toast.success.updateIdentity',
            title: 'toast.success.title',
          })
        );
      } catch (e: any) {
        dispatch(
          toastActions.create({
            type: Toast.Type.ERROR,
            message: 'toast.error.unknown',
            title: 'toast.error.title',
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );
  return (
    <EuiForm component="form" className="formWrapper" onSubmit={handleSubmit(onSubmit)}>
      <EuiFormRow label={t('form.label.organization')} fullWidth>
        <EuiFieldText value={user?.attributes.organization_name} fullWidth disabled />
      </EuiFormRow>
      <EuiFormRow label={t('form.label.role')} fullWidth>
        <EuiFieldText value={user?.attributes.role} fullWidth disabled />
      </EuiFormRow>
      <EuiFormRow label={t('form.label.login')} fullWidth>
        <EuiFieldText value={user?.attributes.email} fullWidth disabled />
      </EuiFormRow>
      <Controller
        control={control}
        name="nickname"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow label={t('form.label.nickname')} fullWidth>
            <EuiFieldText value={value} onChange={onChange} onBlur={onBlur} isInvalid={!!error} fullWidth />
          </EuiFormRow>
        )}
      />
      <EuiButton isLoading={isLoading} type="submit" color="primary" fill className="primaryBtn buttonS">
        {t('button.changeIdentity')}
      </EuiButton>
    </EuiForm>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { customerServiceApi } from '@/api/customer';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (payload: Paths.ReadUsersUsersGet.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.getUsers(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchUsersStats = createAsyncThunk(
  'users/fetchUsersStats',
  async (payload: Paths.GetUsersWithStatsStatsUsersGet.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.getUsersStats(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload: Paths.CreateUserUsersPost.RequestBody, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.createUser(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const editUserById = createAsyncThunk(
  'users/editUserById',
  async (
    payload: Paths.UpdateUserByIdUsersUserIdPut.PathParameters & Paths.UpdateUserByIdUsersUserIdPut.RequestBody,
    thunkApi
  ) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.updateUserById(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const editUsers = createAsyncThunk(
  'users/editUsers',
  async (payload: Paths.UpdateUsersUsersPut.QueryParameters & Paths.UpdateUsersUsersPut.RequestBody, thunkApi) => {
    try {
      const { data } = await customerServiceApi.updateUsers(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const deleteUserById = createAsyncThunk(
  'users/deleteUserById',
  async (payload: Paths.DeleteUserUsersUserIdDelete.PathParameters, thunkApi) => {
    try {
      await customerServiceApi.deleteUserById(payload);
      return payload;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

import { FC, useMemo } from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import { EuiLink } from '@elastic/eui';

interface ChannelLinkProps {
  copyParams: string;
  channel_id: number;
  channel_name: string;
  className: string;
}

export const ChannelLink: FC<ChannelLinkProps> = ({ copyParams, channel_id, channel_name, className }) => {
  const href = useMemo(() => {
    const url = new URL(copyParams);
    url.searchParams.delete('channel_name');

    url.searchParams.set('channel_id', String(channel_id));
    url.searchParams.set('channel_id_type', 'channel_id');

    return url;
  }, [channel_id, copyParams]);

  const handleClickHref = useLinkClickHandler(href, { target: '_blank' });

  return (
    <EuiLink href={href.toString()} target="_blank" onClick={handleClickHref} className={className} external={false}>
      {channel_name}
    </EuiLink>
  );
};

import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiIcon,
  EuiPanel,
  EuiPopover,
  EuiScreenReaderOnly,
  EuiTablePagination,
  EuiText,
  EuiToolTip,
} from '@elastic/eui';
import { css } from '@emotion/css';

import { DeleteOrganizationModal, EditOrganizationModal, EmptyData, Loader } from '@/components';
import { ReactComponent as Boxes } from '@/assets/boxesHorizontalFilled.svg';
import { CONFIG_ITEMS_PER_PAGE } from '@/constants';
import { dateFormatter, getTimeFromNow } from '@/utils';

import { Columns, RawData, TableProps } from './types';

export const OrganizationsTable: FC<TableProps> = ({
  items,
  total,
  limit,
  activePage,
  loading,
  handleChangeItemsPerPage,
  handleChangePage,
  handleAfterDisable,
}) => {
  const { t, i18n } = useTranslation();

  const [rawId, setRawId] = useState<number | null>(null);
  const [rawEdit, setRawEdit] = useState<RawData | null>(null);
  const [rawDelete, setRawDelete] = useState<number | null>(null);

  const onActionClick = useCallback((id: number) => {
    setRawId((prev) => (prev !== id ? id : null));
  }, []);

  const closeActions = useCallback(() => {
    setRawId(null);
  }, []);

  const columnsConfig: EuiBasicTableColumn<RawData>[] = useMemo(
    () => [
      {
        field: Columns.name,
        name: t('table.organizations.column.name'),
        width: '250',
        render: (value: string) => {
          return (
            <EuiFlexGroup direction="row" gutterSize="xs">
              <EuiText
                size="s"
                className={css`
                  font-weight: 600;
                `}
                color="text"
              >
                {value}
              </EuiText>
            </EuiFlexGroup>
          );
        },
      },
      {
        field: Columns.users,
        name: t('table.organizations.column.users'),
        width: '130',
        render: (_, item) => {
          return (
            <EuiFlexGroup gutterSize="none" alignItems="center">
              <EuiToolTip content={t('table.organizations.activeUsers')}>
                <EuiText size="s" color="success">
                  {item.active_users}
                </EuiText>
              </EuiToolTip>
              <EuiToolTip content={t('table.organizations.disabledUsers')}>
                <EuiText size="s" color="text">
                  /{item.inactive_users}
                </EuiText>
              </EuiToolTip>
            </EuiFlexGroup>
          );
        },
      },
      {
        field: Columns.date,
        name: t('table.organizations.column.date'),
        width: '160',
        render: (value: string) => {
          return (
            <EuiToolTip content={dateFormatter(value, process.env.REACT_APP_DATE_TIME_FORMAT)}>
              <EuiFlexGroup direction="row" gutterSize="xs" alignItems="center">
                <EuiIcon type="clock" />
                <EuiText size="s">{getTimeFromNow(value, i18n.language)}</EuiText>
              </EuiFlexGroup>
            </EuiToolTip>
          );
        },
      },
      {
        field: Columns.note,
        name: t('table.organizations.column.description'),
        width: '180',
        render: (value: string) => {
          return (
            <EuiText size="s" color="text">
              {value}
            </EuiText>
          );
        },
      },
      {
        align: 'right',
        width: '35px',
        name: (
          <EuiScreenReaderOnly>
            <span>Actions</span>
          </EuiScreenReaderOnly>
        ),
        render: (value: RawData) => {
          return (
            <EuiPopover
              isOpen={rawId === value.id}
              closePopover={closeActions}
              panelPaddingSize="none"
              anchorPosition="downLeft"
              hasArrow={false}
              offset={4}
              button={
                <EuiButtonIcon
                  aria-label="actions menu"
                  iconType={Boxes}
                  color="text"
                  onClick={() => onActionClick(value.id)}
                />
              }
            >
              <EuiContextMenuPanel
                className="borderedItems"
                items={[
                  <EuiContextMenuItem
                    key="edit"
                    onClick={() => {
                      setRawEdit(value);
                      closeActions();
                    }}
                    size="s"
                  >
                    <EuiText size="s">{t('button.edit')}</EuiText>
                  </EuiContextMenuItem>,
                  <EuiContextMenuItem
                    key="disable"
                    onClick={() => {
                      setRawDelete(value.id);
                      closeActions();
                    }}
                    size="s"
                  >
                    <EuiText
                      color="danger"
                      size="s"
                      className={css`
                        white-space: pre-line;
                      `}
                    >
                      {t('button.disableAllUsers')}
                    </EuiText>
                  </EuiContextMenuItem>,
                ]}
              />
            </EuiPopover>
          );
        },
      },
    ],
    [t, i18n.language, rawId, closeActions, onActionClick]
  );
  if (loading) {
    return (
      <EuiPanel
        hasBorder
        hasShadow={false}
        grow
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        className="tableEmptyData"
      >
        <Loader />
      </EuiPanel>
    );
  }
  if (!total) {
    return <EmptyData note={t('table.organizations.emptyData')} className="tableEmptyData" />;
  }
  return (
    <EuiPanel
      style={{ overflow: 'hidden', width: '100%', position: 'relative' }}
      hasBorder
      hasShadow={false}
      paddingSize="none"
      className="table"
    >
      <EuiBasicTable
        aria-label="List table"
        tableCaption={' '}
        loading={loading}
        itemId="id"
        items={items}
        columns={columnsConfig}
        responsiveBreakpoint={false}
        className="tableOverflow"
      />
      {!!total && !!limit && (
        <EuiTablePagination
          aria-label="List pagination"
          pageCount={Math.ceil(total / limit)}
          activePage={activePage}
          showPerPageOptions={true}
          onChangePage={handleChangePage}
          itemsPerPage={limit}
          onChangeItemsPerPage={handleChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
          responsive={['xs', 's']}
        />
      )}
      {!!rawEdit && <EditOrganizationModal organization={rawEdit} onClose={() => setRawEdit(null)} />}
      {!!rawDelete && (
        <DeleteOrganizationModal
          id={rawDelete}
          onClose={() => setRawDelete(null)}
          handleAfterDisable={handleAfterDisable}
        />
      )}
    </EuiPanel>
  );
};

import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { create, fetchItems, updateById, deleteById } from './thunks';

const initialState: State = {
  loading: true,
  total_items: 0,
  items: [],
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          const { items, total_items } = action.payload;
          state.items = items;
          state.total_items = total_items;
        }
      })
      .addCase(fetchItems.rejected, (state) => {
        state.loading = false;
      });
    builder.addCase(updateById.fulfilled, (state, action) => {
      if (action.payload) {
        state.items = state.items.map((item) => (item.id === action.payload.id ? action.payload.attributes : item));
      }
    });
  },
});

export const actions = {
  ...slice.actions,
  fetchItems,
  updateById,
  deleteById,
  create,
};

export default slice.reducer;

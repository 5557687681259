import { captureException } from '@sentry/react';
import moment from 'moment';

export const dateFormatter = (date?: string | null | number, format = process.env.REACT_APP_DATE_TIME_FORMAT) => {
  if (!date) return '';
  try {
    return moment(date).format(format);
  } catch (e) {
    captureException(e);
    return date;
  }
};

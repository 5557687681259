import { FC, ReactNode, useMemo } from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import {
  EuiPageSidebar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAvatar,
  EuiText,
  EuiSpacer,
  useEuiTheme,
  EuiPanel,
  EuiButtonIcon,
  EuiContextMenuPanelDescriptor,
  EuiContextMenu,
  EuiPopoverTitle,
  EuiPopover,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { Routes } from '@/routes';
import AccountSettings from '@/assets/account.png';
import { useSelector } from '@/store';
import { appSelectors } from '@/store/slices/app';
import { Logo } from '../Logo';
import { useIsOpenControl, useRole } from '@/hooks';
import { Roles } from '@/types';
import { prepareNameForAvatar } from '@/utils';

import { OrganizationSelect } from './OrganizationSelect';

export const SideBar: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const { isOpen, close, toggle } = useIsOpenControl(false);
  const { validateAccessRole } = useRole([]);

  const user = useSelector(appSelectors.getUser);
  const currentOrganizationName = useSelector(appSelectors.getCurrentOrganizationName);

  const handleClickMyAccountHref = useLinkClickHandler(Routes.ACCOUNT);
  const handleClickLogoutHref = useLinkClickHandler(Routes.LOGOUT);
  const handleClickOrganizationHref = useLinkClickHandler(Routes.ORGANIZATIONS);
  const handleClickUsersHref = useLinkClickHandler(Routes.USERS);
  const handleClickUsersStatsHref = useLinkClickHandler(Routes.USERS_STATS);

  const panels = useMemo<
    (EuiContextMenuPanelDescriptor & {
      roles?: Roles.Role[];
    })[]
  >(() => {
    const items = [
      {
        name: (
          <EuiText color="text" size="xs">
            {t('headerMenu.users')}
          </EuiText>
        ),
        href: Routes.USERS,
        roles: [Roles.Role.ADMIN, Roles.Role.SUPER_ADMIN],
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickUsersHref(e);
            close();
          }
        },
      },
      {
        name: (
          <EuiText color="text" size="xs">
            {t('headerMenu.users_stats')}
          </EuiText>
        ),
        href: Routes.USERS_STATS,
        roles: [Roles.Role.ADMIN, Roles.Role.SUPER_ADMIN],
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickUsersStatsHref(e);
            close();
          }
        },
      },
      {
        name: (
          <EuiText color="text" size="xs">
            {t('headerMenu.organizations')}
          </EuiText>
        ),
        href: Routes.ORGANIZATIONS,
        roles: [Roles.Role.SUPER_ADMIN],
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickOrganizationHref(e);
            close();
          }
        },
      },
      {
        name: (
          <EuiText color="text" size="xs">
            {t('headerMenu.account')}
          </EuiText>
        ),
        href: Routes.ACCOUNT,
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickMyAccountHref(e);
            close();
          }
        },
      },
      {
        name: (
          <EuiText color="danger" size="xs">
            {t('headerMenu.logout')}
          </EuiText>
        ),
        href: Routes.LOGOUT,
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickLogoutHref(e);
            close();
          }
        },
      },
    ];
    return [
      {
        id: 0,
        items: items.filter((item) => !(item.roles && !validateAccessRole(item.roles))),
      },
    ];
  }, [
    t,
    handleClickUsersHref,
    close,
    handleClickUsersStatsHref,
    handleClickOrganizationHref,
    handleClickMyAccountHref,
    handleClickLogoutHref,
    validateAccessRole,
  ]);

  return (
    <EuiPageSidebar paddingSize="none" sticky minWidth={395} responsive={['xs', 's', 'm', 'l']}>
      <EuiPanel
        grow
        borderRadius="none"
        className={css`
          height: 100%;

          @media (max-width: 1199px) {
            height: auto;
          }
        `}
        paddingSize="none"
        hasShadow={false}
        hasBorder
      >
        <EuiFlexGroup
          direction="column"
          className={css`
            height: 100%;

            @media (max-width: 1199px) {
              height: auto;
              flex-direction: row;
              flex-wrap: wrap;
              column-gap: 0px;
            }
          `}
        >
          <EuiFlexItem
            grow={0}
            className={css`
              padding-inline: ${euiTheme.size.l};
              @media (max-width: 1199px) {
                flex-grow: 1;
                padding-left: 20px;
                padding-right: 0%;
              }
            `}
          >
            <EuiSpacer size="l" />
            <Logo isLink />
          </EuiFlexItem>
          <EuiFlexItem
            grow={1}
            className={css`
              border-top: 1px solid ${euiTheme.colors.lightShade};
              height: 100%;
              overflow: hidden;

              @media (max-width: 1199px) {
                order: 2;
                min-inline-size: 100%;
                padding-inline: 0;
              }
            `}
          >
            {children}
          </EuiFlexItem>
          <EuiFlexItem
            grow={0}
            className={css`
              padding-inline: ${euiTheme.size.l};
              border-top: 1px solid ${euiTheme.colors.lightShade};
              @media (max-width: 1199px) {
                padding-inline: 20px;
              }
            `}
          >
            <EuiSpacer size="l" />
            <EuiFlexGroup alignItems="center" gutterSize="m" style={{ flexWrap: 'nowrap' }}>
              <EuiFlexItem
                className={css`
                  flex-direction: row;
                  gap: 10px;
                  @media (max-width: 1199px) {
                    display: none;
                  }
                `}
              >
                <EuiAvatar
                  size="l"
                  name={prepareNameForAvatar(user?.attributes.nickname || user?.attributes.email || '')}
                  type="space"
                  initialsLength={2}
                />
                <EuiFlexItem>
                  <EuiText>{user?.attributes.nickname || user?.attributes.email}</EuiText>
                  <EuiText size="xs" color="subdued">
                    {t('headerMenu.org', { name: currentOrganizationName || user?.attributes.organization_name })}
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexItem>

              <EuiPopover
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                isOpen={isOpen}
                closePopover={close}
                panelPaddingSize="none"
                anchorPosition="rightDown"
                hasArrow={false}
                offset={2}
                button={
                  <EuiButtonIcon
                    iconType={AccountSettings}
                    iconSize="xl"
                    onClick={toggle}
                    style={{ inlineSize: 40, blockSize: 40 }}
                  />
                }
              >
                <EuiPopoverTitle paddingSize="s" style={{ borderColor: euiTheme.colors.lightShade }}>
                  <EuiText
                    size="s"
                    className={css`
                      font-weight: 500;
                      padding: 4px;
                    `}
                  >
                    {user?.attributes.nickname || user?.attributes.email}
                  </EuiText>
                  <EuiFlexGroup gutterSize="xs" alignItems="center">
                    <EuiText
                      color="subdued"
                      size="xs"
                      className={css`
                        padding-inline: 4px;
                        padding-bottom: 4px;
                      `}
                    >
                      {t('headerMenu.org', { name: '' })}
                    </EuiText>
                    <EuiFlexItem grow={1}>
                      <OrganizationSelect />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPopoverTitle>
                <EuiContextMenu initialPanelId={0} panels={panels} className="borderedItems" />
              </EuiPopover>
            </EuiFlexGroup>
            <EuiSpacer
              className={css`
                @media (max-width: 1199px) {
                  display: none;
                }
              `}
              size="l"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </EuiPageSidebar>
  );
};

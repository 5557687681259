import { FC } from 'react';
import { EuiFlexGroup, EuiPanel, EuiText, EuiTitle, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { TG_USER_ERROR_STATUS } from '@/constants';

type EmptySearchProps = {
  hasProfile: boolean;
  profileActivity?: string | null;
};

export const EmptySearch: FC<EmptySearchProps> = ({ hasProfile, profileActivity }) => {
  const { euiTheme } = useEuiTheme();
  const { t } = useTranslation();

  return (
    <EuiFlexGroup style={{ minHeight: '100%' }} alignItems="center" justifyContent="center">
      <EuiPanel hasShadow={false} paddingSize="xl" hasBorder style={{ maxWidth: 620 }}>
        <EuiTitle size="s">
          <h1
            className={css`
              font-weight: 600;
              font-size: 18px;
              padding-bottom: ${euiTheme.size.xs};
              border-bottom: 1px solid ${euiTheme.colors.lightShade};
            `}
          >
            {t('page.searchDetails.searchResult.title')}
          </h1>
        </EuiTitle>
        <EuiText
          size="s"
          className={css`
            padding-block: ${euiTheme.size.m};
          `}
        >
          {!!profileActivity && TG_USER_ERROR_STATUS.includes(profileActivity)
            ? t(`error_messages.${profileActivity}`)
            : t(`page.searchDetails.searchResult.${hasProfile ? 'has_profile' : 'no_messages'}`)}
        </EuiText>
      </EuiPanel>
    </EuiFlexGroup>
  );
};

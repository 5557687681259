import { FC } from 'react';
import { EuiButtonIcon } from '@elastic/eui';

interface SecurityBtnProps {
  isSecurity: boolean;
  toggleSecurity: () => void;
}
export const SecurityFieldBtn: FC<SecurityBtnProps> = ({ isSecurity, toggleSecurity }) => {
  return (
    <EuiButtonIcon
      style={{ backgroundColor: 'transparent' }}
      display="empty"
      iconType={isSecurity ? 'eye' : 'eyeClosed'}
      onClick={toggleSecurity}
      color="text"
      aria-label="Show security field"
    />
  );
};

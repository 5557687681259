import { useCallback, useRef, useState } from 'react';
import {
  EuiButton,
  EuiCheckbox,
  EuiFieldText,
  EuiForm,
  EuiFormErrorText,
  EuiFormRow,
  EuiSpacer,
  useIsWithinBreakpoints,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch } from '@/store';
import { Routes } from '@/routes';
import { appActions } from '@/store/slices/app';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';

import { FormFields, schema } from './shcema';

export const SignInForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      email: '',
      password: '',
      remember_me: false,
      captcha_token: '',
    },
    resolver: yupResolver(schema),
    context: {
      isDevMode: process.env.NODE_ENV === 'development',
    },
  });

  const isResponsive = useIsWithinBreakpoints(['xs']);

  const handleRequest = useCallback(
    async (data: FormFields) => {
      try {
        setLoading(true);
        const response = await dispatch(appActions.login(data)).unwrap();
        if (response?.pending_two_fa) {
          navigate(`${Routes.LOGIN_OTP}/${location.search}`, { state: data });
        } else {
          navigate(`${Routes.SETTINGS_OTP}`, { state: data });
        }
      } catch (e: any) {
        setLoading(false);
        const errors = e.errors as { detail: string }[];
        if (Array.isArray(errors)) {
          setError(errors[0].detail);
        } else if (e.detail?.[0]?.msg) {
          setError(e.detail[0].msg);
        } else {
          dispatch(
            toastActions.create({
              type: Toast.Type.ERROR,
              message: 'toast.error.unknown',
              title: 'toast.error.title',
            })
          );
        }
      }
    },
    [dispatch, location.search, navigate]
  );

  return (
    <EuiForm
      component="form"
      css={{
        width: 550,
        maxWidth: '100%',
        paddingTop: 30,
      }}
      className={css`
        label {
          font-size: 14px;
          font-weight: 400;
        }
      `}
    >
      <Controller
        control={control}
        name="email"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            label={t('form.label.login')}
            isInvalid={!!error}
            error={t(error?.message as string)}
            fullWidth
            style={{ gap: 10 }}
            isDisabled={loading}
          >
            <EuiFieldText
              placeholder={t(`form.placeholder.login`)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            label={t('form.label.password')}
            isInvalid={!!error}
            error={t(error?.message as string)}
            fullWidth
            style={{ gap: 10, marginTop: 15 }}
            isDisabled={loading}
          >
            <EuiFieldText
              type="password"
              placeholder={t(`form.placeholder.${name}`)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="remember_me"
        render={({ field: { value, name, onChange } }) => (
          <EuiFormRow style={{ marginTop: 15 }}>
            <EuiCheckbox
              id={name}
              label={t(`form.label.${name}`)}
              checked={value}
              onChange={onChange}
              className={css`
                .euiCheckbox__input ~ .euiCheckbox__label {
                  padding-left: 34px;
                }
              `}
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="captcha_token"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <EuiFormRow style={{ marginTop: 30 }} isInvalid={!!error} error={t(error?.message as string)} fullWidth>
            <ReCAPTCHA
              theme="light"
              ref={captchaRef}
              sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY as string}
              onChange={(token) => onChange(token ?? '')}
              size={isResponsive ? 'compact' : 'normal'}
            />
          </EuiFormRow>
        )}
      />
      {!!error && (
        <EuiFormRow>
          <EuiFormErrorText>{t(error)}</EuiFormErrorText>
        </EuiFormRow>
      )}
      <EuiSpacer size="xl" />
      <EuiButton
        fill
        fullWidth
        type="submit"
        isLoading={loading}
        onClick={handleSubmit(handleRequest)}
        color="primary"
        className="primaryBtn"
      >
        {t('button.signIn')}
      </EuiButton>
    </EuiForm>
  );
};

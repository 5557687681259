import { ReduxState } from '../../store';

export const getLoading = (state: ReduxState) => state.search.isLoading;
export const getLoadingExport = (state: ReduxState) => state.search.isLoadingExport;
export const getDetail = (state: ReduxState) => state.search.detail;
export const getStatus = (state: ReduxState) => state.search.detail?.status;
export const getItems = (state: ReduxState) => state.search.items;
export const getStats = (state: ReduxState) => state.search.stats;
export const getExport = (state: ReduxState) => state.search.export;

export const getLoadingStats = (state: ReduxState) => state.search.isLoadingStats;
export const getStatsId = (state: ReduxState) => state.search.statsId;
export const getStatsStatus = (state: ReduxState) => state.search.statsStatus;

import { FC, useRef, useState } from 'react';

import { ModalLayout } from '../ModalLayout';
import { useDispatch } from '@/store';
import { usersActions } from '@/store/slices/users';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';

import { EditUserForm, UpdateUserFormRef } from './EditUserFrom';
import { Fields } from './schema';

interface EditUserModalProps {
  onClose: () => void;
  user: Components.Schemas.User;
}
const FORM_ID = 'editUser';

export const EditUserModal: FC<EditUserModalProps> = ({ onClose, user }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<UpdateUserFormRef>(null);
  const dispatch = useDispatch();

  const onSubmit = async (data: Fields) => {
    try {
      setLoading(true);
      delete data.password;
      if (!data.new_password) delete data.new_password;
      await dispatch(usersActions.editUserById({ user_id: user.id, ...data })).unwrap();
      dispatch(
        toastActions.create({
          type: Toast.Type.SUCCESS,
          title: 'toast.success.title',
          message: 'toast.success.updateUser',
        })
      );
      onClose();
    } catch (e: any) {
      setLoading(false);
      const errors = e.errors as { errors: { loc: string; detail: string }[] };
      if (Array.isArray(errors)) {
        errors.forEach(({ loc, detail }) => {
          formRef.current?.setError(loc || 'email', {
            message: detail,
          });
        });
      } else if (e.detail?.[0]) {
        formRef.current?.setError(e.detail[0].loc?.[1], {
          message: e.detail[0].msg,
        });
      } else {
        dispatch(
          toastActions.create({
            type: Toast.Type.ERROR,
            message: 'toast.error.unknown',
            title: 'toast.error.title',
          })
        );
      }
    }
  };
  return (
    <ModalLayout formName={FORM_ID} onClose={onClose} isLoading={loading}>
      <EditUserForm id={FORM_ID} onSubmit={onSubmit} user={user} ref={formRef} />
    </ModalLayout>
  );
};

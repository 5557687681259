import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { organizationsActions } from '@/store/slices/organizations';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';
import { useDispatch } from '@/store';
import { ConfirmModal } from '../ConfirmModal';

interface DeleteOrganizationModalProps {
  id: number;
  onClose: () => void;
  handleAfterDisable?: () => void;
}

export const DeleteOrganizationModal: FC<DeleteOrganizationModalProps> = ({ id, onClose, handleAfterDisable }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await dispatch(
        organizationsActions.deleteById({
          organization_id: id,
        })
      ).unwrap();
      dispatch(
        toastActions.create({
          type: Toast.Type.SUCCESS,
          message: t('toast.success.disableUsers'),
          title: 'toast.success.title',
        })
      );
      if (handleAfterDisable) {
        handleAfterDisable();
      }
      onClose();
    } catch (e) {
      dispatch(
        toastActions.create({
          type: Toast.Type.ERROR,
          message: 'toast.error.unknown',
          title: 'toast.error.title',
        })
      );
    } finally {
      setLoading(false);
      close();
    }
  };
  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={handleSubmit}
      color="danger"
      title={t('modal.deleteOrganization.title')}
      note={t('modal.deleteOrganization.note')}
      btnText={t('button.disable')}
      isLoading={isLoading}
    />
  );
};

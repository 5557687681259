import { useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch } from '@/store';
import { appActions } from '@/store/slices/app';

import { FormFields, schema } from './shcema';

export const SignInFormOtp = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      otp: '',
      remember_me: Boolean(state?.remember_me),
    },
    resolver: yupResolver(schema),
  });

  const handleRequest = useCallback(
    async (data: FormFields) => {
      try {
        setIsLoading(true);
        await dispatch(appActions.verifyTwoAuth(data)).unwrap();
      } catch (e: any) {
        console.log(e);
        setIsLoading(false);
        if (e.errors?.[0]?.detail) {
          setError('otp', { message: e.errors[0].detail });
        } else {
          setError('otp', { message: 'some error' });
        }
      }
    },
    [dispatch, setError]
  );

  return (
    <EuiForm
      component="form"
      css={{
        width: 550,
        maxWidth: '100%',
      }}
      onSubmit={handleSubmit(handleRequest)}
    >
      <EuiTitle size="m">
        <h1 style={{ fontWeight: 500, fontSize: 24, marginBottom: 10 }}>{t('page.signInOtp.title')}</h1>
      </EuiTitle>
      <EuiText size="s" color="text">
        {t('page.signInOtp.subTitle')}
      </EuiText>
      <EuiSpacer size="xl" />
      <Controller
        control={control}
        name="otp"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            label={t('form.label.confirm_code')}
            isInvalid={!!error}
            error={t(error?.message as string)}
            fullWidth
            style={{ gap: 10 }}
            isDisabled={isLoading}
            className={css`
              label {
                font-size: 14px;
                font-weight: 400;
              }
            `}
          >
            <EuiFieldText value={value} onChange={onChange} onBlur={onBlur} isInvalid={!!error} fullWidth autoFocus />
          </EuiFormRow>
        )}
      />
      <EuiSpacer size="xl" />
      <EuiButton fill fullWidth type="submit" isLoading={isLoading} className="primaryBtn">
        {t('button.signIn')}
      </EuiButton>
    </EuiForm>
  );
};

import { FC, ReactNode } from 'react';
import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from '@elastic/eui';
import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { ReactComponent as GlobePointer } from '@/assets/globe_pointer.svg';
import { ReactComponent as Mobile } from '@/assets/mobile.svg';
import { ReactComponent as Info } from '@/assets/info.svg';
import MediaStub from '@/assets/media_stub.png';
import { dateFormatter, getTimeFromNow, prepareNameForAvatar } from '@/utils';
import { Search } from '@/types';
import { ActionMenu } from '../SearchActionsMenu';

import { CollapsedText } from './CollapsedText';
import { TgIconLink } from './TgIconLink';

const TG_LINK = 'https://t.me';
const TG_LINK_WEB = 'https://t.me/s';

const getMessageLink = (msg_id: number, channel_name: string, link = TG_LINK) => {
  if (!channel_name) null;
  return `${link}/${channel_name}/${msg_id}`;
};

type LayoutProps = {
  fromUserId: number;
  fromUserName: string | null;
  date: string;
  editDate?: string | null;
  messageId: number;
  channelName: string | null;
  children: ReactNode | string;
  key?: number | string;
  onClick?: () => void;
  isInteractive?: boolean;
  channelId?: number;
};
export const MessageLayout: FC<LayoutProps> = ({
  fromUserName,
  children,
  fromUserId,
  messageId,
  date,
  editDate,
  channelName,
  key,
  onClick,
  isInteractive,
  channelId,
}) => {
  const { euiTheme } = useEuiTheme();
  const { t, i18n } = useTranslation();
  return (
    <EuiFlexGroup alignItems="flexEnd" gutterSize="s" style={{ maxWidth: 1220, flexWrap: 'nowrap' }} key={key}>
      <EuiAvatar
        size="l"
        name={prepareNameForAvatar(fromUserName || t('messageCard.unknown_username'))}
        initialsLength={1}
        color="#818DFC"
        className={
          css`
            span {
              color: ${euiTheme.colors.body};
              font-weight: 500;
            }
          ` + ' mobileHidden'
        }
      />
      <EuiPanel
        hasBorder={false}
        hasShadow={false}
        paddingSize="s"
        className={css`
          background-color: ${euiTheme.colors.lightestShade};
          padding-top: 4px;
        `}
      >
        {isInteractive ? (
          <>
            <ActionMenu
              btnText={fromUserName || t('messageCard.unknown_username.title')}
              user_id={fromUserId}
              channel_id={channelId}
              btnColor={fromUserName ? euiTheme.colors.primary : 'var(--noname-btn-color)'}
            />
            {!fromUserName && (
              <EuiToolTip position="right" content={<p>{t('messageCard.unknown_username.tooltip')}</p>}>
                <EuiIcon
                  type={Info}
                  size="s"
                  className={css`
                    margin-left: 4px;
                    color: var(--noname-btn-color);
                  `}
                />
              </EuiToolTip>
            )}
          </>
        ) : (
          <EuiText
            color="primary"
            size="s"
            className={css`
              color: ${euiTheme.colors.primary};
              font-weight: 600;
            `}
          >
            {fromUserName || t('messageCard.unknown_username.title')}
          </EuiText>
        )}
        <EuiFlexGroup
          gutterSize="m"
          className={css`
            padding-block: ${euiTheme.size.xs};
            row-gap: ${euiTheme.size.xs};
          `}
        >
          <EuiText color="subdued" size="xs">
            {t('messageCard.user_id', { id: fromUserId })}
          </EuiText>
          <EuiText color="subdued" size="xs">
            {t('messageCard.msg_id', { id: messageId })}
          </EuiText>
          <EuiText color="subdued" size="xs">
            {t('messageCard.age', { date: date ? getTimeFromNow(date, i18n.language) : '--' })}
          </EuiText>
        </EuiFlexGroup>
        {children}
        <EuiText color="subdued" size="xs" style={{ textAlign: 'end' }} className="whiteSpaceText">
          {dateFormatter(date, process.env.REACT_APP_DATE_TIME_FORMAT)}
          {editDate ? (
            <>
              &nbsp;
              {t('messageCard.edit', { date: dateFormatter(editDate, process.env.REACT_APP_DATE_TIME_FORMAT) })}
            </>
          ) : (
            ''
          )}
        </EuiText>
      </EuiPanel>
      <EuiFlexItem
        grow={0}
        className={css`
          flex-grow: 0;
          flex-shrink: 0;
          inline-size: fit-content !important;
          flex-basis: fit-content !important;
        `}
      >
        <TgIconLink
          iconType={Mobile}
          href={isInteractive && channelName ? getMessageLink(messageId, channelName, TG_LINK_WEB) : null}
          onClick={onClick}
        />
        <EuiSpacer size="xs" />
        <TgIconLink
          iconType={GlobePointer}
          href={isInteractive && channelName ? getMessageLink(messageId, channelName) : null}
          onClick={onClick}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

type CardProps = {
  data: Components.Schemas.Message;
  onClick?: () => void;
};
export const MessageCard: FC<CardProps> = ({ data, onClick }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  return (
    <MessageLayout
      fromUserId={data.from_user_id}
      fromUserName={data.from_user_name}
      date={data.date}
      messageId={data.message_id}
      editDate={data.edit_date}
      channelName={data.channel_name}
      isInteractive
      onClick={onClick}
      channelId={data.channel_id}
    >
      {(!!data.reply_to || data.type === Search.MessagesTypes.JOINED) && (
        <EuiCallOut
          iconType={''}
          color="primary"
          size="s"
          className={css`
            margin-block: ${euiTheme.size.s};
            position: relative;
            width: fit-content;
            border-radius: ${euiTheme.size.xs};
            ::before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: ${euiTheme.size.xxs};
              background-color: ${euiTheme.colors.primary};
              border-top-left-radius: ${euiTheme.size.xs};
              border-bottom-left-radius: ${euiTheme.size.xs};
            }
          `}
        >
          <EuiText
            size="xs"
            color="primary"
            className={css`
              font-style: italic;
              color: ${euiTheme.colors.primary};
            `}
          >
            {data.reply_to ? (
              <Trans
                i18nKey="messageCard.reply_to"
                values={{ id: data.reply_to }}
                components={[
                  data.channel_name ? (
                    <EuiLink
                      key={0}
                      href={getMessageLink(data.reply_to, data.channel_name)}
                      target="_blank"
                      external={false}
                      className={css`
                        text-decoration: underline;
                      `}
                    />
                  ) : (
                    <EuiButtonEmpty
                      key={0}
                      size="xs"
                      onClick={onClick}
                      type="button"
                      style={{ fontWeight: 400, fontStyle: 'italic', blockSize: 'auto' }}
                      flush="both"
                      textProps={false}
                      contentProps={{
                        className: css`
                          &.euiButtonEmpty__content {
                            padding: 0;
                            text-decoration: underline;
                          }
                        `,
                      }}
                    />
                  ),
                ]}
              />
            ) : (
              t('messageCard.user_joined')
            )}
          </EuiText>
        </EuiCallOut>
      )}
      {!!data.has_media && (
        <EuiFlexGroup
          alignItems="center"
          gutterSize="xs"
          className={css`
            margin-block: ${euiTheme.size.xs};
          `}
        >
          <EuiIcon type={MediaStub} size="l" />
          <EuiText size="xs" color="subdued">
            {t('messageCard.media')}
          </EuiText>
        </EuiFlexGroup>
      )}
      {!!data.content && <CollapsedText text={data.content} />}
    </MessageLayout>
  );
};

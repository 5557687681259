import { Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import { Routes } from '@/routes';
import { PageLoader } from '../Loader';
import { useDispatch, useSelector } from '@/store';
import { appActions, appSelectors } from '@/store/slices/app';

export const AuthRequired = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(appSelectors.getAuthenticated);
  const isEnabledTwoAuth = useSelector(appSelectors.getEnableTwoFa);
  const isLoading = useSelector(appSelectors.getLoading);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(appActions.getAccount());
    }
  }, [dispatch, isAuthenticated]);

  if (isAuthenticated) {
    if (isLoading) {
      return <PageLoader />;
    }

    if (!isEnabledTwoAuth) {
      return <Navigate to={Routes.SETTINGS_OTP} />;
    }

    return (
      <>
        <Outlet />
      </>
    );
  }

  const redirectUrl = `${window.location.pathname}${window.location.search}`;
  const navigateTo = `${Routes.LOGIN}/?redirect=${redirectUrl}`;
  return <Navigate to={navigateTo} replace />;
};

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiText } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { bigNumberFormatter } from '@/utils';
import TzLogo from '@/assets/partners/tz_logo.png';
import NCCCLogo from '@/assets/partners/NCCC_logo.png';
import DetectedLogo from '@/assets/partners/detected_logo.svg';
import { ReactComponent as ChLogoTryzub } from '@/assets/partners/ch_logo_tryzub.svg';
import { ReactComponent as ChLogo } from '@/assets/partners/ch_logo.svg';
import { useSelector } from '@/store';
import { appSelectors } from '@/store/slices/app';
import { STATS_STUB } from '@/constants';

export const Counter = () => {
  const { t } = useTranslation();
  const stats = useSelector(appSelectors.getStats);

  return (
    <EuiFlexGroup className="counterWrapper">
      <EuiPanel hasShadow={false} hasBorder paddingSize="s">
        <EuiText size="xs" color="subdued">
          {t('page.home.counter.messages')}
        </EuiText>
        <EuiText size="s" style={{ fontWeight: 500, marginTop: 8 }}>
          {'> ' + bigNumberFormatter(stats.total_messages || STATS_STUB.messages)}
        </EuiText>
      </EuiPanel>
      <EuiPanel hasShadow={false} hasBorder paddingSize="s">
        <EuiText size="xs" color="subdued">
          {t('page.home.counter.channels')}
        </EuiText>
        <EuiText size="s" style={{ fontWeight: 500, marginTop: 8 }}>
          {'> ' + bigNumberFormatter(stats.total_channels || STATS_STUB.channels)}
        </EuiText>
      </EuiPanel>
      <EuiPanel hasShadow={false} hasBorder paddingSize="s">
        <EuiFlexGroup alignItems="center" justifyContent="flexStart" gutterSize="xl" className="logosWrapper">
          <EuiFlexItem grow={0}>
            <EuiText
              size="xs"
              color="subdued"
              className={css`
                @media (min-width: 768px) {
                  whitespace: pre-line;
                }
              `}
            >
              {t('page.home.counter.partners')}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiFlexGroup gutterSize="none" alignItems="center">
              <EuiIcon type={TzLogo} size="xxl" />
              <EuiText size="m" style={{ fontWeight: 500 }}>
                {t('logo.tz')}
              </EuiText>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiFlexGroup gutterSize="none" alignItems="center">
              <EuiIcon type={ChLogoTryzub} size="xxl" />
              <EuiIcon type={ChLogo} size="xxl" style={{ width: 73, height: 23 }} />
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiIcon type={NCCCLogo} size="xxl" style={{ width: 97, height: 40 }} />
          </EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiIcon type={DetectedLogo} size="xxl" style={{ width: 123, height: 15 }} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </EuiFlexGroup>
  );
};

import { useCallback } from 'react';

import { useSelector } from '@/store';
import { appSelectors } from '@/store/slices/app';
import { Roles } from '@/types';

export const ROLES_OPTIONS = [
  {
    value: Roles.Role.SUPER_ADMIN,
    access: [Roles.Role.SUPER_ADMIN],
  },
  {
    value: Roles.Role.ADMIN,
    access: [Roles.Role.SUPER_ADMIN],
  },
  {
    value: Roles.Role.USER,
    access: [Roles.Role.SUPER_ADMIN, Roles.Role.ADMIN],
  },
];

export const useRole = (roles?: Roles.Role[]) => {
  const user = useSelector(appSelectors.getUser);

  const validateAccessRole = useCallback(
    (accessRoles: Roles.Role[]) =>
      !!user && !!user.attributes.role && accessRoles.includes(user.attributes.role as Roles.Role),
    [user]
  );

  return {
    validateAccessRole,
    isValid: !!user && !!user.attributes.role && roles && roles.includes(user.attributes.role as Roles.Role),
    role: user?.attributes.role as Roles.Role,
  };
};

import { FC, useCallback, useState } from 'react';
import { EuiButtonEmpty, EuiText, useEuiTheme } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

interface CollapsedTextProps {
  text: string;
}
const PARAGRAPH_SEPARATOR = '\n';
const PARAGRAPH_LIMIT = 5;
const MAX_LENGTH = 500;

const getChunk = (text: string, paragraphs: string[]): string => {
  const byParagraph = paragraphs.slice(0, PARAGRAPH_LIMIT).join(PARAGRAPH_SEPARATOR);
  const bySymbols = text.slice(0, MAX_LENGTH);
  return byParagraph.length < bySymbols.length ? byParagraph : bySymbols;
};

export const CollapsedText: FC<CollapsedTextProps> = ({ text }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);

  const paragraphs = text.split(PARAGRAPH_SEPARATOR);
  const shouldTruncate = text.length > MAX_LENGTH || paragraphs.length > PARAGRAPH_LIMIT;
  const chunk = getChunk(text, paragraphs);

  return (
    <EuiText
      size="s"
      className={css`
        white-space: pre-line;
        padding-top: ${euiTheme.size.xs};
        word-break: break-word;
      `}
    >
      {shouldTruncate && isCollapsed ? `${chunk}...` : text}
      {shouldTruncate && (
        <EuiButtonEmpty size="s" onClick={toggleCollapse} color="primary">
          {isCollapsed ? t('button.showText') : t('button.collapse')}
        </EuiButtonEmpty>
      )}
    </EuiText>
  );
};

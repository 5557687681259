import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiImage,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema, boolean, object, string } from 'yup';
import { css } from '@emotion/css';

import { ReactComponent as AuthAppAndroidIcon } from '@/assets/auth_apps/android.svg';
import { ReactComponent as AuthAppIosIcon } from '@/assets/auth_apps/ios.svg';
import { ReactComponent as AuthAppMicrosoftIcon } from '@/assets/auth_apps/microsoft.svg';
import { ScanQrCode } from '@/components';
import { appActions } from '@/store/slices/app';
import { useDispatch } from '@/store';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';

const AUTHENTICATION_APPS = [
  {
    name: 'android',
    icon: AuthAppAndroidIcon,
    url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  },
  {
    name: 'ios',
    icon: AuthAppIosIcon,
    url: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  },
  {
    name: 'microsoft',
    icon: AuthAppMicrosoftIcon,
    // eslint-disable-next-line max-len
    url: 'https://support.microsoft.com/en-us/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a',
  },
];

interface EnabledFormProps {
  onCancel: () => void;
}
type FormFields = { current_password: string; otp: string; remember_me: boolean };
export const schema: ObjectSchema<FormFields> = object().shape({
  current_password: string().required('form.error.required'),
  otp: string().min(6, 'form.error.code_length').max(6, 'form.error.code_length').required('form.error.required'),
  remember_me: boolean().required(),
});
export const EnabledForm: FunctionComponent<EnabledFormProps> = ({ onCancel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [qrCodeImageUrl, setQrCodeImage] = useState<string | null>(null);

  const { control, setError, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      current_password: '',
      otp: '',
      remember_me: Boolean(state?.remember_me),
    },
    resolver: yupResolver(schema),
  });

  const handleCancel = useCallback(() => {
    if (!isLoading) onCancel();
  }, [isLoading, onCancel]);

  const handleRequestImage = useCallback(async () => {
    try {
      const image = await dispatch(appActions.fetchQrImageTwoAuth()).unwrap();
      setQrCodeImage(URL.createObjectURL(image));
      setIsLoadingImage(false);
    } catch (e) {
      dispatch(
        toastActions.create({
          type: Toast.Type.ERROR,
          message: 'toast.error.unknown',
          title: 'toast.error.title',
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    handleRequestImage();
  }, [handleRequestImage]);

  const onSubmit = useCallback(
    async (data: FormFields) => {
      try {
        setIsLoading(true);
        await dispatch(appActions.enableTwoAuth(data)).unwrap();
      } catch (e: any) {
        if (e.errors) {
          setError('otp', { message: e.errors[0]?.detail });
        } else {
          dispatch(
            toastActions.create({
              type: Toast.Type.ERROR,
              message: 'toast.error.unknown',
              title: 'toast.error.title',
            })
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, setError]
  );

  return (
    <>
      <EuiTitle size="s">
        <h1 style={{ fontWeight: 500, fontSize: 24, marginBottom: 10 }}>{t('modal.twoFactorEnable.title')}</h1>
      </EuiTitle>
      <EuiText size="s">{t('modal.twoFactorEnable.subtitle')}</EuiText>
      <EuiSpacer size="xl" />
      <ul
        style={{
          listStyle: 'none',
          padding: 0,
        }}
      >
        <li>
          <EuiText size="s" style={{ fontWeight: 600 }}>
            {t('modal.twoFactorEnable.steps.1')}
          </EuiText>
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: euiTheme.size.xxs,
              paddingBlock: euiTheme.size.m,
            }}
          >
            {AUTHENTICATION_APPS.map(({ name, icon, url }) => (
              <li
                key={name}
                style={{
                  paddingBlock: euiTheme.size.xs,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <EuiIcon type={icon} size="m" />
                <EuiLink
                  style={{
                    marginLeft: euiTheme.size.s,
                  }}
                  target="_blank"
                  href={url}
                >
                  {t(`modal.twoFactorEnable.apps.${name}`)}
                </EuiLink>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <EuiText size="s" style={{ fontWeight: 600 }}>
            {t('modal.twoFactorEnable.steps.2')}
          </EuiText>
          <EuiSpacer size="m" />
          {!!qrCodeImageUrl && !isLoadingImage && (
            <EuiFlexGroup direction="column" alignItems="center" gutterSize="m">
              <EuiImage size={113} src={qrCodeImageUrl} alt="Auth config QRCode" />
              <EuiText
                style={{
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                }}
                size="s"
              >
                {t('modal.twoFactorEnable.qr.note')}
              </EuiText>
              <ScanQrCode image={qrCodeImageUrl}>
                {({ data }) => {
                  if (data) {
                    const { searchParams, pathname } = new URL(data);
                    const labelItems: Record<string, string> = {
                      title: pathname.replace('//totp/', ''),
                      secret: '',
                      period: t('modal.twoFactorEnable.qr.yes'),
                    };
                    searchParams.forEach((value, key) => {
                      if (['secret'].includes(key)) {
                        labelItems[key] = value;
                      }
                    });

                    return (
                      <EuiFlexGroup direction="column" alignItems="center" gutterSize="xs">
                        {Object.entries(labelItems).map(([key, value]) => (
                          <EuiText
                            key={key}
                            size="s"
                            style={{
                              fontWeight: 600,
                              display: 'inline-flex',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                            }}
                          >
                            <span>{t(`modal.twoFactorEnable.qr.items.${key}`)}:&nbsp;</span>
                            <span style={{ wordBreak: 'break-word' }}>{value}</span>
                          </EuiText>
                        ))}
                      </EuiFlexGroup>
                    );
                  }

                  return null;
                }}
              </ScanQrCode>
            </EuiFlexGroup>
          )}
          <EuiSpacer size="m" />
        </li>
        <li>
          <EuiText size="s" style={{ fontWeight: 600 }}>
            {t('modal.twoFactorEnable.steps.3')}
          </EuiText>
          <EuiSpacer size="m" />
          <EuiForm
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            id="two-auth"
            className={css`
              label {
                font-size: 14px;
                font-weight: 400;
              }
            `}
          >
            <Controller
              control={control}
              name="current_password"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <EuiFormRow
                  fullWidth
                  isInvalid={!!error}
                  error={t(error?.message as string)}
                  label={t('form.label.current_password')}
                  style={{ gap: 10, marginTop: 10 }}
                >
                  <EuiFieldText
                    isInvalid={!!error}
                    autoComplete="new-password"
                    fullWidth
                    type={'password'}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
            <Controller
              control={control}
              name="otp"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <EuiFormRow
                  fullWidth
                  isInvalid={!!error}
                  error={t(error?.message as string)}
                  label={t('form.label.otp')}
                  style={{ gap: 10, marginTop: 10 }}
                >
                  <EuiFieldText isInvalid={!!error} fullWidth value={value} onChange={onChange} onBlur={onBlur} />
                </EuiFormRow>
              )}
            />
          </EuiForm>
        </li>
      </ul>
      <EuiSpacer size="xl" />
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem grow>
          <EuiButton
            fill
            isLoading={isLoading}
            form="two-auth"
            type="submit"
            contentProps={{ style: { color: euiTheme.colors.body } }}
            className={css`
              &[class*='isDisabled-fill-disabled'] {
                background-color: ${euiTheme.colors.primary};
              }
            `}
          >
            {t('button.enable')}
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow>
          <EuiButtonEmpty onClick={handleCancel}>{t('button.cancel')}</EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

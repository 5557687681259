import { FC, useRef, useState } from 'react';

import { organizationsActions } from '@/store/slices/organizations';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';
import { useDispatch } from '@/store';
import { ModalLayout } from '../ModalLayout';

import { CreateOrganizationForm, CreateOrganizationFormRef } from './CreateOrganizationForm';

interface CreateOrganizationModalProps {
  onClose: () => void;
  onCreate?: () => void;
}
const FORM_ID = 'createOrganization';

export const CreateOrganizationModal: FC<CreateOrganizationModalProps> = ({ onClose, onCreate }) => {
  const dispatch = useDispatch();

  const formRef = useRef<CreateOrganizationFormRef>(null);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (data: Paths.CreateOrganizationOrganizationsPost.RequestBody) => {
    try {
      setLoading(true);
      await dispatch(organizationsActions.create(data)).unwrap();
      dispatch(
        toastActions.create({
          type: Toast.Type.SUCCESS,
          title: 'toast.success.title',
          message: 'toast.success.createOrganization',
        })
      );
      if (onCreate) {
        onCreate();
      }
      onClose();
    } catch (e: any) {
      setLoading(false);
      const errors = e.errors as { errors: { loc: string; detail: string }[] };
      if (Array.isArray(errors)) {
        errors.forEach(({ loc, detail }) => {
          formRef.current?.setError(loc || 'name', {
            message: detail,
          });
        });
      } else {
        dispatch(
          toastActions.create({
            type: Toast.Type.ERROR,
            message: 'toast.error.unknown',
            title: 'toast.error.title',
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalLayout formName={FORM_ID} onClose={onClose} isLoading={isLoading}>
      <CreateOrganizationForm id={FORM_ID} ref={formRef} onSubmit={onSubmit} />
    </ModalLayout>
  );
};

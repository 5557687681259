export type State = {
  isLoading: boolean;
  access_token: string | null;
  refresh_token: string | null;
  user: Components.Schemas.SingleItemResponseUser | null;
  recovery_codes: string[] | null;
  current_organization_id: number | null;
  organizations: Components.Schemas.Organization[];
  stats: {
    total_messages: number | null;
    total_channels: number | null;
  };
};

export enum AUTH_LOCALSTORAGE_KEYS {
  ACCESS = 'access-token',
  REFRESH = 'refresh-token',
}

export enum SETTINGS_AUTH_LOCALSTORAGE_KEYS {
  ORGANIZATION = 'organization',
}

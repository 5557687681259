import { FC, useMemo } from 'react';
import { EuiIcon, EuiLink, IconType, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';

type TgLinkProps = {
  iconType: IconType;
  href?: string | null;
  onClick?: () => void;
};

export const TgIconLink: FC<TgLinkProps> = ({ iconType, href, onClick }) => {
  const { euiTheme } = useEuiTheme();
  const className = useMemo(
    () => css`
      background-color: ${euiTheme.colors.lightShade};
      color: ${euiTheme.colors.text};
      border-radius: 50%;
      width: ${euiTheme.size.l};
      height: ${euiTheme.size.l};
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    [euiTheme]
  );
  if (href) {
    return (
      <EuiLink href={href} target="_blank" external={false} className={className}>
        <EuiIcon type={iconType} size="s" />
      </EuiLink>
    );
  }
  if (onClick) {
    return (
      <button type="button" className={className} onClick={onClick}>
        <EuiIcon type={iconType} size="s" />
      </button>
    );
  }
  return (
    <div className={className}>
      <EuiIcon type={iconType} size="s" />
    </div>
  );
};

import { object, string, ObjectSchema, boolean, ref } from 'yup';
type FieldsData = Omit<
  Paths.UpdateUserByIdUsersUserIdPut.RequestBody,
  'join_date' | 'hashed_password' | 'last_seen' | 'current_password' | 'organization_id' | 'organization_name'
> & {
  password?: string;
};
export type Fields = {
  [K in keyof FieldsData]: NonNullable<FieldsData[K]>;
};

export const schema: ObjectSchema<Fields> = object().shape({
  user_id: string(),
  email: string().email('form.error.email').required('form.error.required'),
  nickname: string(),
  new_password: string(),
  password: string().oneOf([ref('new_password')], 'form.error.repeat_password'),
  role: string(),
  is_active: boolean(),
});

import { FC } from 'react';
import { EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, useEuiTheme } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { dateFormatter } from '@/utils';
import i18n from '@/i18next';

export const UserCard: FC<{ userInfo: Components.Schemas.UserInfo }> = ({ userInfo }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  return (
    <EuiPanel hasBorder hasShadow={false} paddingSize="s" grow style={{ width: '100%' }}>
      <EuiFlexGroup alignItems="flexStart" style={{ flexWrap: 'nowrap' }}>
        <EuiFlexGroup direction="column" gutterSize="s" style={{ flexGrow: 0 }}>
          <EuiText color="subdued" size="xs">
            {t('userCard.title')}
          </EuiText>
          <div
            className={css`
              margin-block: ${euiTheme.size.s};
              margin-inline: auto;
              background-color: ${euiTheme.colors.lightestShade};
              border: 1px solid ${euiTheme.colors.lightShade};
              width: 55px;
              height: 55px;
              border-radius: 50%;
            `}
          />
        </EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <EuiFlexGrid columns={3} gutterSize="s" className="userInfo">
            <EuiFlexItem>
              <EuiText size="xs" color="subdued">
                {t('userCard.name')}
              </EuiText>
              <EuiText size="xs">
                {userInfo.first_name || userInfo.last_name
                  ? (userInfo.first_name || '') + ' ' + (userInfo.last_name || '')
                  : '-'}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="xs" color="subdued">
                {t('userCard.user_name')}
              </EuiText>
              <EuiText size="xs">{userInfo.username || '-'}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="xs" color="subdued">
                {t('userCard.tg_id')}
              </EuiText>
              <EuiText size="xs">{userInfo.telegram_id || '-'}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="xs" color="subdued">
                {t('userCard.last_activity')}
              </EuiText>
              <EuiText size="xs">
                {userInfo.status?.last_seen
                  ? dateFormatter(userInfo.status.last_seen, `${process.env.REACT_APP_DATE_FORMAT} HH:mm`)
                  : userInfo.status?.activity && i18n.exists(`userCard.status.${userInfo.status.activity}`)
                    ? t(`userCard.status.${userInfo.status.activity}`)
                    : '-'}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="xs" color="subdued">
                {t('userCard.bot')}
              </EuiText>
              <EuiText size="xs">
                {userInfo.is_bot !== null ? t(`userCard.${userInfo.is_bot ? 'yes' : 'no'}`) : '-'}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="xs" color="subdued">
                {t('userCard.checked')}
              </EuiText>
              <EuiText size="xs">
                {userInfo.verified !== null ? t(`userCard.${userInfo.verified ? 'yes' : 'no'}`) : '-'}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGrid>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

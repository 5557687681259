import { FormFields } from '@/components/Forms/SearchForm/shcema';

export const PERIODS = ['1h', '24h', '7d', '14d', '30d', 'year', 'all', 'custom'];
export const DEFAULT_PERIOD = '30d';

export const televisionUser = {
  fromUserName: 'Television',
  fromUserId: 1001,
  channelId: 123,
  channelName: 'main',
};

export const searchExamples: { id: string; searchData: FormFields & { autorun: string } }[] = [
  {
    id: '1',
    searchData: {
      search_term: 'телеграм',
      user_id: '5426981180',
      user_id_type: 'user_id',
      period: 'custom',
      start_date: '2024-02-28 00:00:00Z',
      end_date: '2024-02-28 21:00:00Z',
      autorun: 'true',
    },
  },
  {
    id: '2',
    searchData: {
      search_term: 'Списки беженцев из мариуполя Торез',
      period: 'all',
      autorun: 'true',
    },
  },
  {
    id: '3',
    searchData: {
      search_term: 'атака ncscc.gov.ua',
      period: 'all',
      exact_search: true,
      autorun: 'true',
    },
  },
  {
    id: '4',
    searchData: {
      user_id: '6135621720',
      user_id_type: 'user_id',
      channel_id: '1608999891',
      period: 'year',
      autorun: 'true',
    },
  },
];

export enum ACCOUNT_MENU_ITEMS {
  IDENTITY = 'identity',
  PASSWORD = 'password',
  TWO_AUTH = '2_factor_auth',
  // API = 'api',
}

export const DEFAULT_ITEMS_PER_PAGE = 25;
export const CONFIG_ITEMS_PER_PAGE = [10, 25, 50];

export const DOCS_URL =
  // eslint-disable-next-line max-len
  'https://docs.google.com/document/d/e/2PACX-1vRV0WjcY2e9KWzBD_giDtty2rbna9g52qMGI5opZB4TXLNgG51BEIjPi6kRwYBMjgq2w2DGzMaiGCmf/pub';

export const TG_USER_ERROR_STATUS = ['hidden', 'not_registered', 'deactivated', 'banned'];
export const DEFAULT_START_DATE = '2010-01-01';

export const STATS_STUB = { messages: 30000000000, channels: 1200000 };

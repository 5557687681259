import { FC } from 'react';
import { EuiButtonEmpty, EuiContextMenuPanel, EuiPopover } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { useIsOpenControl } from '@/hooks';

import { ActionMenuItem } from './ActionMenuItem';

type ActionMenuProps = {
  btnText: string;
  channel_id?: number;
  user_id?: number;
  isChannelMenu?: boolean;
  btnColor: string;
};

export const ActionMenu: FC<ActionMenuProps> = ({ btnText, isChannelMenu, channel_id, user_id, btnColor }) => {
  const { isOpen, close, toggle } = useIsOpenControl(false);
  const { t } = useTranslation();
  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={close}
      panelPaddingSize="none"
      anchorPosition="downLeft"
      hasArrow={false}
      offset={0}
      button={
        <EuiButtonEmpty
          onClick={toggle}
          type="button"
          flush="both"
          className={css`
            height: fit-content;
            .euiButtonEmpty__content {
              padding: 0;
              color: ${btnColor};
            }
          `}
          color={isChannelMenu ? 'text' : 'primary'}
        >
          {btnText}
        </EuiButtonEmpty>
      }
    >
      <EuiContextMenuPanel className="borderedItems">
        {isChannelMenu ? (
          <ActionMenuItem title={t('messageCard.menu.in_channel')} onClick={close} channel_id={channel_id} />
        ) : (
          <>
            <ActionMenuItem title={t('messageCard.menu.by_user')} onClick={close} user_id={user_id} />
            <ActionMenuItem
              title={t('messageCard.menu.by_user_in_channel')}
              onClick={close}
              channel_id={channel_id}
              user_id={user_id}
            />
          </>
        )}
      </EuiContextMenuPanel>
    </EuiPopover>
  );
};

import { FC } from 'react';
import {
  EuiButton,
  EuiFlexGroup,
  EuiIcon,
  EuiPage,
  EuiPageSection,
  EuiPageTemplate,
  EuiPanel,
  EuiText,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { Routes } from '@/routes';

export const ErrorFallback: FC = () => {
  const { t } = useTranslation();

  return (
    <EuiPageTemplate grow>
      <EuiPage grow paddingSize="l">
        <EuiFlexGroup alignItems="center" justifyContent="center">
          <EuiPageSection>
            <EuiPanel hasBorder>
              <EuiFlexGroup direction="column" alignItems="center">
                <EuiIcon size="l" type="faceSad" color="primary" />
                <EuiText
                  style={{
                    whiteSpace: 'pre-wrap',
                    textAlign: 'center',
                    maxWidth: 350,
                  }}
                >
                  {t('errorFallback.note')}
                </EuiText>
                <EuiButton fill href={Routes.HOME}>
                  {t('button.reload')}
                </EuiButton>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiPageSection>
        </EuiFlexGroup>
      </EuiPage>
    </EuiPageTemplate>
  );
};

import { FormEventHandler } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { EuiButton, EuiFieldText, EuiFlexGroup, EuiForm, EuiFormRow } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

type EntitySearchFormProps<T extends FieldValues> = {
  handleSearch: FormEventHandler;
  control: Control<T>;
  loading: boolean;
  placeholder: string;
};
export const EntitySearchForm = <
  TFieldValues extends FieldValues = {
    search?: string;
  },
>({
  handleSearch,
  control,
  loading,
  placeholder,
}: EntitySearchFormProps<TFieldValues>) => {
  const { t } = useTranslation();
  return (
    <EuiForm
      component="form"
      css={{
        width: 464,
        maxWidth: '100%',
        paddingTop: 20,
      }}
      className={css`
        label {
          font-size: 14px;
          font-weight: 400;
        }
      `}
    >
      <Controller
        control={control}
        name={'search' as Path<TFieldValues>}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            isInvalid={!!error}
            error={t(error?.message as string)}
            fullWidth
            style={{ gap: 10 }}
            label={t('form.label.search')}
          >
            <EuiFieldText
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
            />
          </EuiFormRow>
        )}
      />
      <EuiFlexGroup
        justifyContent="flexEnd"
        className={css`
          margin-top: 20px;
        `}
      >
        <EuiButton fill type="submit" isLoading={loading} onClick={handleSearch} color="primary" className="primaryBtn">
          {t('button.search')}
        </EuiButton>
      </EuiFlexGroup>
    </EuiForm>
  );
};

import moment, { Moment } from 'moment';

import { DEFAULT_START_DATE } from '@/constants';

export const getConstrainedDateISOString = (date: Moment | null): string => {
  if (!date) return '';

  const now = moment();
  const minDate = moment(DEFAULT_START_DATE);

  if (date.isAfter(now)) return now.toISOString();
  if (date.isBefore(minDate)) return minDate.toISOString();

  return date.toISOString();
};

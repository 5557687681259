import { FC } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText, useEuiTheme } from '@elastic/eui';
import { Trans, useTranslation } from 'react-i18next';

import { Routes } from '@/routes';
import TzLogo from '@/assets/partners/tz_logo.png';
import { ReactComponent as ChLogo } from '@/assets/partners/ch_logo_tryzub.svg';
import { ReactComponent as LogoIcon } from '@/assets/logo.svg';

type LogoProps = { isLink?: boolean };
export const Logo: FC<LogoProps> = ({ isLink }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const icon = <EuiIcon type={LogoIcon} color={'var(--logo-color)'} size="xl" style={{ width: 38, height: 43 }} />;
  const Component = isLink ? Link : EuiText;
  return (
    <EuiFlexGroup gutterSize="s" style={{ flexWrap: 'nowrap' }}>
      {isLink ? (
        <Link key="logo-icon" to={Routes.HOME}>
          {icon}
        </Link>
      ) : (
        icon
      )}
      <EuiFlexItem>
        <Component
          key="television"
          to={Routes.HOME}
          className={css`
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 4px;
            color: ${euiTheme.colors.text};
            width: fit-content;
          `}
        >
          {t('logo.television')}
        </Component>
        <EuiText
          size="xs"
          className={css`
            display: inline-flex;
            align-items: flex-end;
          `}
        >
          <Trans
            i18nKey="logo.client_from"
            components={[
              <EuiIcon
                key={0}
                type={ChLogo}
                className={css`
                  margin-left: 2px;
                `}
              />,
              <EuiIcon key={1} type={TzLogo} />,
            ]}
          />
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

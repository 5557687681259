import { FC, useMemo } from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';

import { ChartData, getChartData, tooltipDateFormatter } from './utils';

export const ActivityChart: FC<ChartData> = ({ data, from, to, copyParams }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const { series, format, step } = useMemo(
    () => getChartData({ data, to, from, copyParams }),
    [data, from, copyParams, to]
  );

  const options = useMemo(() => {
    return {
      chart: {
        type: 'area',
        marginTop: 30,
        marginBottom: 30,
        marginRight: 30,
        height: 113,
        style: {
          fontFamily: euiTheme.font.family,
        },
      },
      title: {
        text: t('page.searchDetails.stats.activity_chart'),
        align: 'left',
        style: {
          fontSize: '12px',
          color: euiTheme.colors.subduedText,
          fontWeight: 400,
        },
      },
      yAxis: {
        minRange: 1,
        title: {
          text: '',
        },
        showEmpty: true,
        softMin: 0,
        min: 0,
        tickAmount: 5,
        allowDecimals: false,
        labels: {
          style: {
            fontSize: '10px',
            color: 'rgba(203, 213, 224, 1)',
          },
        },
        gridLineDashStyle: 'Dash',
      },
      xAxis: {
        type: 'datetime',
        lineWidth: 0,
        title: {
          text: '',
        },
        showEmpty: true,
        labels: {
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            return moment(this.value).format(format);
          },
          style: {
            fontSize: '10px',
            color: 'rgba(203, 213, 224, 1)',
          },
          y: 14,
        },
        tickmarkPlacement: 'on',
        tickLength: 0,
        crosshair: {
          width: 1,
        },
      },
      series,
      tooltip: {
        enabled: true,
        useHTML: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          if (this.point.index === this.series.data.length - 1 && !this.point.y) return false;
          return `
            <p>
             ${tooltipDateFormatter(this.point.x, step, to)}
            </p>
            <p style="font-size: 14px; margin-top: 8px">
              ${t('page.searchDetails.stats.activity_chart_msg')}: <b>${this.point.y}</b>
            </p>
          `;
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          lineWidth: 1.5,
          marker: {
            enabled: false,
          },
        },
      },
    };
  }, [euiTheme.font.family, euiTheme.colors.subduedText, t, format, series, step, to]);

  return (
    <div
      className={css`
        width: 100%;
        height: auto;
        min-width: 300px;
      `}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

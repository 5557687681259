import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiAvatar,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiIcon,
  EuiPanel,
  EuiTablePagination,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from '@elastic/eui';
import { css } from '@emotion/css';

import { EmptyData, Loader } from '@/components';
import { prepareNameForAvatar } from '@/utils';
import { Roles } from '@/types';
import { CONFIG_ITEMS_PER_PAGE } from '@/constants';
import { ReactComponent as Info } from '@/assets/info.svg';

import { Columns, TableProps, UserData } from './types';

export const UsersStatsTable: FC<TableProps> = ({
  data,
  limit,
  total,
  activePage,
  handleChangePage,
  handleChangeItemsPerPage,
  isLoading,
  isUsedFilters,
}) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const columnsConfig: (Partial<EuiBasicTableColumn<UserData>> & { access?: Roles.Role[] })[] = useMemo(
    () => [
      {
        field: Columns.user,
        name: t(`table.users_stats.column.${Columns.user}`),
        width: '400',
        render: (value: string, item: UserData) => {
          return (
            <EuiFlexGroup gutterSize="s" alignItems="center">
              <EuiAvatar
                name={prepareNameForAvatar(value || item.email)}
                size="m"
                initialsLength={1}
                className={css`
                  span {
                    color: #fff;
                    font-weight: 600;
                  }
                `}
              />
              <EuiFlexGroup direction="column" gutterSize="xs">
                {!!value && (
                  <EuiText color={item.is_active ? 'text' : 'subdued'} size="s">
                    {value}
                  </EuiText>
                )}
                <EuiText color="subdued" size="xs">
                  {item.email}
                </EuiText>
              </EuiFlexGroup>
            </EuiFlexGroup>
          );
        },
      },
      {
        field: Columns.status,
        width: '200',
        name: (
          <EuiToolTip
            position="top"
            content={
              <p
                className={css`
                  white-space: pre-wrap;
                `}
              >
                {t('table.users_stats.tooltip.status')}
              </p>
            }
          >
            <EuiFlexGroup alignItems="center" gutterSize="xs">
              {t(`table.users_stats.column.${Columns.status}`)}
              <EuiIcon type={Info} size="s" color={euiTheme.colors.subduedText} />
            </EuiFlexGroup>
          </EuiToolTip>
        ),
        render: (value: boolean) => {
          return (
            <EuiFlexGroup alignItems="baseline" gutterSize="xs">
              <EuiIcon type={value ? 'check' : 'cross'} size="s" color={value ? 'success' : 'danger'} />
              <EuiText size="s">{t(`table.users_stats.status.${value ? 'active' : 'disabled'}`)}</EuiText>
            </EuiFlexGroup>
          );
        },
      },

      {
        field: Columns.weekly,
        name: t(`table.users_stats.column.${Columns.weekly}`),
        width: '250',
        render: (_, item: UserData) => {
          return (
            <EuiFlexGroup gutterSize="none" alignItems="center">
              <EuiToolTip content={t('table.users_stats.requests.total')}>
                <EuiText size="s" color="text">
                  {item.weekly_count}/
                </EuiText>
              </EuiToolTip>
              <EuiToolTip content={t('table.users_stats.requests.success')}>
                <EuiText size="s" color="success">
                  {item.weekly_count_success}
                </EuiText>
              </EuiToolTip>
            </EuiFlexGroup>
          );
        },
      },
      {
        field: Columns.total,
        width: '250',
        name: t(`table.users_stats.column.${Columns.total}`),
        render: (_, item: UserData) => {
          return (
            <EuiFlexGroup gutterSize="none" alignItems="center">
              <EuiToolTip content={t('table.users_stats.requests.total')}>
                <EuiText size="s" color="text">
                  {item.total_count}/
                </EuiText>
              </EuiToolTip>
              <EuiToolTip content={t('table.users_stats.requests.success')}>
                <EuiText size="s" color="success">
                  {item.total_count_success}
                </EuiText>
              </EuiToolTip>
            </EuiFlexGroup>
          );
        },
      },
    ],
    [euiTheme.colors.subduedText, t]
  );

  if (isLoading)
    return (
      <EuiPanel
        hasBorder
        hasShadow={false}
        grow
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        className="tableEmptyData"
      >
        <Loader />
      </EuiPanel>
    );
  if (!total) {
    return (
      <EmptyData note={t(`table.users_stats.${isUsedFilters ? 'notFound' : 'emptyData'}`)} className="tableEmptyData" />
    );
  }
  return (
    <EuiPanel
      style={{ overflow: 'hidden', width: '100%', position: 'relative' }}
      hasBorder
      hasShadow={false}
      paddingSize="none"
      className="table"
    >
      <EuiBasicTable
        aria-label="Users stats list table"
        tableCaption={' '}
        className="tableOverflow"
        itemId="id"
        items={data}
        columns={columnsConfig as EuiBasicTableColumn<UserData>[]}
        responsiveBreakpoint={false}
      />
      {!!total && !!limit && (
        <EuiTablePagination
          aria-label="List pagination"
          pageCount={Math.ceil(total / limit)}
          activePage={activePage}
          showPerPageOptions={true}
          onChangePage={handleChangePage}
          itemsPerPage={limit}
          onChangeItemsPerPage={handleChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
          responsive={['xs', 's']}
        />
      )}
    </EuiPanel>
  );
};

import { boolean, object, ObjectSchema, string } from 'yup';

export type FormFields = Omit<Paths.NewSearchSearchNewPost.QueryParameters, 'channel_id' | 'user_id'> & {
  period?: string;
  channel_id?: string;
  user_id?: string;
  user_id_type?: string;
  channel_id_type?: string;
};

export const schema: ObjectSchema<FormFields> = object().shape({
  search_term: string().max(400, 'form.error.max_length'),
  period: string().required('form.error.required'),
  user_id_type: string(),
  channel_id_type: string(),
  exact_search: boolean(),
  unique: boolean(),
  user_id: string().when('user_id_type', (type, schema) =>
    type.some((v) => v === 'user_id') ? schema.matches(/^\d*$/, 'form.error.only_digits') : schema
  ),
  phone_number: string().when('user_id_type', (type, schema) =>
    type.some((v) => v === 'phone_number') ? schema.matches(/^(|\+\d{10,14})$/, 'form.error.phone_number') : schema
  ),
  channel_name: string().when('channel_id_type', (type, schema) =>
    type.some((v) => v === 'channel_name') ? schema.required('form.error.channel_name') : schema
  ),
  channel_id: string().when('channel_id_type', (type, schema) =>
    type.some((v) => v === 'channel_id') ? schema.matches(/^\d*$/, 'form.error.only_digits') : schema
  ),
  telezip_syntax_query: string().max(400, 'form.error.max_length'),
  start_date: string().when('period', (period, schema) =>
    period[0] === 'custom'
      ? schema
          .when('end_date', (end_date, schema) =>
            end_date[0]
              ? schema.test('is-before-end', 'form.error.start_date_before_end_date', function (startDate) {
                  if (!startDate) return true; // Якщо `start_date` порожнє, перевірка успішна
                  return new Date(startDate) <= new Date(end_date[0]); // `start_date` має бути меншим за `end_date`
                })
              : schema
          )
          .required('form.error.required_date')
      : schema
  ),
  end_date: string().when('period', (period, schema) =>
    period[0] === 'custom' ? schema.required('form.error.required_date') : schema
  ),
});

export enum Columns {
  name = 'name',
  date = 'created_at',
  reports = 'reports',
  users = 'users',
  note = 'note',
}

export type RawData = Components.Schemas.Organization;

export type TableProps = {
  loading: boolean;
  items: RawData[];
  limit?: number;
  total: number;
  activePage: number;
  handleChangePage: (page: number) => void;
  handleChangeItemsPerPage: (limit: number) => void;
  handleAfterDisable: () => void;
};

export enum ActionLoader {
  DELETE = 'DELETE',
}

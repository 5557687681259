import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiAvatar,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonIcon,
  EuiCheckbox,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiIcon,
  EuiPanel,
  EuiPopover,
  EuiScreenReaderOnly,
  EuiTablePagination,
  EuiText,
} from '@elastic/eui';
import { css } from '@emotion/css';

import { useIsOpenControl, useRole } from '@/hooks';
import { ConfirmModal, EditUserModal, EmptyData, Loader } from '@/components';
import { dateFormatter, prepareNameForAvatar } from '@/utils';
import { ReactComponent as Boxes } from '@/assets/boxesHorizontalFilled.svg';
import { ReactComponent as Unlock } from '@/assets/unlock.svg';
import { ReactComponent as Lock } from '@/assets/locked.svg';
import { useSelector } from '@/store';
import { usersSelectors } from '@/store/slices/users';
import { Roles } from '@/types';
import { CONFIG_ITEMS_PER_PAGE } from '@/constants';
import { appSelectors } from '@/store/slices/app';

import { Columns, TableProps, UserData } from './types';

export const UsersTable: FC<TableProps> = ({
  limit,
  total,
  activePage,
  handleChangePage,
  handleChangeItemsPerPage,
  selected,
  onSelectionChange,
  onDisableBtnClick,
  isLoading,
  isUsedFilters,
}) => {
  const { t } = useTranslation();
  const { isOpen, close, open } = useIsOpenControl(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [userToEdit, setUserToEdit] = useState<UserData | null>(null);

  const data = useSelector(usersSelectors.getUsers);
  const { isValid: hasAccess, validateAccessRole } = useRole([Roles.Role.SUPER_ADMIN]);
  const isUser = validateAccessRole([Roles.Role.USER]);
  const currentUser = useSelector(appSelectors.getUser);

  const selectable = useCallback(
    (user: UserData) => {
      if (isUser) return false;
      return user.is_active && (hasAccess || user.role === Roles.Role.USER);
    },
    [hasAccess, isUser]
  );

  const ids = useMemo(() => data.filter((user) => selectable(user)).map((item) => item.id), [data, selectable]);

  const isSelectAll = useMemo(() => selected.length === ids.length, [ids.length, selected.length]);

  const disabledAction = useCallback(
    (user: UserData) => {
      if (isUser && user.id !== currentUser?.attributes.id) return true;
      return !hasAccess && user.role !== Roles.Role.USER;
    },
    [currentUser?.attributes.id, hasAccess, isUser]
  );

  const onUserActionBtnClick = useCallback((id: number) => {
    setUserId((prev) => {
      if (prev !== id) {
        return id;
      } else {
        return null;
      }
    });
  }, []);
  const closeUserActionsMenu = useCallback(() => {
    setUserId(null);
  }, []);

  const columnsConfig: (Partial<EuiBasicTableColumn<UserData>> & { access?: Roles.Role[] })[] = useMemo(
    () =>
      [
        {
          field: 'checkbox',
          name: (
            <EuiFlexGroup
              alignItems="center"
              justifyContent="flexStart"
              className={css`
                width: 32px;
                height: 20px;
              `}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <EuiCheckbox
                id={'selectAllCheckbox'}
                label={null}
                aria-label="Select all rows"
                title="Select all rows"
                disabled={!ids.length}
                checked={isSelectAll && !!ids.length}
                onChange={() => onSelectionChange(isSelectAll ? [] : ids)}
              />
            </EuiFlexGroup>
          ),
          width: '32px',
          render: (_: string, item: UserData) => {
            return (
              <EuiFlexGroup
                style={{ width: 32, flexGrow: 0 }}
                alignItems="center"
                justifyContent="flexStart"
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <EuiCheckbox
                  id={`${item.id}`}
                  label={null}
                  aria-label="Select all rows"
                  title="Select all rows"
                  checked={selected.includes(item.id)}
                  disabled={!selectable(item)}
                  onChange={() => {
                    if (selected.includes(item.id)) {
                      onSelectionChange(selected.filter((id) => id !== item.id));
                    } else {
                      onSelectionChange([...selected, item.id]);
                    }
                  }}
                />
              </EuiFlexGroup>
            );
          },
        },
        {
          field: Columns.user,
          name: t(`table.users.column.${Columns.user}`),
          width: hasAccess ? '290' : '400',
          render: (value: string, item: UserData) => {
            return (
              <EuiFlexGroup gutterSize="s" alignItems="center">
                <EuiAvatar
                  name={prepareNameForAvatar(value || item.email)}
                  size="m"
                  initialsLength={1}
                  color={item.is_active ? undefined : 'subdued'}
                  className={css`
                    span {
                      color: #fff;
                      font-weight: 600;
                    }
                  `}
                />
                <EuiFlexGroup direction="column" gutterSize="xs">
                  {!!value && (
                    <EuiText color={item.is_active ? 'text' : 'subdued'} size="s">
                      {value}
                    </EuiText>
                  )}
                  <EuiText color="subdued" size="xs">
                    {item.email}
                  </EuiText>
                </EuiFlexGroup>
              </EuiFlexGroup>
            );
          },
        },
        {
          field: Columns.organization,
          name: t(`table.users.column.${Columns.organization}`),
          width: '140',
          truncateText: { lines: 1 },
          render: (value: string, item: UserData) => {
            return (
              <EuiText size="s" color={item.is_active ? 'text' : 'subdued'}>
                {value}
              </EuiText>
            );
          },
          access: [Roles.Role.SUPER_ADMIN],
        },
        {
          field: Columns.two_fa,
          name: t(`table.users.column.${Columns.two_fa}`),
          width: '120',
          render: (value: boolean, item: UserData) => {
            return (
              <EuiFlexGroup alignItems="baseline" gutterSize="xs">
                <EuiIcon type={value ? 'check' : 'warning'} size="s" color={value ? 'success' : 'warning'} />
                <EuiText size="s" color={item.is_active ? 'text' : 'subdued'}>
                  {t(`table.users.two_fa_status.${value ? 'enabled' : 'disabled'}`)}
                </EuiText>
              </EuiFlexGroup>
            );
          },
          access: [Roles.Role.SUPER_ADMIN],
        },
        {
          field: Columns.role,
          name: t(`table.users.column.${Columns.role}`),
          width: '130',
          render: (value: string, item: UserData) => {
            return (
              <EuiText size="s" color={item.is_active ? 'text' : 'subdued'}>
                {t(`role.${value}`)}
              </EuiText>
            );
          },
        },
        {
          field: Columns.joined,
          name: t(`table.users.column.${Columns.joined}`),
          width: '135',
          render: (value: string, item: UserData) => {
            return (
              <EuiText size="s" color={item.is_active ? 'text' : 'subdued'}>
                {dateFormatter(value, process.env.REACT_APP_DATE_FORMAT)}
              </EuiText>
            );
          },
        },
        {
          field: Columns.lastSeen,
          name: t(`table.users.column.${Columns.lastSeen}`),
          width: '180',
          render: (value: string, item: UserData) => {
            return (
              <EuiText size="s" color={item.is_active ? 'text' : 'subdued'}>
                {dateFormatter(value, process.env.REACT_APP_DATE_TIME_FORMAT)}
              </EuiText>
            );
          },
        },
        {
          field: Columns.status,
          name: t(`table.users.column.${Columns.status}`),
          width: '150',
          render: (value: boolean) => {
            return (
              <EuiFlexGroup alignItems="baseline" gutterSize="xs">
                <EuiIcon type={value ? Unlock : Lock} size="s" color={value ? 'text' : 'subdued'} />
                <EuiText size="s" color={value ? 'text' : 'subdued'}>
                  {t(`table.users.status.${value ? 'active' : 'disabled'}`)}
                </EuiText>
              </EuiFlexGroup>
            );
          },
        },
        {
          align: 'right',
          width: '40px',
          name: (
            <EuiScreenReaderOnly>
              <span>Actions</span>
            </EuiScreenReaderOnly>
          ),
          render: (value: UserData) => {
            return (
              <EuiPopover
                isOpen={userId === value.id}
                closePopover={closeUserActionsMenu}
                panelPaddingSize="none"
                anchorPosition="downLeft"
                hasArrow={false}
                offset={4}
                button={
                  <EuiButtonIcon
                    aria-label="actions menu"
                    iconType={Boxes}
                    color="text"
                    disabled={disabledAction(value)}
                    onClick={() => onUserActionBtnClick(value.id)}
                  />
                }
              >
                <EuiContextMenuPanel
                  items={[
                    <EuiContextMenuItem
                      key="edit"
                      onClick={() => {
                        setUserToEdit(value);
                        closeUserActionsMenu();
                      }}
                      size="s"
                    >
                      <EuiText size="s">{t('button.edit')}</EuiText>
                    </EuiContextMenuItem>,
                  ]}
                />
              </EuiPopover>
            );
          },
        },
      ].filter((item) => !item.access || validateAccessRole(item.access)),
    [
      ids,
      isSelectAll,
      t,
      hasAccess,
      onSelectionChange,
      selected,
      selectable,
      userId,
      closeUserActionsMenu,
      disabledAction,
      onUserActionBtnClick,
      validateAccessRole,
    ]
  );

  if (isLoading)
    return (
      <EuiPanel
        hasBorder
        hasShadow={false}
        grow
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        className="tableEmptyData"
      >
        <Loader />
      </EuiPanel>
    );
  if (!total) {
    return <EmptyData note={t(`table.users.${isUsedFilters ? 'notFound' : 'emptyData'}`)} className="tableEmptyData" />;
  }
  return (
    <EuiPanel
      style={{ overflow: 'hidden', width: '100%', position: 'relative' }}
      hasBorder
      hasShadow={false}
      paddingSize="none"
      className="table"
    >
      {!!selected.length && (
        <EuiFlexGroup
          gutterSize="m"
          className={css`
            position: absolute;
            z-index: 20;
            top: 0;
            left: 40px;
            right: 0;
            height: 50px;
            background-color: var(--table-header-background);
          `}
          alignItems="center"
          justifyContent="spaceBetween"
        >
          <EuiButton color="danger" className="borderedButton" style={{ fontWeight: 400 }} onClick={open} size="s">
            {t('button.disable', {
              subject: '',
            })}
          </EuiButton>
        </EuiFlexGroup>
      )}
      <EuiBasicTable
        aria-label="Users list table"
        tableCaption={' '}
        itemId="id"
        items={data}
        columns={columnsConfig as EuiBasicTableColumn<UserData>[]}
        responsiveBreakpoint={false}
        className="tableWithCheckbox tableOverflow"
      />
      {!!total && !!limit && (
        <EuiTablePagination
          aria-label="List pagination"
          pageCount={Math.ceil(total / limit)}
          activePage={activePage}
          showPerPageOptions={true}
          onChangePage={handleChangePage}
          itemsPerPage={limit}
          onChangeItemsPerPage={handleChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
          responsive={['xs', 's']}
        />
      )}
      {!!userToEdit && <EditUserModal user={userToEdit} onClose={() => setUserToEdit(null)} />}
      {isOpen && (
        <ConfirmModal
          onClose={close}
          onConfirm={() => {
            onDisableBtnClick();
            close();
          }}
          color="danger"
          title={t('modal.disableUser.title')}
          note={t('modal.disableUser.note')}
          btnText={t('button.disable', {
            subject: '',
          })}
        />
      )}
    </EuiPanel>
  );
};

import {
  EuiButton,
  EuiCallOut,
  EuiCopy,
  EuiFlexGroup,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface RecoveryCodesModalProps {
  onClose: () => void;
  codes: string[];
}

export const RecoveryCodesModal: FC<RecoveryCodesModalProps> = ({ codes, onClose }) => {
  const { t } = useTranslation();

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle size="xs">
          <h1 style={{ fontWeight: 600 }}>{t('modal.recoveryCodes.title')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText size="s">{t('modal.recoveryCodes.subtitle')}:</EuiText>
        <EuiSpacer size="m" />
        <EuiPanel
          style={{
            minWidth: 660,
          }}
          hasBorder={true}
          paddingSize="s"
        >
          <EuiFlexGroup direction="column" alignItems="center" gutterSize="xs">
            {codes.map((code) => (
              <EuiText key={code} color="accent" size="xs">
                {code}
              </EuiText>
            ))}
          </EuiFlexGroup>
        </EuiPanel>
        <EuiSpacer size="m" />
        <EuiCallOut title={t('modal.recoveryCodes.alertTitle')} size="m" color="warning">
          <EuiText size="s" color="warning">
            {t('modal.recoveryCodes.alertNote')}
          </EuiText>
        </EuiCallOut>
        <EuiSpacer size="xs" />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiCopy textToCopy={codes.join('\n')}>
          {(copy) => (
            <EuiButton onClick={copy} fill color="primary" className="primaryBtn">
              {t('button.copy')}
            </EuiButton>
          )}
        </EuiCopy>
      </EuiModalFooter>
    </EuiModal>
  );
};

import { useEffect, useCallback, ReactNode, useState } from 'react';
// eslint-disable-next-line import/default
import QrScanner from 'qr-scanner';
import { captureException } from '@sentry/react';

interface ScanQrCodeInterface {
  image: string;
  children: (params: ChildrenProps) => ReactNode;
}

interface ChildrenProps {
  data: string | null;
  isLoading: boolean;
  error: boolean;
}

export const ScanQrCode = ({ children, image }: ScanQrCodeInterface) => {
  const [data, setData] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleScanQrCode = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await QrScanner.scanImage(image);
      setData(result);
    } catch (error) {
      setError(true);
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  }, [image]);

  useEffect(() => {
    handleScanQrCode();
  }, [handleScanQrCode]);

  return (
    <>
      {children({
        data,
        error,
        isLoading,
      })}
    </>
  );
};

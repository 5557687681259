import moment, { unitOfTime } from 'moment';

import { dateFormatter } from '@/utils';

export type ChartData = {
  data: Components.Schemas.MessagesPerHour[] | null;
  from: string | null;
  to: string | null;
  copyParams: string;
};

const STEP_LIMIT = 24;
const HOUR = 3600000;
const MINUTE = 60000;

export const getSteps = ({ from, to }: { from: string | null; to: string | null }) => {
  let currentStart = from ? moment(from) : moment().subtract(6, 'months');
  let end = to ? moment(to) : moment();
  const differenceInDays = moment(end).diff(currentStart, 'days');
  const diffInHours = moment(end).diff(currentStart, 'hours');
  const roundTo: unitOfTime.StartOf = diffInHours < 12 ? 'minutes' : 'hours';
  currentStart = currentStart.startOf(roundTo);
  end = end.endOf(roundTo);

  // count step for 24 points
  const totalMilliseconds = end.diff(currentStart);
  const stepMilliseconds = totalMilliseconds / STEP_LIMIT - 1;
  let roundedStep: number;
  if (diffInHours < 12) {
    roundedStep = Math.max(Math.ceil(stepMilliseconds / MINUTE) * MINUTE, MINUTE);
  } else {
    roundedStep = Math.max(Math.ceil(stepMilliseconds / HOUR) * HOUR, HOUR);
  }

  const steps = [];
  for (let i = 0; i < STEP_LIMIT; i += 1) {
    steps.push(currentStart.valueOf());
    currentStart = currentStart.add(roundedStep, 'milliseconds');
    if (currentStart.isSameOrAfter(end, 'minutes') && i < STEP_LIMIT - 1) {
      steps.push(end.valueOf());
      break;
    }
  }

  let format = 'YYYY';
  if (differenceInDays < 1) {
    format = 'HH:mm';
  } else if (differenceInDays < 6) {
    format = 'DD.MMM HH:mm';
  } else if (differenceInDays < 90) {
    format = 'DD.MMM';
  } else if (differenceInDays < 180) {
    format = 'DD.MMM';
  } else if (differenceInDays < 365 * 2) {
    format = 'MMM.YYYY';
  }

  return { steps, format, step: roundedStep, roundTo };
};

const getGroupedByDate = (
  steps: number[],
  roundTo: unitOfTime.StartOf,
  data?: Components.Schemas.MessagesPerHour[] | null
) => {
  if (!data || !data.length) {
    return steps.map((category) => [category, 0]);
  }
  const groupedByDate = new Map(steps.map((key) => [key, 0]));
  const roundingOffset = roundTo === 'hours' ? HOUR : MINUTE;

  data.forEach((item) => {
    const itemTime = moment(item.hour).valueOf();

    let key: number = steps[steps.length - 1];
    for (let i = 0; i < steps.length; i += 1) {
      if (itemTime >= steps[i] && itemTime < (steps[i + 1] || steps[i] + roundingOffset * 2)) {
        key = steps[i];
        break;
      }
    }
    const count = groupedByDate.get(key) as number;
    groupedByDate.set(key, count + item.count);
  });
  return Array.from(groupedByDate.entries());
};

export const getChartData = ({ data, from, to, copyParams }: ChartData) => {
  const { steps, format, step, roundTo } = getSteps({ from, to });
  return {
    series: [
      {
        data: getGroupedByDate(steps, roundTo, data),
        color: '#4FD1C5',
        lineWidth: 1.5,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(79, 209, 197, 0.54)'],
            [1, 'rgba(79, 209, 197, 0)'],
          ],
        },
        cursor: 'pointer',
        point: {
          events: {
            click: function (this: Highcharts.Point) {
              if (!this.y) return;
              if (copyParams && this.x) {
                const start_date = moment(this.x).toISOString();
                const end_date = moment(this.x + step).toISOString();

                const url = new URL(copyParams);
                url.searchParams.set('start_date', start_date);
                url.searchParams.set('end_date', end_date);
                url.searchParams.set('period', 'custom');

                window.open(url, '_blank');
              }
            },
          },
        },
      },
    ],
    format,
    step,
  };
};

//date and step in milliseconds
export const tooltipDateFormatter = (date: number, step: number, to: string | null) => {
  let result = dateFormatter(date, process.env.REACT_APP_DATE_TIME_FORMAT_NO_SEC);
  if (result && moment(date).isBefore(moment(to))) {
    result +=
      ' - ' + dateFormatter(Math.min(date + step, moment(to).valueOf()), process.env.REACT_APP_DATE_TIME_FORMAT_NO_SEC);
  }
  return result;
};

import { captureException } from '@sentry/react';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/en-gb';

export const getTimeFromNow = (date: string, lng = 'en-gb') => {
  try {
    return moment(date).locale(lng).fromNow();
  } catch (e) {
    captureException(e);
    return date;
  }
};

import { EuiFieldText, EuiForm, EuiFormRow, EuiSuperSelect, EuiText } from '@elastic/eui';
import { css } from '@emotion/css';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Controller, UseFormSetError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { ROLES_OPTIONS, useIsOpenControl, useRole } from '@/hooks';
import { SecurityFieldBtn } from '@/components/SecurityFieldBtn';
import { Roles } from '@/types';

import { Fields, schema } from './schema';

interface UpdateUserFormProps {
  id: string;
  onSubmit: (data: Fields) => void;
  user: Components.Schemas.User;
}

export interface UpdateUserFormRef {
  setError: UseFormSetError<Fields>;
}

export const EditUserForm = forwardRef<UpdateUserFormRef, UpdateUserFormProps>(function EditUserForm(
  { id, onSubmit, user },
  ref
) {
  const { isOpen: isSecurityField, toggle: toggleSecurityField } = useIsOpenControl(true);
  const { isOpen: isSecurityField2, toggle: toggleSecurityField2 } = useIsOpenControl(true);

  const { t } = useTranslation();
  const { validateAccessRole, isValid: isSuperAdmin } = useRole([Roles.Role.SUPER_ADMIN]);
  const isUser = validateAccessRole([Roles.Role.USER]);
  const rolesOptions = useMemo(
    () =>
      ROLES_OPTIONS.map(({ value, access }) => ({
        value,
        inputDisplay: t(`role.${value}`),
        disabled: !validateAccessRole(access),
      })),
    [t, validateAccessRole]
  );
  const { control, handleSubmit, watch, setError } = useForm<Fields>({
    defaultValues: {
      email: user.email ?? '',
      nickname: user.nickname ?? '',
      role: user.role ?? '',
      is_active: !!user.is_active,
      new_password: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { new_password } = watch();
  useImperativeHandle(ref, () => {
    return {
      setError,
    };
  }, [setError]);

  return (
    <EuiForm
      component="form"
      id={id}
      onSubmit={handleSubmit(onSubmit)}
      className={css`
        width: 400px;
        max-width: 100%;
        .euiFormRow__labelWrapper {
          flex-direction: column;
          gap: 10px;
        }
        .euiFormLabel {
          font-weight: 400;
          font-size: 14px;
        }
        .euiFormControlLayout--group {
          background-color: transparent;
        }
      `}
    >
      <Controller
        control={control}
        name="email"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ gap: 10 }}
            label={t(`form.label.login`)}
            labelAppend={
              <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                {t(`modal.editUser.notes.${name}`)}
              </EuiText>
            }
            error={t(error?.message as string)}
            isInvalid={!!error?.message}
            fullWidth
          >
            <EuiFieldText
              placeholder={t(`form.placeholder.${name}`)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              disabled={isUser}
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="new_password"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ gap: 10 }}
            label={t(`form.label.${name}`)}
            error={t(error?.message as string)}
            isInvalid={!!error?.message}
            fullWidth
          >
            <EuiFieldText
              type={isSecurityField ? 'password' : 'text'}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              autoComplete="new-password"
              append={<SecurityFieldBtn isSecurity={isSecurityField} toggleSecurity={toggleSecurityField} />}
            />
          </EuiFormRow>
        )}
      />
      {!!new_password && (
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              style={{ gap: 10 }}
              label={t(`form.label.repeat_password`)}
              labelAppend={
                <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                  {t(`modal.editUser.notes.repeat_password`)}
                </EuiText>
              }
              error={t(error?.message as string)}
              isInvalid={!!error?.message}
              fullWidth
            >
              <EuiFieldText
                type={isSecurityField2 ? 'password' : 'text'}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={!!error}
                fullWidth
                append={<SecurityFieldBtn isSecurity={isSecurityField2} toggleSecurity={toggleSecurityField2} />}
              />
            </EuiFormRow>
          )}
        />
      )}
      <Controller
        control={control}
        name="nickname"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ gap: 10 }}
            label={t(`form.label.${name}`)}
            labelAppend={
              <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                {t(`modal.editUser.notes.${name}`)}
              </EuiText>
            }
            error={t(error?.message as string)}
            isInvalid={!!error?.message}
            fullWidth
          >
            <EuiFieldText
              type="text"
              placeholder={t(`form.placeholder.${name}`)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="role"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            style={{ gap: 10 }}
            label={t(`form.label.${name}`)}
            error={t(error?.message as string)}
            isInvalid={!!error?.message}
            fullWidth
          >
            <EuiSuperSelect
              name={name}
              valueOfSelected={value}
              options={rolesOptions}
              placeholder={t(`form.placeholder.${name}`)}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              disabled={!isSuperAdmin}
            />
          </EuiFormRow>
        )}
      />
      {!isUser && (
        <Controller
          control={control}
          name="is_active"
          render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              style={{ gap: 10 }}
              label={t(`form.label.${name}`)}
              labelAppend={
                <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                  {t(`modal.editUser.notes.${name}`)}
                </EuiText>
              }
              error={t(error?.message as string)}
              isInvalid={!!error?.message}
              fullWidth
            >
              <EuiSuperSelect
                name={name}
                valueOfSelected={value}
                options={[
                  { value: true, inputDisplay: t('table.users.status.active') },
                  { value: false, inputDisplay: t('table.users.status.disabled') },
                ]}
                placeholder={t(`form.placeholder.${name}`)}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={!!error}
                fullWidth
              />
            </EuiFormRow>
          )}
        />
      )}
    </EuiForm>
  );
});

import { FC, ReactNode } from 'react';
import { EuiButtonEmpty, EuiFlexGroup, EuiIcon, EuiPanel, EuiText, EuiToolTip, useEuiTheme } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { ActionMenu } from '../SearchActionsMenu';
import { ReactComponent as Info } from '@/assets/info.svg';

type CardProps = {
  isCollapsed: boolean;
  toggleCollapsed: (id: number) => void;
  channelId: number;
  channelName: string | null;
  children: ReactNode;
  isInteractive?: boolean;
};

export const ChannelCard: FC<CardProps> = ({
  isCollapsed,
  toggleCollapsed,
  channelId,
  channelName,
  children,
  isInteractive,
}) => {
  const { euiTheme } = useEuiTheme();
  const { t } = useTranslation();
  return (
    <EuiPanel
      hasBorder={true}
      hasShadow={false}
      paddingSize="none"
      style={{ width: '100%', flexGrow: 0 }}
      key={channelId}
    >
      <EuiFlexGroup
        gutterSize="xs"
        style={{ paddingInline: euiTheme.size.m, paddingBlock: euiTheme.size.xs }}
        alignItems="center"
      >
        <EuiButtonEmpty
          onClick={() => {
            toggleCollapsed(channelId);
          }}
          color="primary"
        >
          {'+ / -'}
        </EuiButtonEmpty>
        {isInteractive ? (
          <>
            <ActionMenu
              btnText={channelName || t('messageCard.unknown_channel_name.title')}
              channel_id={channelId}
              isChannelMenu
              btnColor={channelName ? euiTheme.colors.text : euiTheme.colors.subduedText}
            />
            {!channelName && (
              <EuiToolTip position="right" content={<p>{t('messageCard.unknown_channel_name.tooltip')}</p>}>
                <EuiIcon type={Info} size="s" color={euiTheme.colors.subduedText} />
              </EuiToolTip>
            )}
          </>
        ) : (
          <EuiText size="s">{channelName}</EuiText>
        )}

        <EuiText
          size="s"
          color="subdued"
          className={css`
            margin-left: 4px;
          `}
        >
          id: {channelId}
        </EuiText>
      </EuiFlexGroup>
      {!isCollapsed && (
        <EuiFlexGroup direction="column" gutterSize="l" className="messagesWrapper">
          {children}
        </EuiFlexGroup>
      )}
    </EuiPanel>
  );
};

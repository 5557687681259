import moment from 'moment';

import { DEFAULT_START_DATE } from '@/constants';

export const convertPeriodToDate = ({ period, from, to }: { period?: string; from?: string; to?: string }) => {
  let start_date = '';
  let end_date = moment().endOf('day').toISOString();
  switch (period) {
    case '1h': {
      start_date = moment().subtract(1, 'hours').toISOString();
      end_date = moment().toISOString();
      break;
    }
    case '24h': {
      start_date = moment().subtract(1, 'days').toISOString();
      end_date = moment().toISOString();
      break;
    }
    case '7d': {
      start_date = moment().startOf('day').subtract(6, 'days').toISOString();
      break;
    }
    case '14d': {
      start_date = moment().startOf('day').subtract(13, 'days').toISOString();
      break;
    }
    case '30d': {
      start_date = moment().startOf('day').subtract(29, 'days').toISOString();
      break;
    }
    case 'year': {
      start_date = moment().startOf('day').subtract(364, 'days').toISOString();
      break;
    }
    case 'all': {
      start_date = moment(DEFAULT_START_DATE).toISOString();
      break;
    }
    case 'custom': {
      start_date = from || moment(DEFAULT_START_DATE).toISOString();
      if (to) {
        end_date = to;
      }
      break;
    }
  }
  return { start_date, end_date };
};

export const convertDateToPeriod = (start_date: string, end_date: string, created_at: string) => {
  try {
    const endDate = moment(end_date);
    const endIsSameCreateAt = moment(endDate).isSame(moment(created_at), 'day');
    const startDate = moment(start_date);
    const diffHours = endDate.diff(startDate, 'hours');
    const diffDays = endDate.diff(startDate, 'days');
    if (endIsSameCreateAt) {
      if (diffHours === 1) {
        return '1h';
      } else if (diffHours === 24) {
        return '24h';
      } else if (diffDays === 6) {
        return '7d';
      } else if (diffDays === 13) {
        return '14d';
      } else if (diffDays === 29) {
        return '30d';
      } else if (diffDays === 364) {
        return 'year';
      } else if (startDate.isSame(DEFAULT_START_DATE, 'day')) {
        return 'all';
      }
    }
  } catch {
    console.warn('Cannot convert to period');
  }
  return 'custom'; // Якщо не знайдено відповідного періоду
};

import { FC } from 'react';
import { EuiForm, EuiFormRow, EuiTextArea } from '@elastic/eui';
import { css } from '@emotion/css';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Fields, schema } from './schema';

interface ReportBugFormProps {
  id: string;
  onSubmit: (data: Fields) => void;
}

export const ReportBugForm: FC<ReportBugFormProps> = ({ id, onSubmit }) =>{
  const { t } = useTranslation();

  const { control, handleSubmit} = useForm<Fields>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <EuiForm
      component="form"
      id={id}
      onSubmit={handleSubmit(onSubmit)}
      className={css`
        width: 400px;
        max-width: 100%;
        .euiFormRow__labelWrapper {
          flex-direction: column;
          gap: 10px;
        }
        .euiFormLabel {
          font-weight: 400;
          font-size: 14px;
        }
      `}
    >
    <Controller
            control={control}
            name="message"
            render={({ field: { value, name, onChange }, fieldState: { error } }) => (
              <EuiFormRow
                style={{ gap: 10 }}
                isInvalid={!!error}
                error={t(error?.message as string)}
                fullWidth
                label={t(`form.label.${name}`)}
                className={css`
                  .euiFormLabel {
                    line-height: 1.4;
                  }
                `}
              >
                <EuiTextArea
                  value={value}
                  onChange={onChange}
                  name={name}
                  isInvalid={!!error}
                  resize="none"
                  placeholder={t(`form.placeholder.${name}`)}
                />
              </EuiFormRow>
            )}
          />

    </EuiForm>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { Roles } from '@/types';
import { ReduxState } from '../../store';

export const getAccessToken = (state: ReduxState) => state.app.access_token;
export const getRefreshToken = (state: ReduxState) => state.app.refresh_token;
export const getUser = (state: ReduxState) => state.app.user;
export const getLoading = (state: ReduxState) => state.app.isLoading;

export const getRecoveryCodes = (state: ReduxState) => state.app.recovery_codes;
export const getEnableTwoFa = (state: ReduxState) => state.app.user?.attributes?.is_2fa_enabled || false;

export const getAuthenticated = (state: ReduxState) => state.app.access_token && state.app.refresh_token;

export const getOrganizationId = (state: ReduxState) =>
  state.app.user?.attributes?.role === Roles.Role.SUPER_ADMIN && state.app.current_organization_id
    ? state.app.current_organization_id
    : (state.app.user?.attributes?.organization_id as number);
export const getOrganizations = (state: ReduxState) => state.app.organizations;

export const getCurrentOrganizationName = createSelector(
  [getOrganizationId, getOrganizations, getUser],
  (id, organizations, user) =>
    (id && organizations && organizations.find((org) => org.id === id)?.name) || user?.attributes?.organization_name
);
export const getCurrentOrganizationId = createSelector([getOrganizationId, getOrganizations], (id, organizations) =>
  id && organizations && organizations.some((org) => org.id === id) ? id : null
);

export const getStats = (state: ReduxState) => state.app.stats;

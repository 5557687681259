import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getCookie } from '@/utils/cookie';

import { AUTH_LOCALSTORAGE_KEYS, SETTINGS_AUTH_LOCALSTORAGE_KEYS, State } from './types';
import {
  getAccount,
  login,
  logout,
  refresh,
  updateAccount,
  updateAccountPassword,
  verifyTwoAuth,
  enableTwoAuth,
  fetchQrImageTwoAuth,
  disableTwoAuth,
  generateRecoveryCodes,
  generateApiToken,
  fetchOrganizations,
  fetchStats,
} from './thunks';

const initialState: State = {
  isLoading: true,
  access_token: localStorage.getItem(AUTH_LOCALSTORAGE_KEYS.ACCESS) || getCookie(AUTH_LOCALSTORAGE_KEYS.ACCESS) || null,
  refresh_token:
    localStorage.getItem(AUTH_LOCALSTORAGE_KEYS.REFRESH) || getCookie(AUTH_LOCALSTORAGE_KEYS.REFRESH) || null,
  user: null,
  recovery_codes: null,
  current_organization_id: Number(localStorage.getItem(SETTINGS_AUTH_LOCALSTORAGE_KEYS.ORGANIZATION)) || null,
  organizations: [],
  stats: {
    total_channels: null,
    total_messages: null,
  },
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clear: () => ({ ...initialState, access_token: null, refresh_token: null }),
    clearRecoveryCodes: (state) => {
      state.recovery_codes = null;
    },
    setCurrentOrganizationId: (state, action: PayloadAction<number | null>) => {
      state.current_organization_id = action.payload;
      if (action.payload) localStorage.setItem(SETTINGS_AUTH_LOCALSTORAGE_KEYS.ORGANIZATION, `${action.payload}`);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
    });
    builder.addCase(verifyTwoAuth.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    });
    builder.addCase(enableTwoAuth.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.recovery_codes = action.payload.recovery_codes;
    });
    builder.addCase(disableTwoAuth.fulfilled, (state) => {
      if (state.user?.attributes) {
        state.user.attributes.is_2fa_enabled = false;
      }
    });
    builder.addCase(generateRecoveryCodes.fulfilled, (state, action) => {
      state.recovery_codes = action.payload.recovery_codes;
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      if (action.payload) state.access_token = action.payload.access_token;
    });

    builder
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, () => ({
        ...initialState,
        access_token: null,
        refresh_token: null,
      }))
      .addCase(logout.rejected, () => ({
        ...initialState,
        access_token: null,
        refresh_token: null,
      }));

    builder
      .addCase(getAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        if (action.payload) {
          state.user = action.payload;
          state.isLoading = false;
        }
      })
      .addCase(getAccount.rejected, (state) => {
        state.isLoading = true;
      });

    builder.addCase(updateAccount.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
      state.organizations = action.payload.items;
    });
    builder.addCase(fetchStats.fulfilled, (state, action) => {
      if (action.payload) {
        state.stats = action.payload;
      }
    });
  },
});

export const actions = {
  ...slice.actions,
  refresh,
  login,
  logout,
  getAccount,
  updateAccount,
  updateAccountPassword,
  verifyTwoAuth,
  enableTwoAuth,
  fetchQrImageTwoAuth,
  disableTwoAuth,
  generateRecoveryCodes,
  generateApiToken,
  fetchOrganizations,
  fetchStats,
};

export default slice.reducer;

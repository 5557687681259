import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { store } from '@/store';
// import { toastActions } from '@/store/slices/toast';
import { appActions, appSelectors } from '@/store/slices/app';
// import { Toast } from '@/types';
import { Mutex } from '../mutex';

const MAX_RETRY_REQUEST_LIMIT = 3;

const headers: Record<string, string> = {
  'Content-Type': 'application/json; charset=utf-8',
};

const instance = axios.create({
  headers,
});

const mutex = new Mutex();

instance.interceptors.request.use(async function (config) {
  const token = appSelectors.getAccessToken(store.getState());
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    config.headers = { ...config.headers, ...headers };
  }

  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError<{ error?: { code?: string } }>) {
    const axiosConfig = error?.config as AxiosRequestConfig & {
      retry?: number;
    };

    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (!axiosConfig.headers?.NotRefreshed) {
            try {
              const refreshPromise = mutex.isLocked()
                ? mutex.getValue()
                : mutex.acquire(store.dispatch(appActions.refresh()).unwrap());

              await refreshPromise;

              if (axiosConfig) {
                axiosConfig.retry = axiosConfig.retry ? axiosConfig.retry + 1 : 1;
                if (axiosConfig.retry && axiosConfig.retry <= MAX_RETRY_REQUEST_LIMIT) {
                  try {
                    const response = await instance(axiosConfig);
                    return response;
                  } catch (errorRetry) {
                    return Promise.reject(errorRetry);
                  }
                } else {
                  store.dispatch(appActions.logout({ force: true }));
                }
              }
            } catch {
              store.dispatch(appActions.logout({ force: true }));
            } finally {
              mutex.release();
            }
          }
          return Promise.reject(error);
        case 403:
          store.dispatch(appActions.logout({ force: true, need_home_redirect: true }));
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    } else if (error.message === 'Network Error') {
      // store.dispatch(
      //   toastActions.create({
      //     type: Toast.Type.ERROR,
      //     message: Toast.Messages.NETWORK_ERROR,
      //   })
      // );
      return Promise.reject({
        message: 'networkError',
      });
    }
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject(error);
    } else {
      // store.dispatch(
      //   toastActions.create({
      //     type: Toast.Type.ERROR,
      //     message: Toast.Messages.UNKNOWN_ERROR,
      //   })
      // );
      return Promise.reject({
        message: 'unknownError',
      });
    }
  }
);

export default instance;

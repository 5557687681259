import { object, string, number, ObjectSchema } from 'yup';

export type FormFields = Paths.UpdateOrganizationOrganizationsOrganizationIdPut.PathParameters &
  Omit<Paths.CreateOrganizationOrganizationsPost.RequestBody, 'img_path'>;

export const schema: ObjectSchema<FormFields> = object().shape({
  organization_id: number().required('form.error.required'),
  name: string().required('form.error.required'),
  note: string(),
});

import { object, string, number, ObjectSchema, boolean } from 'yup';

export type Fields = Paths.CreateUserUsersPost.RequestBody;

export const schema: ObjectSchema<Fields> = object().shape({
  email: string().email('form.error.email').required('form.error.required'),
  nickname: string(),
  password: string().min(8, 'form.error.min_length').required('form.error.required'),
  organization_id: number().required(),
  role: string(),
  is_active: boolean(),
});

import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFieldText,
  EuiFlexGroup,
  EuiForm,
  EuiFormErrorText,
  EuiFormRow,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiToolTip,
  useEuiTheme,
  useIsWithinBreakpoints,
} from '@elastic/eui';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';

import { DEFAULT_PERIOD } from '@/constants';
import { convertDateToPeriod, convertPeriodToDate } from '@/utils';
import { useDispatch, useSelector } from '@/store';
import { searchActions, searchSelectors } from '@/store/slices/search';
import { Routes } from '@/routes';
import { Search } from '@/types';
import { ReactComponent as Info } from '@/assets/info.svg';

import { FormFields, schema } from './shcema';
import { PeriodSelect } from './PeriodSelect';

const checkAtLeastOneField = (formData: FormFields): boolean =>
  !formData.search_term && !formData.channel_id && !formData.user_id && !formData.channel_name;

const checkIsDisableClear = (formData: FormFields, isLightSearch: boolean): boolean =>
  isLightSearch
    ? !formData.search_term &&
      !formData.channel_id &&
      !formData.channel_name &&
      !formData.user_id &&
      !formData.exact_search &&
      formData.period === DEFAULT_PERIOD
    : !formData.telezip_syntax_query;

const getDefaultValuesFromSearchParams = (searchParams: URLSearchParams) => ({
  search_term: searchParams.get('search_term') || '',
  period: searchParams.get('period') || DEFAULT_PERIOD,
  exact_search: (searchParams.get('exact_search') && searchParams.get('exact_search') === 'true') || false,
  channel_id: searchParams.get('channel_id') || '',
  user_id: searchParams.get('user_id') || '',
  telezip_syntax_query: searchParams.get('telezip_syntax_query') || '',
  user_id_type: searchParams.get('user_id_type') || '',
  channel_id_type: searchParams.get('channel_id_type') || '',
  channel_name: searchParams.get('channel_name') || '',
  start_date: searchParams.get('start_date') || '',
  end_date: searchParams.get('end_date') || '',
});

export const SearchForm: FC<{
  isPending: boolean;
  isLightSearch: boolean;
  setIsLightSearch: Dispatch<SetStateAction<boolean>>;
  afterSubmit: () => void;
}> = ({ isPending, isLightSearch, setIsLightSearch, afterSubmit }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const detail = useSelector(searchSelectors.getDetail);
  const [queryParams, setQueryParams] = useState<
    | (Paths.NewSearchSearchNewPost.QueryParameters & {
        period?: string;
        user_id_type?: string;
        channel_id_type?: string;
      })
    | null
  >(null);

  const resetFields = useMemo(() => {
    const fields: FormFields = {
      exact_search: detail ? !detail.exact_search : queryParams ? !queryParams.exact_search : false,
      period: DEFAULT_PERIOD,
    };
    fields.search_term = detail?.search_term || queryParams?.search_term || '';
    fields.telezip_syntax_query = detail?.telezip_query || queryParams?.telezip_syntax_query || '';
    if (detail?.user_id || queryParams?.user_id) {
      fields.user_id = String(detail?.user_id || queryParams?.user_id);
      fields.user_id_type = 'user_id';
    } else {
      fields.user_id = '';
    }

    if (detail?.channel_name || queryParams?.channel_name) {
      fields.channel_name = String(detail?.channel_name || queryParams?.channel_name);
      fields.channel_id_type = 'channel_name';
    } else if (detail?.channel_id || queryParams?.channel_id) {
      fields.channel_id = String(detail?.channel_id || queryParams?.channel_id || '');
      fields.channel_id_type = 'channel_id';
    }

    if (detail?.start_date && detail?.end_date) {
      const period = convertDateToPeriod(detail?.start_date, detail?.end_date, detail?.created_at);
      if (period) {
        fields.period = period;
      }
      if (period === 'custom') {
        fields.start_date = detail.start_date;
        fields.end_date = detail.end_date;
      }
    } else if (queryParams?.start_date && queryParams?.end_date) {
      const period = queryParams?.period;
      if (period) {
        fields.period = period;
      }
      if (period === 'custom') {
        fields.start_date = queryParams.start_date;
        fields.end_date = queryParams.end_date;
      }
    }

    return fields;
  }, [detail, queryParams]);

  const isAutorun = useMemo(
    () => !!searchParams.get('autorun') && searchParams.get('autorun') === 'true',
    [searchParams]
  );

  const [defaultValues, setDefaultValues] = useState(getDefaultValuesFromSearchParams(searchParams));
  const defaultValuesRef = useRef(defaultValues);

  const isMobile = useIsWithinBreakpoints(['xs', 's']);

  const {
    control,
    handleSubmit,
    setError: setFormError,
    setValue,
    watch,
    reset,
    formState: { errors },
    trigger,
  } = useForm<FormFields>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { user_id_type, period, channel_id_type, ...rest } = watch();

  const isDisabledClean = useMemo(
    () => checkIsDisableClear({ ...rest, period }, isLightSearch),
    [isLightSearch, period, rest]
  );

  const handleClear = useCallback(() => {
    if (isLightSearch) {
      reset(
        getDefaultValuesFromSearchParams(
          new URLSearchParams({
            telezip_syntax_query: rest.telezip_syntax_query || '',
          })
        )
      );
    } else {
      reset({ ...rest, user_id_type, period, channel_id_type, telezip_syntax_query: '' });
    }
  }, [isLightSearch, reset, rest, user_id_type, period, channel_id_type]);

  const handleRequest = useCallback(
    async (formData: FormFields) => {
      if (isLightSearch && checkAtLeastOneField(formData)) {
        return setError('form.error.at_least_one_required');
      }
      if (!isLightSearch && !formData.telezip_syntax_query) {
        return setFormError('telezip_syntax_query', { message: 'form.error.required' });
      }
      try {
        setLoading(true);
        setError(null);
        let data: Paths.NewSearchSearchNewPost.QueryParameters;
        if (isLightSearch) {
          const { start_date, end_date } = convertPeriodToDate({
            period: formData.period,
            from: formData.start_date,
            to: formData.end_date,
          });
          data = {
            start_date,
            end_date,
            exact_search: !formData.exact_search,
          };
          if (formData.search_term) {
            data.search_term = formData.search_term;
          }
          if (formData.user_id) {
            data.user_id = Number(formData.user_id);
          }
          if (formData.channel_id) {
            data.channel_id = Number(formData.channel_id);
          }
          if (formData.channel_name) {
            data.channel_name = formData.channel_name;
          }
        } else {
          data = {
            telezip_syntax_query: formData.telezip_syntax_query,
          };
        }
        setQueryParams({
          ...data,
          period: formData.period,
          user_id_type: formData.user_id_type,
          channel_id_type: formData.channel_id_type,
        });
        const {
          data: {
            attributes: { search_id },
          },
        } = await dispatch(searchActions.createSearch(data)).unwrap();

        navigate(
          generatePath(Routes.SEARCH_DETAILS, {
            id: String(search_id),
          }),
          {
            state: {
              status: Search.Status.IN_QUEUE,
            },
            replace: isAutorun,
          }
        );
      } catch (e: any) {
        setLoading(false);
        if (e.error?.message === 'networkError') {
          navigate(Routes.SEARCH_ERROR, {
            state: {
              error: e.error.message,
            },
          });
        }
        if (e.errors?.[0]?.detail) {
          navigate(Routes.SEARCH_ERROR, {
            state: {
              error: e.errors[0].detail,
            },
          });
        } else if (e.detail?.[0]?.msg) {
          setError(e.detail?.[0]?.msg);
        }
      } finally {
        setLoading(false);
        afterSubmit();
      }
    },
    [afterSubmit, dispatch, isAutorun, isLightSearch, navigate, setFormError]
  );

  useEffect(() => {
    if (defaultValuesRef.current !== defaultValues) {
      defaultValuesRef.current = defaultValues;
      reset(defaultValues);

      if (isAutorun) {
        handleSubmit(handleRequest)();
      }
    }
  }, [isAutorun, handleSubmit, handleRequest, defaultValues, reset]);

  useEffect(() => {
    if (searchParams.size && isAutorun) {
      setIsLightSearch((state) =>
        !!searchParams.get('telezip_syntax_query') && state
          ? false
          : !searchParams.get('telezip_syntax_query') && !state
            ? true
            : state
      );
      setDefaultValues(getDefaultValuesFromSearchParams(searchParams));
    }
  }, [isAutorun, searchParams, setIsLightSearch]);

  useEffect(() => {
    if (rest.user_id) {
      setValue('user_id_type', 'user_id');
    } else {
      setValue('user_id_type', '');
    }
  }, [rest.user_id, setValue]);

  useEffect(() => {
    if (rest.channel_id) {
      setValue('channel_id_type', 'channel_id');
    } else if (rest.channel_name) {
      setValue('channel_id_type', 'channel_name');
    } else {
      setValue('channel_id_type', '');
    }
  }, [rest.channel_id, rest.channel_name, setValue]);

  useEffect(() => {
    reset(resetFields);
  }, [reset, resetFields]);

  return (
    <EuiForm
      component="form"
      css={{
        width: isMobile ? 'calc(100dvw - 40px)' : 464,
        maxWidth: '100%',
        paddingTop: 20,
      }}
      className={css`
        label {
          font-size: 14px;
          font-weight: 400;
        }
      `}
    >
      {isLightSearch ? (
        <>
          <Controller
            control={control}
            name="search_term"
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                label={t('form.label.search_term')}
                isInvalid={!!error}
                error={t(error?.message as string)}
                fullWidth
                style={{ gap: 10 }}
                isDisabled={loading}
              >
                <EuiFieldText
                  placeholder={t(`form.placeholder.search_term`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />

          <Controller
            control={control}
            key="user_id"
            name="user_id"
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                label={t('form.label.user_id_type', { type: t('form.label.user_id') })}
                isInvalid={!!error}
                error={t(error?.message || '')}
                style={{ gap: 10 }}
                fullWidth
                isDisabled={loading}
                className={css`
                  .euiRadio.euiRadioGroup__item {
                    input.euiRadio__input:checked + .euiRadio__circle {
                      background-image: none;
                      background-color: ${euiTheme.colors.primary};
                      border-color: ${euiTheme.colors.primary};
                      outline-offset: -2.5px;
                      outline: 1.5px solid #fff;
                    }
                  }
                `}
              >
                <EuiFieldText
                  placeholder={t(`form.placeholder.user_id`)}
                  value={value as string}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />

          <Controller
            control={control}
            name="channel_id"
            render={({ field: { name, value, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                label={t('form.label.channel_id')}
                isInvalid={!!error}
                error={t(error?.message as string)}
                fullWidth
                style={{ gap: 10 }}
                isDisabled={loading}
              >
                <EuiFieldText
                  placeholder={t(`form.placeholder.channel_id`)}
                  value={value as string}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  disabled={!!channel_id_type && channel_id_type !== name}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="channel_name"
            render={({ field: { name, value, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                label={t('form.label.channel_name')}
                isInvalid={!!error}
                error={t(error?.message as string)}
                fullWidth
                style={{ gap: 10 }}
                isDisabled={loading}
              >
                <EuiFieldText
                  placeholder={t(`form.placeholder.channel_name`)}
                  value={value as string}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  disabled={!!channel_id_type && channel_id_type !== name}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <PeriodSelect
            selectedPeriod={period}
            error={errors.start_date?.message || errors.end_date?.message}
            control={control}
            trigger={trigger}
          />
          <Controller
            control={control}
            name="exact_search"
            render={({ field: { value, name, onChange } }) => (
              <EuiFormRow style={{ marginTop: 15 }}>
                <EuiCheckbox
                  id={name}
                  label={
                    <EuiToolTip position="top" content={<p>{t(`form.tooltip.${name}`)}</p>}>
                      <EuiFlexGroup gutterSize="xs" alignItems="center">
                        <p>{t(`form.label.${name}`)}</p>
                        <EuiIcon type={Info} size="m" />
                      </EuiFlexGroup>
                    </EuiToolTip>
                  }
                  checked={value}
                  onChange={onChange}
                  className={css`
                    .euiCheckbox__input ~ .euiCheckbox__label {
                      padding-left: 34px;
                    }
                  `}
                />
              </EuiFormRow>
            )}
          />

          {!!error && (
            <EuiFormRow>
              <EuiFormErrorText>{t(error)}</EuiFormErrorText>
            </EuiFormRow>
          )}
        </>
      ) : (
        <>
          <Controller
            control={control}
            key="telezip_syntax_query"
            name="telezip_syntax_query"
            render={({ field: { value, name, onChange }, fieldState: { error } }) => (
              <EuiFormRow
                style={{ gap: 10 }}
                isInvalid={!!error}
                error={t(error?.message as string)}
                fullWidth
                isDisabled={loading}
                label={t(`form.label.${name}`)}
                className={css`
                  .euiFormLabel {
                    line-height: 1.4;
                  }
                `}
              >
                <EuiTextArea
                  value={value}
                  onChange={onChange}
                  name={name}
                  isInvalid={!!error}
                  resize="none"
                  placeholder={t(`form.placeholder.hard_search`)}
                />
              </EuiFormRow>
            )}
          />
          <EuiSpacer size="xs" />
          <EuiText size="s">
            <Trans
              i18nKey="form.text.docs"
              components={[
                <Link key={0} to={Routes.DOCS}>
                  <EuiLink />
                </Link>,
              ]}
            />
          </EuiText>
        </>
      )}

      <EuiSpacer size="xl" />
      <EuiFlexGroup alignItems="center" justifyContent="flexEnd" gutterSize={isMobile ? 'xs' : 'l'}>
        <EuiButtonEmpty
          type="button"
          color="danger"
          onClick={afterSubmit}
          className={css`
            display: none;
            @media (max-width: 1199px) {
              display: block;
              padding: 0;
            }
          `}
        >
          {t('button.close')}
        </EuiButtonEmpty>
        <EuiButtonEmpty
          type="button"
          color="primary"
          disabled={isPending || loading || isDisabledClean}
          onClick={handleClear}
          className={css`
            @media (max-width: 1199px) {
              padding: 0;
            }
          `}
        >
          {t('button.clear')}
        </EuiButtonEmpty>
        <EuiButton
          fill
          type="submit"
          isLoading={loading}
          disabled={isPending}
          onClick={handleSubmit(handleRequest)}
          color="primary"
          className="primaryBtn"
        >
          {t('button.search')}
        </EuiButton>
      </EuiFlexGroup>
    </EuiForm>
  );
};

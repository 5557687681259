import { createSlice } from '@reduxjs/toolkit';

import { Status } from '@/types/Search';

import { State } from './types';
import {
  createSearch,
  deleteSearchById,
  getSearchById,
  getSearchDetails,
  fetchExportFile,
  createExport,
  getExportById,
  createSearchStats,
  getSearchStatsById,
  fetchSearchStats,
} from './thunks';

const initialState: State = {
  isLoading: false,
  isLoadingExport: false,
  isLoadingStats: false,
  detail: null,
  export: null,
  items: [],
  stats: null,
  statsId: null,
  statsStatus: null,
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchById.fulfilled, (state, action) => {
      state.detail = action.payload.data.attributes;
    });

    builder
      .addCase(getSearchDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detail = action.payload.data.search;
        state.items = action.payload.data.items;
        state.stats = action.payload.data.stats;
      })
      .addCase(getSearchDetails.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(deleteSearchById.fulfilled, () => initialState);

    builder
      .addCase(createExport.pending, (state) => {
        state.isLoadingExport = true;
      })
      .addCase(createExport.fulfilled, (state, action) => {
        state.export = action.payload.data.attributes;
        state.isLoadingExport = action.payload.data.attributes.status !== Status.SUCCESS;
      })
      .addCase(createExport.rejected, (state) => {
        state.isLoadingExport = false;
      });

    builder
      .addCase(getExportById.pending, (state) => {
        state.isLoadingExport = true;
      })
      .addCase(getExportById.fulfilled, (state, action) => {
        state.export = action.payload.data.attributes;
        state.isLoadingExport = action.payload.data.attributes.status !== Status.SUCCESS;
      })
      .addCase(getExportById.rejected, (state) => {
        state.isLoadingExport = false;
      });

    builder
      .addCase(fetchExportFile.pending, (state) => {
        state.isLoadingExport = true;
      })
      .addCase(fetchExportFile.fulfilled, (state) => {
        state.isLoadingExport = false;
      })
      .addCase(fetchExportFile.rejected, (state) => {
        state.isLoadingExport = false;
      });

    builder
      .addCase(createSearchStats.pending, (state) => {
        state.isLoadingStats = true;
      })
      .addCase(createSearchStats.fulfilled, (state, action) => {
        state.statsId = action.payload.attributes.search_id;
        state.statsStatus = Status.IN_QUEUE;
      })
      .addCase(createSearchStats.rejected, (state) => {
        state.isLoadingStats = false;
      });

    builder
      .addCase(getSearchStatsById.pending, (state) => {
        state.isLoadingStats = true;
      })
      .addCase(getSearchStatsById.fulfilled, (state, action) => {
        state.statsStatus = action.payload.attributes.status;
        state.statsId = action.payload.attributes.id;
        if (action.payload.attributes.status === Status.SUCCESS || action.payload.attributes.status === Status.FAILED) {
          state.isLoadingStats = false;
        }
        if (action.payload.attributes.status === Status.FAILED && state.detail) {
          state.detail.stats_search_id = null;
        }
      })
      .addCase(getSearchStatsById.rejected, (state) => {
        state.isLoadingStats = false;
      });

    builder
      .addCase(fetchSearchStats.pending, (state) => {
        state.isLoadingStats = true;
      })
      .addCase(fetchSearchStats.fulfilled, (state, action) => {
        state.stats = action.payload.attributes;
        state.isLoadingStats = false;
      })
      .addCase(fetchSearchStats.rejected, (state) => {
        state.isLoadingStats = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  createSearch,
  getSearchById,
  getSearchDetails,
  deleteSearchById,
  fetchExportFile,
  createExport,
  getExportById,
  createSearchStats,
  getSearchStatsById,
  fetchSearchStats,
};

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as getUid } from 'uuid';

import { Toast } from '@/types';

import { State } from './types';

const initialState: State = {
  items: [],
};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    create: (state, { payload }: PayloadAction<Omit<Toast.Data, 'id'>>) => {
      state.items = [...state.items, { ...payload, id: getUid() }];
    },
    remove: (state, { payload }: PayloadAction<string>) => {
      state.items = state.items.filter(({ id }) => id !== payload);
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;

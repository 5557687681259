import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema, object, string } from 'yup';
import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { useDispatch } from '@/store';
import { SecurityFieldBtn } from '@/components/SecurityFieldBtn';
import { useIsOpenControl } from '@/hooks';
import { appActions } from '@/store/slices/app';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';

type FormFields = Paths.DisableTwoFactorAuthAuthTwoFactorAuthDelete.QueryParameters;
export const schema: ObjectSchema<FormFields> = object().shape({
  current_password: string().required('form.error.required'),
});
export const DisabledForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isOpen: isSecurityField, toggle: toggleSecurityField } = useIsOpenControl(true);

  const [loading, setLoading] = useState(false);
  const [loadingCodes, setLoadingCodes] = useState(false);

  const { control, handleSubmit, setError } = useForm<FormFields>({
    defaultValues: {
      current_password: '',
    },
    resolver: yupResolver(schema),
  });
  const handleDisable = useCallback(
    async (data: FormFields) => {
      try {
        setLoading(true);
        await dispatch(appActions.disableTwoAuth(data)).unwrap();
        dispatch(
          toastActions.create({
            type: Toast.Type.SUCCESS,
            message: 'toast.success.twoFactorDisabled',
            title: 'toast.success.title',
          })
        );
      } catch (e: any) {
        if (e.errors) {
          setError('current_password', { message: e.errors[0]?.detail });
        } else {
          dispatch(
            toastActions.create({
              type: Toast.Type.ERROR,
              message: 'toast.error.unknown',
              title: 'toast.error.title',
            })
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, setError]
  );

  const handleGenerateCodes = useCallback(
    async (data: FormFields) => {
      try {
        setLoadingCodes(true);
        await dispatch(appActions.generateRecoveryCodes(data)).unwrap();
        dispatch(
          toastActions.create({
            type: Toast.Type.SUCCESS,
            message: 'toast.success.generateCodes',
            title: 'toast.success.title',
          })
        );
      } catch (e: any) {
        if (e.errors) {
          setError('current_password', { message: `${e.errors[0]?.detail}` });
        } else {
          dispatch(
            toastActions.create({
              type: Toast.Type.ERROR,
              message: 'toast.error.unknown',
              title: 'toast.error.title',
            })
          );
        }
      } finally {
        setLoadingCodes(false);
      }
    },
    [dispatch, setError]
  );

  return (
    <div className="formWrapper">
      <Controller
        control={control}
        name="current_password"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            isInvalid={!!error}
            error={t(error?.message || '')}
            label={t('form.label.current_password')}
            fullWidth
          >
            <EuiFieldText
              type={isSecurityField ? 'password' : 'text'}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              autoComplete="cur-password"
              append={<SecurityFieldBtn isSecurity={isSecurityField} toggleSecurity={toggleSecurityField} />}
            />
          </EuiFormRow>
        )}
      />
      <EuiSpacer size="m" />
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem>
          <EuiTitle size="xxs">
            <h3>{t('page.account.two_factor.authenticator.title')}</h3>
          </EuiTitle>
          <EuiText color="subdued" className="description" size="xs">
            {t('page.account.two_factor.authenticator.description')}
          </EuiText>
        </EuiFlexItem>
        <EuiButton
          color="danger"
          className="borderedButton buttonM"
          isLoading={loading}
          onClick={handleSubmit(handleDisable)}
        >
          {t('button.off')}
        </EuiButton>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem>
          <EuiTitle size="xxs">
            <h3>{t('page.account.two_factor.codes.title')}</h3>
          </EuiTitle>
          <EuiText color="subdued" className="description" size="xs">
            {t('page.account.two_factor.codes.description')}
          </EuiText>
        </EuiFlexItem>
        <EuiButton
          color="primary"
          className="borderedButton buttonM"
          onClick={handleSubmit(handleGenerateCodes)}
          isLoading={loadingCodes}
        >
          {t('button.generateCodes')}
        </EuiButton>
      </EuiFlexGroup>
    </div>
  );
};

enum Status {
  IN_QUEUE = 'in_queue',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed',
}
enum MessagesTypes {
  JOINED = 'user_joined',
  MESSAGE = 'message',
}
export { Status, MessagesTypes };

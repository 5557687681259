export enum Routes {
  HOME = '/',
  LOGIN = '/login',
  LOGIN_OTP = '/login-otp',
  SETTINGS_OTP = '/settings-otp',
  LOGOUT = '/logout',
  ACCOUNT = '/account',
  DOCS = '/docs',
  SEARCH = '/search',
  SEARCH_DETAILS = '/search/:id',
  ORGANIZATIONS = '/organizations',
  USERS = '/users',
  USERS_STATS = '/users-stats',
  SEARCH_ERROR = '/search-error',
}

function getCookie(name: string) {
  const matches = document.cookie.match(
    // eslint-disable-next-line no-useless-escape
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

interface Attributed {
  secure?: boolean;
  path?: string;
  expires?: string | Date;
  ['max-age']?: number;
}

function setCookie(name: string, value: string, attributes: Attributed = {}) {
  attributes = {
    path: '/',
    secure: true,
    // add other defaults here if necessary
    ...attributes,
  };

  if (attributes.expires instanceof Date) {
    attributes.expires = attributes.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const attributeKey in attributes) {
    updatedCookie += '; ' + attributeKey;
    const attributeValue = attributes[attributeKey as keyof Attributed];
    if (attributeValue !== true) {
      updatedCookie += '=' + attributeValue;
    }
  }

  document.cookie = updatedCookie;
}

function deleteCookie(name: string) {
  setCookie(name, '', {
    'max-age': -1,
  });
}

export { setCookie, getCookie, deleteCookie };

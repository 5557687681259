import { useCallback, useMemo } from 'react';
import { EuiSuperSelect, EuiText } from '@elastic/eui';

import { useRole } from '@/hooks';
import { Roles } from '@/types';
import { useDispatch, useSelector } from '@/store';
import { appActions, appSelectors } from '@/store/slices/app';

export const OrganizationSelect = () => {
  const { isValid: isSuperAdmin } = useRole([Roles.Role.SUPER_ADMIN]);
  const dispatch = useDispatch();

  const currentOrganizationId = useSelector(appSelectors.getCurrentOrganizationId);
  const currentOrganizationName = useSelector(appSelectors.getCurrentOrganizationName);
  const organizations = useSelector(appSelectors.getOrganizations);

  const organizationOptions = useMemo(
    () => organizations.map((org) => ({ value: org.id, inputDisplay: org.name })),
    [organizations]
  );

  const handleOnChange = useCallback(
    (id: number) => {
      dispatch(appActions.setCurrentOrganizationId(id));
    },
    [dispatch]
  );

  return isSuperAdmin ? (
    <EuiSuperSelect
      name="organization"
      valueOfSelected={currentOrganizationId ?? 0}
      options={organizationOptions}
      placeholder=""
      onChange={handleOnChange}
      fullWidth
      compressed
      className="organizationSelect"
      itemClassName="organizationSelectItem"
    />
  ) : (
    <EuiText color="subdued" size="xs" style={{ paddingBottom: 4 }}>
      {currentOrganizationName}
    </EuiText>
  );
};

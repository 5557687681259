import { EuiGlobalToastList } from '@elastic/eui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from '@/store';
import { toastActions, toastSelectors } from '@/store/slices/toast';
import { Toast } from '@/types';

const DEFAUT_TOAST_DURATION = 3000;

export const ToastProvider = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const toasts = useSelector(toastSelectors.getItems);

  const handleClose = useCallback(
    (toast: any) => {
      dispatch(toastActions.remove(toast.id));
    },
    [dispatch]
  );

  return (
    <EuiGlobalToastList
      toasts={toasts.map(({ type, message, id, title }) => ({
        ...ToastConfigs[type],
        id,
        text: i18n.exists(message as string) ? t(message as string) : message,
        title: i18n.exists(title as string) ? t(title as string) : undefined,
      }))}
      dismissToast={handleClose}
      toastLifeTimeMs={DEFAUT_TOAST_DURATION}
    />
  );
};

interface ToastConfig {
  color: 'primary' | 'success' | 'warning' | 'danger';
  duration?: number; //in milli seconds
}

const ToastConfigs: Record<Toast.Type, ToastConfig> = {
  [Toast.Type.INFO]: {
    color: 'primary',
    duration: DEFAUT_TOAST_DURATION,
  },
  [Toast.Type.SUCCESS]: {
    color: 'success',

    duration: DEFAUT_TOAST_DURATION,
  },
  [Toast.Type.ERROR]: {
    color: 'danger',
    duration: DEFAUT_TOAST_DURATION,
  },
  [Toast.Type.WARNING]: {
    color: 'warning',
    duration: DEFAUT_TOAST_DURATION,
  },
};

import { FC, useMemo } from 'react';
import { EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiText, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { bigNumberFormatter } from '@/utils';

import { prepareChartData } from './utils';
import { ChannelLink } from './ChannelLink';

const CHART_HEIGHT = 15;

type TopChannelsChartProps = {
  items: Components.Schemas.MessagesPerChannel[];
  total: number;
  copyParams: string;
};

export const TopChannelsChart: FC<TopChannelsChartProps> = ({ items, total, copyParams }) => {
  const { euiTheme } = useEuiTheme();
  const { t } = useTranslation();
  const data = useMemo(() => prepareChartData(items, total), [items, total]);
  const chunks = useMemo(() => {
    if (!data) return null;
    return data.map(({ rate, color }, key) => (
      <EuiFlexItem
        key={key}
        grow={rate ? 1 : 0}
        className={css`
          background-color: ${color};
          flex-basis: ${rate < 1 && rate !== 0 ? 1 : rate}% !important;
          height: ${CHART_HEIGHT}px;
        `}
      />
    ));
  }, [data]);
  const labels = useMemo(() => {
    if (!data) return null;
    return data.map(({ count, channel_name, color, id }, key) => (
      <EuiFlexGroup
        key={key}
        gutterSize="xs"
        alignItems="center"
        className={css`
          overflow: hidden;
          max-width: 100%;
        `}
      >
        <EuiHealth
          color={color}
          textSize="xs"
          className={css`
            overflow: hidden;
            max-width: 100%;
          `}
        >
          {id ? (
            <ChannelLink
              className={css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
                color: ${euiTheme.colors.text};
              `}
              channel_name={channel_name || t('messageCard.unknown_channel_name.title')}
              channel_id={id}
              copyParams={copyParams}
            />
          ) : (
            <span
              className={css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
                color: ${euiTheme.colors.text};
              `}
            >
              {channel_name || t('messageCard.unknown_channel_name.title')}
            </span>
          )}
        </EuiHealth>
        <EuiText
          size="xs"
          color={euiTheme.colors.primary}
          className={css`
            flex-shrink: 0;
          `}
        >
          {bigNumberFormatter(count)}
        </EuiText>
      </EuiFlexGroup>
    ));
  }, [data, euiTheme.colors.text, euiTheme.colors.primary, t, copyParams]);
  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="s"
      className={css`
        padding: ${euiTheme.size.s};
        width: 100%;
        overflow: hidden;
      `}
    >
      <EuiText color="subdued" size="xs">
        {t('page.searchDetails.stats.top_channels')}
      </EuiText>
      <EuiFlexGroup
        gutterSize="none"
        className={css`
          width: 100%;
          height: ${CHART_HEIGHT}px;
          background-color: ${euiTheme.colors.lightestShade};
          overflow: hidden;
          border-radius: 2px;
        `}
        style={{ flexWrap: 'nowrap' }}
      >
        {chunks}
      </EuiFlexGroup>
      <EuiFlexGrid columns={3} gutterSize="s" style={{ minHeight: 50, maxWidth: '100%', overflow: 'hidden' }}>
        {labels}
      </EuiFlexGrid>
    </EuiFlexGroup>
  );
};

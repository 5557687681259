import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { createUser, deleteUserById, editUserById, editUsers, fetchUsers, fetchUsersStats } from './thunks';

const initialState: State = {
  loading: true,
  loading_stats: true,
  items: [],
  stats: [],
  total_items: 0,
  total_stats: 0,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.items = action.payload.items;
          state.total_items = action.payload.total_items;
        }
      });

    builder
      .addCase(fetchUsersStats.pending, (state) => {
        state.loading_stats = true;
      })
      .addCase(fetchUsersStats.fulfilled, (state, action) => {
        state.loading_stats = false;
        if (action.payload) {
          state.stats = action.payload.items;
          state.total_stats = action.payload.total_items;
        }
      });

    builder.addCase(editUserById.fulfilled, (state, action) => {
      if (action.payload) {
        state.items = state.items.map((user) => (user.id === action.payload.id ? action.payload.attributes : user));
      }
    });
    builder
      .addCase(editUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(editUsers.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          action.payload.forEach((item) => {
            const idx = state.items.findIndex((user) => user.id === item.data.attributes.id);
            state.items[idx] = item.data.attributes;
          });
        }
      });
    builder
      .addCase(deleteUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.items = state.items.filter((user) => user.id !== action.payload.user_id);
      });
  },
});

export const actions = {
  ...slice.actions,
  createUser,
  editUserById,
  fetchUsers,
  fetchUsersStats,
  editUsers,
};

export default slice.reducer;

import { boolean, object, ObjectSchema, string } from 'yup';

export type FormFields = {
  otp: string;
  remember_me: boolean;
};

export const schema: ObjectSchema<FormFields> = object().shape({
  otp: string().required('form.error.required'),
  remember_me: boolean().required('form.error.required'),
});

import { FC, ReactNode } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { Routes } from '@/routes';
import { useSelector } from '@/store';
import { appSelectors } from '@/store/slices/app';

import { AuthUILayout } from './AuthUILayout';

const REDIRECT_SEARCH_PARAM = 'redirect';

export const AuthenticationLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const isAuthenticated = useSelector(appSelectors.getAuthenticated);
  const [searchParams] = useSearchParams();

  if (isAuthenticated) {
    let redirectUrl: string = Routes.HOME;
    const redirect = searchParams.get(REDIRECT_SEARCH_PARAM);
    if (redirect) {
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.delete(REDIRECT_SEARCH_PARAM);
      const params = newSearchParams.toString() ? '&' + newSearchParams.toString() : '';
      redirectUrl = redirect + params;
    }
    return <Navigate to={redirectUrl} replace />;
  }

  return <AuthUILayout>{children}</AuthUILayout>;
};

/* eslint-disable max-len */
import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class CustomerServiceApi {
  private BASE_PATH = `${process.env.REACT_APP_CUSTOMER_SERVICE_URL}`;
  private AUTH_PATH = 'auth';
  private USERS_PATH = 'users';
  private ORGANIZATIONS_PATH = 'organizations';

  //USERS
  createUser(data: Paths.CreateUserUsersPost.RequestBody): AxiosPromise<Paths.CreateUserUsersPost.Responses.$201> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}`,
      method: 'post',
      data,
    });
  }

  getUsers(
    params: Paths.ReadUsersUsersGet.QueryParameters,
    signal: AbortSignal
  ): AxiosPromise<Paths.ReadUsersUsersGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}`,
      method: 'get',
      params,
      signal,
    });
  }

  getCurrentUser(): AxiosPromise<Paths.ReadUsersMeUsersMeGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/me`,
      method: 'get',
    });
  }

  getUserById({
    user_id,
  }: Paths.ReadUserUsersUserIdGet.PathParameters): AxiosPromise<Paths.ReadUserUsersUserIdGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/${user_id}`,
      method: 'get',
    });
  }

  updateUserById({
    user_id,
    ...data
  }: Paths.UpdateUserByIdUsersUserIdPut.PathParameters &
    Paths.UpdateUserByIdUsersUserIdPut.RequestBody): AxiosPromise<Paths.UpdateUserByIdUsersUserIdPut.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/${user_id}`,
      method: 'put',
      data,
    });
  }

  updateUsers({
    user_ids,
    ...data
  }: Paths.UpdateUsersUsersPut.RequestBody &
    Paths.UpdateUsersUsersPut.QueryParameters): AxiosPromise<Paths.UpdateUsersUsersPut.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}`,
      method: 'put',
      params: { user_ids },
      paramsSerializer: {
        indexes: null,
      },
      data,
    });
  }

  deleteUserById({
    user_id,
  }: Paths.DeleteUserUsersUserIdDelete.PathParameters): AxiosPromise<Paths.DeleteUserUsersUserIdDelete.Responses.$204> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/${user_id}`,
      method: 'delete',
    });
  }

  updateCurrent(
    data: Paths.UpdateUserSettingsUsersSettingsPut.RequestBody
  ): AxiosPromise<Paths.UpdateUserSettingsUsersSettingsPut.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/settings`,
      method: 'put',
      data,
    });
  }

  updateCurrentPassword(
    data: Paths.UpdateUserPasswordUsersPasswordPut.RequestBody
  ): AxiosPromise<Paths.UpdateUserPasswordUsersPasswordPut.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/password`,
      method: 'put',
      data,
    });
  }

  generateApiToken(): AxiosPromise<Paths.GenerateUserApiTokenUsersGenerateApiTokenGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/generate_api_token`,
      method: 'get',
    });
  }

  //ORGANIZATIONS
  getOrganizations(
    params: Paths.ReadOrganizationsOrganizationsGet.QueryParameters,
    signal: AbortSignal
  ): AxiosPromise<Paths.ReadOrganizationsOrganizationsGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.ORGANIZATIONS_PATH}`,
      method: 'get',
      params,
      signal,
    });
  }

  createOrganization(
    data: Paths.CreateOrganizationOrganizationsPost.RequestBody
  ): AxiosPromise<Paths.CreateOrganizationOrganizationsPost.Responses.$201> {
    return axios({
      url: `${this.BASE_PATH}/${this.ORGANIZATIONS_PATH}`,
      method: 'post',
      data,
    });
  }

  getOrganizationById({
    organization_id,
  }: Paths.ReadOrganizationOrganizationsOrganizationIdGet.PathParameters): AxiosPromise<Paths.ReadOrganizationOrganizationsOrganizationIdGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.ORGANIZATIONS_PATH}/${organization_id}`,
      method: 'get',
    });
  }

  updateOrganizationById({
    organization_id,
    ...data
  }: Paths.UpdateOrganizationOrganizationsOrganizationIdPut.PathParameters &
    Paths.UpdateOrganizationOrganizationsOrganizationIdPut.RequestBody): AxiosPromise<Paths.UpdateOrganizationOrganizationsOrganizationIdPut.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.ORGANIZATIONS_PATH}/${organization_id}`,
      method: 'put',
      data,
    });
  }

  disableOrganizationUsersById(
    params: Paths.DisableAllUsersUsersDisableAllUsersPut.QueryParameters
  ): AxiosPromise<Paths.DisableAllUsersUsersDisableAllUsersPut.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.USERS_PATH}/disable_all_users`,
      method: 'put',
      params,
    });
  }

  //AUTH
  login(
    data: Paths.LoginForAccessTokenAuthLoginPost.RequestBody
  ): AxiosPromise<Paths.LoginForAccessTokenAuthLoginPost.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/login`,
      method: 'post',
      data,
      headers: {
        NotRefreshed: true,
      },
    });
  }

  refresh(
    params: Paths.RefreshTokenAuthRefreshPost.QueryParameters
  ): AxiosPromise<Paths.RefreshTokenAuthRefreshPost.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/refresh`,
      method: 'post',
      params,
      headers: {
        NotRefreshed: true,
      },
    });
  }

  logout(params: Paths.LogoutAuthLogoutPost.QueryParameters): AxiosPromise<Paths.LogoutAuthLogoutPost.Responses.$204> {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/logout`,
      method: 'post',
      params,
      headers: {
        NotRefreshed: true,
      },
    });
  }

  getQrCodeTwoAuth(): AxiosPromise<Blob> {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/two-factor-auth`,
      method: 'get',
      responseType: 'blob',
    });
  }

  verifyTwoAuth(
    params: Paths.VerifyTwoFactorAuthAuthVerify2faPost.QueryParameters
  ): AxiosPromise<Paths.VerifyTwoFactorAuthAuthVerify2faPost.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/verify-2fa`,
      method: 'post',
      params,
      headers: {
        NotRefreshed: true,
      },
    });
  }

  enableTwoAuth(
    params: Paths.ActivateTwoFactorAuthAuthTwoFactorAuthPost.QueryParameters
  ): AxiosPromise<Paths.ActivateTwoFactorAuthAuthTwoFactorAuthPost.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/two-factor-auth`,
      method: 'post',
      params,
      headers: {
        NotRefreshed: true,
      },
    });
  }

  disableTwoAuth(params: Paths.DisableTwoFactorAuthAuthTwoFactorAuthDelete.QueryParameters): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/two-factor-auth`,
      method: 'delete',
      params,
    });
  }

  generateCodesTwoAuth(
    params: Paths.GenerateNewRecoveryCodesAuthGenerateRecoveryCodesPost.QueryParameters
  ): AxiosPromise<Paths.GenerateNewRecoveryCodesAuthGenerateRecoveryCodesPost.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.AUTH_PATH}/generate_recovery_codes`,
      method: 'post',
      params,
    });
  }

  getUsersStats(
    params: Paths.GetUsersWithStatsStatsUsersGet.QueryParameters,
    signal: AbortSignal
  ): AxiosPromise<Paths.GetUsersWithStatsStatsUsersGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/stats/users`,
      method: 'get',
      params,
      signal,
    });
  }
}

export const customerServiceApi = new CustomerServiceApi();

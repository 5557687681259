import { appReducer as app } from './slices/app';
import { searchReducer as search } from './slices/search';
import { organizationsReducer as organizations } from './slices/organizations';
import { usersReducer as users } from './slices/users';
import { toastReducer as toast } from './slices/toast';

export const reducer = {
  app,
  search,
  organizations,
  users,
  toast,
};

export enum Columns {
  user = 'nickname',
  status = 'is_active',
  weekly = 'weekly',
  total = 'total',
}

export type UserData = Components.Schemas.UserWithStats;

export type TableProps = {
  data: UserData[];
  isLoading: boolean;
  isUsedFilters: boolean;
  limit?: number;
  total: number;
  activePage: number;
  handleChangePage: (page: number) => void;
  handleChangeItemsPerPage: (limit: number) => void;
};

import { EuiHorizontalRule, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiText } from '@elastic/eui';
import { FC } from 'react';

type InfoModalProps = {
  title: string;
  note: string;
  onClose: () => void
}

export const InfoModal: FC<InfoModalProps> = ({ title, note, onClose }) => {
  return (
    <EuiModal onClose={onClose} style={{ maxWidth: 467 }}>
      <EuiModalHeader style={{ paddingBottom: 0 }}>
        <EuiModalHeaderTitle
          size="xs"
          style={{
            fontWeight: 500,
            fontSize: '18px',
          }}
        >
          {title}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiHorizontalRule margin="s" />
      <EuiModalBody style={{ paddingBottom: 24 }}>
        <EuiText size="s">{note}</EuiText>
      </EuiModalBody>
    </EuiModal>
  );
};

import { ReactNode, useCallback, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Mutex } from 'async-mutex';
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());

    if (a === b) continue;

    return a > b || isNaN(b);
  }
  return false;
};

const mutex = new Mutex();

export const CacheChecker = ({ children }: { children: ReactNode }) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { t } = useTranslation();

  const bootstrap = useCallback(async () => {
    if (mutex.isLocked()) {
      mutex.cancel();
    }
    try {
      await mutex.acquire();
      const meta: AxiosResponse<{
        version: string;
      }> = await axios.get('/meta.json', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      if (semverGreaterThan(meta.data.version, process.env.REACT_APP_VERSION_NUMBER as string)) setShouldRefresh(true);
    } catch (e: any) {
      console.warn('Cannot check version: ' + e?.message);
    } finally {
      mutex.release();
    }
  }, []);

  const handleReload = useCallback(() => {
    window.location.replace(window.location.href);
  }, []);

  useEffect(() => {
    bootstrap();
    const timeoutId = setInterval(
      () => {
        bootstrap();
      },
      60 * 10 * 1000
    );

    return () => {
      clearInterval(timeoutId);
    };
  }, [bootstrap]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') addEventListener('focus', bootstrap);

    return () => {
      if (process.env.NODE_ENV !== 'development') removeEventListener('focus', bootstrap);
    };
  }, [bootstrap]);

  return (
    <>
      {children}
      {shouldRefresh && (
        <EuiModal onClose={() => setShouldRefresh(false)}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>{t('cacheChecker.title')}</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText>{t('cacheChecker.note')}</EuiText>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton fill onClick={handleReload}>
              {t('button.reload')}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

import { FC, useRef, useState } from 'react';

import { ModalLayout } from '../ModalLayout';
import { useDispatch } from '@/store';
import { usersActions } from '@/store/slices/users';
import { toastActions } from '@/store/slices/toast';
import { Roles, Toast } from '@/types';

import { Fields } from './schema';
import { CreateUserForm, CreateUserFormRef } from './CreateUserForm';

interface CreateUserModalProps {
  onClose: () => void;
  onCreate?: () => void;
}
const FORM_ID = 'createUser';

export const CreateUserModal: FC<CreateUserModalProps> = ({ onClose, onCreate }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<CreateUserFormRef>(null);
  const dispatch = useDispatch();

  const onSubmit = async (data: Fields) => {
    if (!data.role) data.role = Roles.Role.USER;
    try {
      setLoading(true);
      await dispatch(usersActions.createUser(data)).unwrap();
      dispatch(
        toastActions.create({
          type: Toast.Type.SUCCESS,
          title: 'toast.success.title',
          message: 'toast.success.createUser',
        })
      );
      if (onCreate) {
        onCreate();
      }
      onClose();
    } catch (e: any) {
      const errors = e.errors as { errors: { loc: string; detail: string }[] };
      if (Array.isArray(errors)) {
        errors.forEach(({ loc, detail }) => {
          formRef.current?.setError(loc || 'email', {
            message: detail,
          });
        });
      } else if (e.detail?.[0]) {
        formRef.current?.setError(e.detail[0].loc?.[1], {
          message: e.detail[0].msg,
        });
      } else {
        dispatch(
          toastActions.create({
            type: Toast.Type.ERROR,
            message: 'toast.error.unknown',
            title: 'toast.error.title',
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalLayout formName={FORM_ID} onClose={onClose} isLoading={loading}>
      <CreateUserForm id={FORM_ID} onSubmit={onSubmit} ref={formRef} />
    </ModalLayout>
  );
};

import { Outlet } from 'react-router-dom';
import { ReactElement } from 'react';

import { useRole } from '@/hooks';
import { Roles } from '@/types';

interface RoleRequiredProps {
  roles: Roles.Role[];
  fallback: ReactElement | null;
}

export const RoleRequired = ({ roles, fallback }: RoleRequiredProps) => {
  const { isValid } = useRole(roles);
  return isValid ? <Outlet /> : fallback;
};

import { FC, ReactNode } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
} from '@elastic/eui';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  onClose: () => void;
  formName: string;
  children: ReactNode;
  isLoading?: boolean;
};
export const ModalLayout: FC<ModalProps> = ({ onClose, formName, children, isLoading }) => {
  const { t } = useTranslation();
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle size="s" style={{ fontWeight: 500, fontSize: '18px' }}>
          {t(`modal.${formName}.title`)}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {children}
        <EuiSpacer size="m" />
      </EuiModalBody>
      <EuiModalFooter
        className={css`
          background-color: var(--footer-background);
          padding-bottom: 16px;
        `}
      >
        <EuiButtonEmpty type="button" color="text" onClick={onClose}>
          {t('button.cancel')}
        </EuiButtonEmpty>
        <EuiButton fill type="submit" isLoading={isLoading} form={formName} className="primaryBtn">
          {t(`modal.${formName}.submit`)}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

interface Data {
  id: string;
  type: Type;
  message?: Messages | string;
  title?: string;
}
enum Type {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

enum Messages {
  NETWORK_ERROR = 'newtwork_error',
  UNKNOWN_ERROR = 'unknown_error',
}

export { Type, Messages };

export type { Data };

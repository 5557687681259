import { FC } from 'react';
import * as Sentry from '@sentry/react';

import { ModalLayout } from '../ModalLayout';
import { useDispatch, useSelector } from '@/store';
import { toastActions } from '@/store/slices/toast';
import { appSelectors } from '@/store/slices/app';
import { Toast } from '@/types';

import { ReportBugForm } from './ReportBugForm';
import { Fields } from './schema';

interface ReportBugModalProps {
  onClose: () => void;
}
const FORM_ID = 'reportBug';

export const ReportBugModal: FC<ReportBugModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(appSelectors.getUser);

  const onSubmit = async (data: Fields) => {
    try {
      Sentry.captureFeedback({
        name: user?.attributes?.nickname || '',
        email: user?.attributes?.email,
        message: data.message}, {
          includeReplay: true,
        });
      dispatch(
        toastActions.create({
          type: Toast.Type.SUCCESS,
          title: 'toast.success.title',
          message: 'toast.success.reportBug',
        })
      );

      onClose();
    } catch (e) {
        dispatch(
          toastActions.create({
            type: Toast.Type.ERROR,
            message: 'toast.error.unknown',
            title: 'toast.error.title',
          })
        );
  }};
  return (
    <ModalLayout formName={FORM_ID} onClose={onClose}>
      <ReportBugForm id={FORM_ID} onSubmit={onSubmit}/>
    </ModalLayout>
  );
};

import { FC, Suspense, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  EuiIcon,
  EuiPage,
  EuiPageBody,
  EuiPageTemplate,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  useEuiTheme,
  useIsWithinBreakpoints,
} from '@elastic/eui';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { PageLoader, SearchForm, SideBar, SideBarMobileContent } from '@/components';
import { useSelector } from '@/store';
import { searchSelectors } from '@/store/slices/search';
import { Search } from '@/types';
import HardSearch from '@/assets/gik_search.png';
import LightSearch from '@/assets/baby_search.png';

export const SideBarLayout: FC = () => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const isMobile = useIsWithinBreakpoints(['xs', 's', 'm', 'l']);

  const searchStatus = useSelector(searchSelectors.getStatus);
  const isLoadingDetail = useSelector(searchSelectors.getLoading);
  const { state } = useLocation();
  const isPending = useMemo(
    () =>
      isLoadingDetail === true ||
      (searchStatus
        ? searchStatus === (Search.Status.IN_QUEUE || searchStatus === Search.Status.PROCESSING)
        : state?.status === Search.Status.IN_QUEUE),
    [isLoadingDetail, searchStatus, state?.status]
  );

  const [isLightSearch, setIsLightSearch] = useState(true);
  const [tab, setTab] = useState<'light' | 'hard' | null>(isMobile ? null : 'light');
  const tabs = useMemo(
    () => [
      <EuiTab
        key="light-search"
        onClick={() => {
          setIsLightSearch(true);
          setTab('light');
        }}
        isSelected={tab === 'light'}
        prepend={<EuiIcon type={LightSearch} size="l" style={{ width: 20, height: 20 }} />}
      >
        {t('page.search.light_search')}
      </EuiTab>,
      <EuiTab
        key="hard-search"
        onClick={() => {
          setIsLightSearch(false);
          setTab('hard');
        }}
        isSelected={tab === 'hard'}
        prepend={<EuiIcon type={HardSearch} size="l" style={{ width: 20, height: 20 }} />}
      >
        {t('page.search.hard_search')}
      </EuiTab>,
    ],
    [t, tab]
  );

  useEffect(() => {
    if (!isMobile) {
      setIsLightSearch(true);
      setTab('light');
    } else {
      setTab(null);
    }
  }, [isMobile]);

  return (
    <EuiPageTemplate grow minHeight="100%">
      <EuiPage direction="column" grow>
        <EuiPage
          paddingSize="none"
          grow
          direction="column"
          className={css`
            height: 100vh;
            @media (min-width: 1200px) {
              flex-direction: row;
            }
          `}
        >
          <SideBar>
            <EuiSpacer size="m" />
            <EuiTabs
              key="tabs"
              bottomBorder={true}
              size="m"
              className={css`
                .euiTab {
                  color: ${euiTheme.colors.subduedText};
                }
                .euiTab.euiTab-isSelected {
                  color: ${euiTheme.colors.text};
                  box-shadow: inset 0 -2px 0 ${euiTheme.colors.text};
                }
                padding-inline: ${euiTheme.size.l};
                @media (max-width: 1199px) {
                  padding-inline: 20px;
                }
              `}
            >
              {tabs}
            </EuiTabs>
            <SideBarMobileContent isOpen={!(tab === null)}>
              <SearchForm
                isPending={isPending}
                isLightSearch={isLightSearch}
                setIsLightSearch={setIsLightSearch}
                afterSubmit={() => {
                  if (isMobile) setTab(null);
                }}
              />
            </SideBarMobileContent>
          </SideBar>
          <EuiPageBody paddingSize="none">
            <Suspense fallback={<PageLoader />}>
              <Outlet />
            </Suspense>
          </EuiPageBody>
        </EuiPage>
      </EuiPage>
    </EuiPageTemplate>
  );
};

import { useEffect } from 'react';
const elementLoader = document.getElementById('page-loader');

export const hiddenLoader = () => {
  if (elementLoader) {
    elementLoader.style.zIndex = '-1';
    elementLoader.style.opacity = '0';
  }
};

export const PageLoader = () => {
  useEffect(() => {
    if (elementLoader) {
      elementLoader.style.zIndex = '100000';
      elementLoader.style.opacity = '1';
    }

    return () => {
      hiddenLoader();
    };
  }, []);

  return null;
};

import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EuiButton, EuiFieldText, EuiForm, EuiFormErrorText, EuiFormRow } from '@elastic/eui';
import { Controller, useForm } from 'react-hook-form';
import { object, ObjectSchema, ref, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useIsOpenControl } from '@/hooks';
import { SecurityFieldBtn } from '../../SecurityFieldBtn';
import { appActions } from '@/store/slices/app';
import { useDispatch } from '@/store';
import { toastActions } from '@/store/slices/toast';
import { Toast } from '@/types';

type FormFields = Paths.UpdateUserPasswordUsersPasswordPut.RequestBody & { repeat_password: string };

const schema: ObjectSchema<FormFields> = object().shape({
  current_password: string().required('form.error.required'),
  new_password: string().required('form.error.required'),
  repeat_password: string()
    .oneOf([ref('new_password')], 'form.error.repeat_password')
    .required('form.error.required'),
});

export const PasswordForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [globalServerError, setGlobalServerError] = useState<string | null>(null);
  const { isOpen: isSecurityField, toggle: toggleSecurityField } = useIsOpenControl(true);
  const { isOpen: isSecurityField2, toggle: toggleSecurityField2 } = useIsOpenControl(true);
  const { isOpen: isSecurityField3, toggle: toggleSecurityField3 } = useIsOpenControl(true);

  const { control, reset, handleSubmit, setError } = useForm<FormFields>({
    defaultValues: {
      current_password: '',
      new_password: '',
      repeat_password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: FormFields) => {
      try {
        setLoading(true);
        await dispatch(appActions.updateAccountPassword(data)).unwrap();
        reset();
        dispatch(
          toastActions.create({
            type: Toast.Type.SUCCESS,
            message: 'toast.success.updatePassword',
            title: 'toast.success.title',
          })
        );
      } catch (e: any) {
        const errors = e.errors as { loc: string; detail: string }[];
        if (Array.isArray(errors) && errors[0]?.loc) {
          const loc = errors[0].loc === 'password' ? 'new_password' : errors[0].loc;
          setError(loc as keyof FormFields, { message: errors[0].detail });
        } else if (e.detail?.[0]?.msg) {
          setGlobalServerError(e.detail[0].msg);
        } else {
          dispatch(
            toastActions.create({
              type: Toast.Type.ERROR,
              message: 'toast.error.unknown',
              title: 'toast.error.title',
            })
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, reset, setError]
  );

  return (
    <EuiForm component="form" className="formWrapper" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="current_password"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            isInvalid={!!error}
            error={t(error?.message || '')}
            label={t('form.label.current_password')}
            fullWidth
          >
            <EuiFieldText
              type={isSecurityField ? 'password' : 'text'}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              autoComplete="cur-password"
              append={<SecurityFieldBtn isSecurity={isSecurityField} toggleSecurity={toggleSecurityField} />}
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="new_password"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            isInvalid={!!error}
            error={t(error?.message || '')}
            label={t('form.label.new_password')}
            fullWidth
          >
            <EuiFieldText
              type={isSecurityField2 ? 'password' : 'text'}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              autoComplete="cur-password"
              append={<SecurityFieldBtn isSecurity={isSecurityField2} toggleSecurity={toggleSecurityField2} />}
            />
          </EuiFormRow>
        )}
      />
      <Controller
        control={control}
        name="repeat_password"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow
            isInvalid={!!error}
            error={t(error?.message || '')}
            label={t('form.label.repeat_password')}
            fullWidth
          >
            <EuiFieldText
              type={isSecurityField3 ? 'password' : 'text'}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
              autoComplete="cur-password"
              append={<SecurityFieldBtn isSecurity={isSecurityField3} toggleSecurity={toggleSecurityField3} />}
            />
          </EuiFormRow>
        )}
      />
      {!!globalServerError && (
        <EuiFormRow>
          <EuiFormErrorText>{t(globalServerError)}</EuiFormErrorText>
        </EuiFormRow>
      )}
      <EuiButton isLoading={isLoading} type="submit" fill className="primaryBtn buttonS">
        {t('button.changePassword')}
      </EuiButton>
    </EuiForm>
  );
};

import { useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';
import { FC, PropsWithChildren } from 'react';

interface SideBarMobileContentProps extends PropsWithChildren {
  isOpen: boolean;
}

export const SideBarMobileContent: FC<SideBarMobileContentProps> = ({ isOpen, children }) => {
  const { euiTheme } = useEuiTheme();
  return (
    <div
      className={css`
        padding-inline: ${euiTheme.size.l};
        padding-bottom: ${euiTheme.size.m};
        overflow: auto;
        @media (max-width: 1199px) {
          position: absolute;
          top: 150px;
          left: 0;
          background-color: ${euiTheme.colors.emptyShade};
          height: calc(100dvh - 150px);
          padding-inline: 20px;
          transform: translateX(-100%);
          transition: all 0.1s linear;
          z-index: 999;

          ${isOpen &&
          `& {
            transform: translateX(0%);
          }`}
        }
      `}
    >
      {children}
    </div>
  );
};

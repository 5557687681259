import { createAsyncThunk } from '@reduxjs/toolkit';

import { customerServiceApi } from '@/api/customer';
import { appActions } from '../app';

export const fetchItems = createAsyncThunk(
  'organizations/fetchItems',
  async (payload: Paths.ReadOrganizationsOrganizationsGet.QueryParameters, { signal, rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.getOrganizations(payload, signal);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const updateById = createAsyncThunk(
  'organizations/updateById',
  async (
    payload: Paths.UpdateOrganizationOrganizationsOrganizationIdPut.PathParameters &
      Paths.UpdateOrganizationOrganizationsOrganizationIdPut.RequestBody,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.updateOrganizationById(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const deleteById = createAsyncThunk(
  'organizations/deleteById',
  async (payload: Paths.DisableAllUsersUsersDisableAllUsersPut.QueryParameters, { rejectWithValue }) => {
    try {
      await customerServiceApi.disableOrganizationUsersById(payload);
      return payload;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const create = createAsyncThunk(
  'organizations/create',
  async (payload: Paths.CreateOrganizationOrganizationsPost.RequestBody, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.createOrganization(payload);
      dispatch(appActions.fetchOrganizations());
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || { error });
    }
  }
);

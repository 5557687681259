import { FC } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

interface ConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;
  color: 'danger' | 'primary';
  title: string;
  note: string;
  btnText: string;
  isLoading?: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ onClose, onConfirm, isLoading, title, note, btnText, color }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  return (
    <EuiModal onClose={onClose} style={{ maxWidth: 467 }}>
      <EuiModalHeader style={{ paddingBottom: 0 }}>
        <EuiModalHeaderTitle
          size="xs"
          style={{
            fontWeight: 500,
            fontSize: '18px',
            color: color === 'danger' ? euiTheme.colors.dangerText : euiTheme.colors.primary,
          }}
        >
          {title}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiHorizontalRule margin="s" />
      <EuiModalBody style={{ paddingBottom: 24 }}>
        <EuiText size="s">{note}</EuiText>
      </EuiModalBody>
      <EuiModalFooter
        className={css`
          background-color: var(--footer-background);
          padding-bottom: 16px;
        `}
      >
        <EuiButtonEmpty type="button" color="text" onClick={onClose}>
          {t('button.cancel')}
        </EuiButtonEmpty>
        <EuiButton fill isLoading={isLoading} type="button" color={color} onClick={onConfirm} className="primaryBtn">
          {btnText}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import { EuiContextMenuItem } from '@elastic/eui';
import { css } from '@emotion/css';

interface ActionMenuItemProps {
  title: string;
  channel_id?: number;
  user_id?: number;
  onClick: () => void;
}

export const ActionMenuItem: FC<ActionMenuItemProps> = ({ title, channel_id, user_id, onClick }) => {
  const href = useMemo(() => {
    const url = new URL(window.location.origin);
    if (channel_id) {
      url.searchParams.append('channel_id', String(channel_id));
    }
    if (user_id) {
      url.searchParams.append('user_id', String(user_id));
      url.searchParams.append('user_id_type', 'user_id');
    }
    url.searchParams.append('period', 'all');
    url.searchParams.append('autorun', String(true));

    return url;
  }, [channel_id, user_id]);

  const handleClickHref = useLinkClickHandler(href, { target: '_blank' });

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (!e.defaultPrevented) {
        onClick();
        handleClickHref(e);
      }
    },
    [onClick, handleClickHref]
  );

  return (
    <EuiContextMenuItem
      href={href.toString()}
      target="_blank"
      onClick={handleClick as MouseEventHandler<Element>}
      className={css`
        white-space: pre-line;
      `}
    >
      {title}
    </EuiContextMenuItem>
  );
};

const COLORS = ['#AFB6FF', '#FFBFAF', '#89C8FC', '#AFFFD0', '#FFDEAF', '#ECECF1'];

const MAX_CHANNELS = 6;
type ChartData = {
  channel_name: string | null;
  count: number;
  rate: number;
  color: string;
  id: number | null;
};

export const prepareChartData = (items: Components.Schemas.MessagesPerChannel[], total: number) => {
  if (!total || !items?.length) return null;
  const channelsToShow: ChartData[] = (items.length > MAX_CHANNELS ? items.slice(0, MAX_CHANNELS - 1) : items).map(
    ({ name, count, id }, idx) => ({
      channel_name: name,
      count: Number(count),
      rate: (Number(count) * 100) / total,
      color: COLORS[idx],
      id,
    })
  );
  if (items.length > MAX_CHANNELS) {
    const count = total - channelsToShow.reduce((acc, item) => (acc += item.count), 0);
    channelsToShow.push({
      channel_name: 'Other',
      count,
      rate: (count * 100) / total,
      color: COLORS[5],
      id: null,
    });
  }
  return channelsToShow;
};

export const getMaxWidth = (count: number) => {
  if (count < 100) {
    return 70;
  } else if (count < 1000) {
    return 60;
  } else {
    return 50;
  }
};

import { EuiFieldText, EuiForm, EuiFormRow, EuiText } from '@elastic/eui';
import { css } from '@emotion/css';
import { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormFields, schema } from './schema';

interface EditOrganizationFormProps {
  id: string;
  organization: Components.Schemas.Organization;
  onSubmit: (data: FormFields) => void;
}

export interface EditOrganizationFormRef {
  setError: UseFormSetError<FormFields>;
}

export const EditOrganizationForm = forwardRef<EditOrganizationFormRef, EditOrganizationFormProps>(
  function EditOrganizationForm({ id, organization, onSubmit }, ref) {
    const { t } = useTranslation();

    const { control, handleSubmit, setError } = useForm<FormFields>({
      defaultValues: {
        organization_id: organization.id,
        name: organization.name || '',
        note: organization.note || '',
      },
      resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => {
      return {
        setError,
      };
    }, [setError]);

    return (
      <EuiForm
        component="form"
        id={id}
        onSubmit={handleSubmit(onSubmit)}
        className={css`
          .euiFormRow__labelWrapper {
            flex-direction: column;
            gap: 10px;
          }
          .euiFormLabel {
            font-weight: 500;
            font-size: 14px;
          }
        `}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              style={{ gap: 10 }}
              label={t('form.label.organization_name')}
              labelAppend={
                <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                  {t(`modal.editOrganization.notes.${name}`)}
                </EuiText>
              }
              isInvalid={!!error}
              error={t(error?.message as string)}
            >
              <EuiFieldText
                placeholder={t('form.placeholder.organization_name')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={!!error}
                fullWidth
              />
            </EuiFormRow>
          )}
        />
        <Controller
          control={control}
          name="note"
          render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              style={{ gap: 10 }}
              label={t(`form.label.${name}`)}
              labelAppend={
                <EuiText size="xs" color="subdued" style={{ lineHeight: '1.2' }}>
                  {t(`modal.editOrganization.notes.${name}`)}
                </EuiText>
              }
              isInvalid={!!error}
              error={t(error?.message as string)}
            >
              <EuiFieldText
                type="text"
                placeholder={t(`form.placeholder.${name}`)}
                value={value as string}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={!!error}
                fullWidth
              />
            </EuiFormRow>
          )}
        />
      </EuiForm>
    );
  }
);

/* eslint-disable max-len */
import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class DataServiceApi {
  private BASE_PATH = process.env.REACT_APP_DATA_SERVICE_URL;
  private SEARCH_PATH = 'search';
  private STATS_PATH = 'stats';

  createSearch(
    params: Paths.NewSearchSearchNewPost.QueryParameters
  ): AxiosPromise<Paths.NewSearchSearchNewPost.Responses.$201> {
    return axios({
      url: `${this.BASE_PATH}/${this.SEARCH_PATH}/new`,
      method: 'post',
      params,
    });
  }

  getSearches(signal?: AbortSignal): AxiosPromise<Paths.GetSearchesSearchListGet.Responses.$200> {
    return axios({ url: `${this.BASE_PATH}/${this.SEARCH_PATH}/list`, method: 'get', signal });
  }

  getSearchById(
    { search_id }: Paths.GetSearchSearchSearchIdGet.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.GetSearchSearchSearchIdGet.Responses.$200> {
    return axios({ url: `${this.BASE_PATH}/${this.SEARCH_PATH}/${search_id}`, method: 'get', signal });
  }

  getSearchMessages(
    { search_id }: Paths.GetSearchMessagesSearchSearchIdMessagesGet.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.GetSearchMessagesSearchSearchIdMessagesGet.Responses.$200> {
    return axios({ url: `${this.BASE_PATH}/${this.SEARCH_PATH}/${search_id}/messages`, method: 'get', signal });
  }

  deleteSearchById(
    { search_id }: Paths.DeleteSearchSearchSearchIdDelete.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.DeleteSearchSearchSearchIdDelete.Responses.$204> {
    return axios({ url: `${this.BASE_PATH}/${this.SEARCH_PATH}/${search_id}`, method: 'delete', signal });
  }

  createExport({
    search_id,
  }: Paths.CreateExportSearchSearchIdExportPost.PathParameters): AxiosPromise<Paths.CreateExportSearchSearchIdExportPost.Responses.$201> {
    return axios({
      url: `${this.BASE_PATH}/${this.SEARCH_PATH}/${search_id}/export`,
      method: 'post',
    });
  }

  getExportById(
    { export_id }: Paths.GetExportSearchExportsExportIdGet.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.GetExportSearchExportsExportIdGet.Responses.$200> {
    return axios({ url: `${this.BASE_PATH}/${this.SEARCH_PATH}/exports/${export_id}`, method: 'get', signal });
  }

  getMessagesExportFile({
    export_id,
  }: Paths.DownloadExportSearchExportsExportIdDownloadGet.PathParameters): AxiosPromise<Blob> {
    return axios({
      url: `${this.BASE_PATH}/${this.SEARCH_PATH}/exports/${export_id}/download`,
      method: 'get',
      responseType: 'blob',
    });
  }

  //STATS
  getStats(): AxiosPromise<Paths.GetLatestStatsStatsGet.Responses.$200> {
    return axios({ url: `${this.BASE_PATH}/${this.STATS_PATH}`, method: 'get' });
  }

  createSearchStats({
    search_id,
  }: Paths.CreateStatisticSearchStatsSearchesSearchIdPost.PathParameters): AxiosPromise<Paths.CreateStatisticSearchStatsSearchesSearchIdPost.Responses.$201> {
    return axios({
      url: `${this.BASE_PATH}/${this.STATS_PATH}/searches/${search_id}`,
      method: 'post',
    });
  }

  getSearchStatsById(
    { search_id }: Paths.GetSearchStatusStatsSearchesSearchIdGet.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.GetSearchStatusStatsSearchesSearchIdGet.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${this.STATS_PATH}/searches/${search_id}`,
      method: 'get',
      signal,
    });
  }
}

export const dataServiceApi = new DataServiceApi();

import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { EuiPage, EuiPageTemplate } from '@elastic/eui';
import { css } from '@emotion/css';

import { PageLoader } from '../Loader';

export const BaseLayout: FC = () => {
  return (
    <EuiPageTemplate grow minHeight="100%">
      <EuiPage direction="column" grow>
        <EuiPage
          grow
          paddingSize="none"
          className={css`
            height: 100vh;
          `}
        >
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </EuiPage>
      </EuiPage>
    </EuiPageTemplate>
  );
};

import { EuiFlexGroup, EuiPanel, EuiText } from '@elastic/eui';
import { FC } from 'react';

type EmptyDataProps = { note: string; className: string };

export const EmptyData: FC<EmptyDataProps> = ({ note, className }) => {
  return (
    <EuiPanel hasBorder hasShadow={false} style={{ alignItems: 'center', display: 'flex' }} className={className}>
      <EuiFlexGroup justifyContent="center" alignItems="center" gutterSize="s" wrap>
        <EuiText color="subdued" size="m" style={{ textAlign: 'center', fontSize: 20 }}>
          {note}
        </EuiText>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { customerServiceApi } from '@/api/customer';
import { ReduxState } from '@/store/store';
import { deleteCookie, setCookie } from '@/utils/cookie';
import { organizationsActions } from '../organizations';
import { Role } from '@/types/Roles';
import { dataServiceApi } from '@/api/data';

import { AUTH_LOCALSTORAGE_KEYS, SETTINGS_AUTH_LOCALSTORAGE_KEYS } from './types';
import { getRefreshToken } from './selectors';

export const login = createAsyncThunk(
  'app/login',
  async (payload: Paths.LoginForAccessTokenAuthLoginPost.RequestBody, thunkApi) => {
    try {
      const { data } = await customerServiceApi.login(payload);
      if (payload.remember_me) {
        localStorage.setItem(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
      } else {
        setCookie(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
      }
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const verifyTwoAuth = createAsyncThunk(
  'app/verifyTwoAuth',
  async (
    {
      remember_me,
      ...payload
    }: Paths.VerifyTwoFactorAuthAuthVerify2faPost.QueryParameters & {
      remember_me: boolean;
    },
    thunkApi
  ) => {
    try {
      const { data } = await customerServiceApi.verifyTwoAuth(payload);
      if (remember_me) {
        localStorage.setItem(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
        localStorage.setItem(AUTH_LOCALSTORAGE_KEYS.REFRESH, data.refresh_token);
      } else {
        setCookie(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
        setCookie(AUTH_LOCALSTORAGE_KEYS.REFRESH, data.refresh_token);
      }
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const enableTwoAuth = createAsyncThunk(
  'app/enableTwoAuth',
  async (
    {
      remember_me,
      ...payload
    }: Paths.ActivateTwoFactorAuthAuthTwoFactorAuthPost.QueryParameters & {
      remember_me: boolean;
    },
    thunkApi
  ) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.enableTwoAuth(payload);
      if (remember_me) {
        localStorage.setItem(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
        localStorage.setItem(AUTH_LOCALSTORAGE_KEYS.REFRESH, data.refresh_token);
      } else {
        setCookie(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
        setCookie(AUTH_LOCALSTORAGE_KEYS.REFRESH, data.refresh_token);
      }
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const disableTwoAuth = createAsyncThunk(
  'app/disableTwoAuth',
  async (payload: Paths.DisableTwoFactorAuthAuthTwoFactorAuthDelete.QueryParameters, thunkApi) => {
    try {
      await customerServiceApi.disableTwoAuth(payload);
      return;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const generateRecoveryCodes = createAsyncThunk(
  'app/generateRecoveryCodes',
  async (payload: Paths.GenerateNewRecoveryCodesAuthGenerateRecoveryCodesPost.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.generateCodesTwoAuth(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchQrImageTwoAuth = createAsyncThunk('app/fetchQrImageTwoAuth', async (_, thunkApi) => {
  try {
    const { data } = await customerServiceApi.getQrCodeTwoAuth();
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

export const generateApiToken = createAsyncThunk('app/generateApiToken', async (_, thunkApi) => {
  try {
    const {
      data: { data },
    } = await customerServiceApi.generateApiToken();
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

export const refresh = createAsyncThunk('app/refresh', async (_, { rejectWithValue, getState }) => {
  try {
    const refresh_token = getRefreshToken(getState() as ReduxState);
    if (refresh_token) {
      const { data } = await customerServiceApi.refresh({ refresh_token });

      if (localStorage.getItem(AUTH_LOCALSTORAGE_KEYS.REFRESH)) {
        localStorage.setItem(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
      } else {
        setCookie(AUTH_LOCALSTORAGE_KEYS.ACCESS, data.access_token);
      }
      return data;
    }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data || { error });
  }
});

export const logout = createAsyncThunk(
  'app/logout',
  async (payload: { force: boolean; need_home_redirect?: boolean }, { getState, dispatch }) => {
    try {
      const refresh_token = getRefreshToken(getState() as ReduxState);

      if (!payload.force && refresh_token) {
        if (refresh_token) {
          await customerServiceApi.logout({ refresh_token });
        }
      } else {
        throw 'Force logout';
      }
    } finally {
      dispatch(organizationsActions.clear());
      deleteCookie(AUTH_LOCALSTORAGE_KEYS.ACCESS);
      deleteCookie(AUTH_LOCALSTORAGE_KEYS.REFRESH);
      localStorage.removeItem(AUTH_LOCALSTORAGE_KEYS.ACCESS);
      localStorage.removeItem(AUTH_LOCALSTORAGE_KEYS.REFRESH);
      localStorage.removeItem(SETTINGS_AUTH_LOCALSTORAGE_KEYS.ORGANIZATION);
    }
    return payload;
  }
);

export const fetchStats = createAsyncThunk('app/fetchStats', async () => {
  try {
    const { data } = await dataServiceApi.getStats();
    return data;
  } catch (e) {
    return Promise.resolve(null);
  }
});

export const getAccount = createAsyncThunk('app/getAccount', async (_, { dispatch }) => {
  try {
    const [
      {
        data: { data },
      },
    ] = await Promise.all([customerServiceApi.getCurrentUser(), dispatch(fetchStats())]);
    if (data.attributes.role === Role.SUPER_ADMIN) {
      dispatch(fetchOrganizations());
    }
    return data;
  } catch (error) {
    dispatch(logout({ force: true }));
  }
});

export const updateAccount = createAsyncThunk(
  'app/updateAccount',
  async (payload: Paths.UpdateUserSettingsUsersSettingsPut.RequestBody, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.updateCurrent(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const updateAccountPassword = createAsyncThunk(
  'app/updateAccountPassword',
  async (payload: Paths.UpdateUserPasswordUsersPasswordPut.RequestBody, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await customerServiceApi.updateCurrentPassword(payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || { error });
    }
  }
);

const setCurrentOrganizationId = createAction<number | null>('app/setCurrentOrganizationId');

export const fetchOrganizations = createAsyncThunk(
  'app/fetchOrganizations',
  async (_, { signal, dispatch, rejectWithValue, getState }) => {
    try {
      const currentUserOrganizationId = (getState() as ReduxState).app.user?.attributes.organization_id;
      const {
        data: { data },
      } = await customerServiceApi.getOrganizations(
        {
          limit: 100,
        },
        signal
      );
      const organizationId = Number(
        localStorage.getItem(SETTINGS_AUTH_LOCALSTORAGE_KEYS.ORGANIZATION) ?? currentUserOrganizationId
      );
      const avaliableOrganization = data.items.find((organization) => organization.id === Number(organizationId));
      if (avaliableOrganization) dispatch(setCurrentOrganizationId(avaliableOrganization.id));

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

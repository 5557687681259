import { RouterProvider } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { Provider } from 'react-redux';

import { router } from '@/routes';
import { CacheChecker, ErrorFallback, ThemeProvider, ToastProvider } from '@/components';
import { store } from '@/store';
import '@/i18next';

function App() {
  return (
    <ThemeProvider>
      <ErrorBoundary fallback={() => <ErrorFallback />}>
        <CacheChecker>
          <Provider store={store}>
            <RouterProvider router={router} />
            <ToastProvider />
          </Provider>
        </CacheChecker>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;

import { EuiProvider, EuiThemeColorMode } from '@elastic/eui';
import { css, Global } from '@emotion/react';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { Theme } from './context';

const LOCAL_STORAGE_KEY = 'ui_theme';

// const initMode = (): EuiThemeColorMode => {
//   let theme = localStorage.getItem(LOCAL_STORAGE_KEY) as EuiThemeColorMode;

//   if (!theme) {
//     if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
//       theme = 'DARK';
//     } else {
//       theme = 'LIGHT';
//     }
//   }
//   return theme;
// };

const primaryOverrides = {
  colors: {
    LIGHT: {
      primary: '#5C6CFB',
      text: '#181E26',
      warningText: '#B77E0E',
      lightestShade: '#F9F9F9',
      lightShade: '#ECEBE7',
    },
    DARK: {
      primary: '#038C4C',
    },
  },
};

export const ThemeProvider: FC<{ children: ReactNode | ReactNode[] | null }> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<EuiThemeColorMode>('LIGHT');

  const handleChangeTheme = useCallback((theme: EuiThemeColorMode) => {
    setCurrentTheme(theme);
    localStorage.setItem(LOCAL_STORAGE_KEY, theme);
  }, []);

  useEffect(() => {
    const meta = document.querySelector('meta[name="eui-theme-insert"]');
    if (meta) {
      const style = document.createElement('link');
      style.href = `/themes/eui_theme_${currentTheme === 'DARK' ? 'dark' : 'light'}.min.css`;
      style.rel = 'stylesheet';
      meta.appendChild(style);

      const id = setTimeout(() => {
        meta.childNodes.forEach((node) => {
          if (meta.children.length > 1) {
            meta.removeChild(node);
          }
          return () => clearTimeout(id);
        });
      }, 1000);
    }
  }, [currentTheme]);

  return (
    <Theme.Provider
      value={{
        colorMode: currentTheme,
        onChangeTheme: handleChangeTheme,
      }}
    >
      <EuiProvider colorMode={currentTheme} modify={primaryOverrides}>
        {children}
        <Global
          styles={css(
            currentTheme === 'DARK'
              ? `
          :root {

          }
        `
              : `
          :root {
          --primary-button-text: #fff;
          --primary-background: #fff;
          --primary-color: #5C6CFB;
          --table-header-background: #EFEFEF;
          --border-color: #ECEBE7;
          --subdued_text: #757474;
          --footer-background: #FAF9FB;
          --dark-red: #73221A;
          --logo-color: #15264C;
          --noname-btn-color: rgba(92, 108, 251, 0.7);
          --disabled-input-background: #FBFBFB;
          }
        `
          )}
        />
      </EuiProvider>
    </Theme.Provider>
  );
};
